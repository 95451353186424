const getState = (state, path = [], defaultValue) => {
  if (typeof path === 'string') return state[path]
  if (path instanceof Array) {
    const { length } = path
    if (!length) return state
    const attr = path.splice(0, 1)
    const value = state[attr]
    if (value === undefined) return defaultValue
    return getState(value, path, defaultValue)
  }
}
const getters = {
  exposureTag (state) {
    return state.exposureTag
  },
  exposureTime (state) {
    return state.exposureTime
  },
  exposureAllowed (state) {
    return state.exposureAllowed
  },
  content (state) {
    return state.context && state.context.content
  },
  mod1_id (state) {
    return state.context && state.context.activityId
  },
  theme (state) {
    return getState(state, ['context', 'content', 'expand_data', 'theme'], { layout: {} })
  },
  layout_data (state) {
    return state.context && state.context.content && state.context.content.layout_data || []
  },
  abtParams (state) {
    return state.context && state.context.content && state.context.content.abtParams || {}
  },
  site (state) {
    return state.context && state.context.site
  },
  PUBLIC_CDN (state) {
    return state.context && state.context.PUBLIC_CDN
  },
  IMG_LINK (state) {
    return state.context && state.context.IMG_LINK
  },
  LAZY_IMG (state) {
    return state.context && state.context.LAZY_IMG
  },
  LAZY_IMG_SQUARE (state) {
    return state.context && state.context.LAZY_IMG_SQUARE
  },
  language (state) {
    return state.context && state.context.language || {}
  },
  SiteUID (state) {
    return state.context && state.context.SiteUID || ''
  },
  serverTime (state) {
    return state.context && state.context.serverTime
  },
  tabActive (state) {
    return state.context && state.context.tabActive
  },
  activityId (state) {
    return state.context && state.context.activityId
  },
  activityName (state) {
    return state.context && state.context.activityName
  },
  activityNameEn (state) {
    return state.context && state.context.activityNameEn
  },
  langText (state) {
    return state.context && state.context.language && state.context.language.lang_coupon_text || {}
  },
  isRomwe (state) {
    return state.context && state.context.isRomwe || false
  },
  showBottomAddCart (state) {
    return getState(state, ['context', 'content', 'expand_data', 'showBottomAddCart'], { layout: {} })
  },
  addCartSku (state) {
    return getState(state, ['context', 'content', 'expand_data', 'addCartSku'], { layout: {} })
  },
  showBackToTop (state) {
    return getState(state, ['context', 'content', 'expand_data', 'showBackToTop'], { layout: {} })
  },
  addCartGoodsId (state) {
    return getState(state, ['context', 'content', 'expand_data', 'addCartGoodsId'], { layout: {} })
  },
  goodsDetail (state) {
    return state.goodsDetail
  },
  productDescSku (state) {
    return state.productDescSku
  },
  isLogin (state) {
    return state.commonInfo.isLogin
  },
  mapToSku (state) {
    return state.commonInfo.mapToSku
  },
  cateInfo (state) {
    return state.commonInfo.cateInfo
  },
  timerExistedShowItem (state) {
    return state.commonInfo.timerExistedShowItem
  },
  fixedButton (state) {
    return getState(state, ['context', 'content', 'expand_data', 'fixedButton'], { layout: {} }) || '0'
  },
  // 专题锚点ActAnchor组件 跳转后的回调
  anchorCb (state) {
    return state.anchorCb
  }
}
export default getters
