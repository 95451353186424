import itemSer from 'public/src/services/goodsItemInfo/interfaceSer'
import { getSiteMallInfo } from 'public/src/pages/common/getSiteMallInfo'
import schttp from 'public/src/services/schttp'

export function fetchVoteList(options) {
  const { activityNameEn } = options
  return schttp({
    method: 'GET',
    url: `/api/campaign/vote/list/query`,
    params: {
      activityNameEn,
    },
  }).then((res) => {
    if (res.code == 1000 && res.data) {
      return res.data
    } else {
      return []
    }
  })
}

export function reportVote(options) {
  const { voteId, activityNameEn, activityDataId, index, testBranch } = options
  return schttp({
    method: 'POST',
    url: `/api/campaign/vote/submit/update`,
    data: {
      activityNameEn,
      activityDataId,
      voteId,
      index,
      testBranch
    },
  }).then((res) => {
    if (res.code == 1000 && res.data) {
      return res.data
    }
  })
}

// 批量获取商品列表收藏状态
export async function fetchGoodsSaveStats (options) {
  const { goodsIds = [] } = options
  let statusObj = {}
  let goodsIdsList = []
  goodsIds.length && goodsIds.forEach(item => {
    goodsIdsList.push({ goods_id: item })
  })
  const atomicInfo = await itemSer.getAtomicInfo({
    goods: goodsIdsList,
    fields: { wish: true },
    needAssign: false
  })
  for (let id in atomicInfo) {
    Object.assign(statusObj, { [id]: atomicInfo[id]?.wishStatus || 0 })
  }
  return statusObj
}

// 查询单个商品的收藏状态
export function fetchGoodSave (options) {
  const { goodsId = '' } = options

  return new Promise((resolve, reject) => {
    schttp({
      method: 'GET',
      url: `/act/getSaveStatus`,
      params: {
        goodsId,
      },
    }).then(res => {
      resolve(res?.info?.result)
    }).catch(() => {
      // eslint-disable-next-line prefer-promise-reject-errors
      reject()
    })
  })
}

// 查询mall相关数据
export async function fetchMallInfo () {
  const { mallInfoForSite = [] } = await getSiteMallInfo()
  return mallInfoForSite || []
}

export function fetchActivityData (params) {
  const { path } = params
  const actUrl = path.includes('?') ? `${path}&xhr=true` : `${path}?xhr=true`
  return schttp({ url: location.origin + actUrl })
}
