const getters = {
  language(state) {
    return state.language
  },
  necessaryData (state) {
    return state.necessaryData
  },
  similarItemsData (state) {
    return state.similarItems || {}
  }
}
export default getters
