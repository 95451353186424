export default {
  // 初始化一些数据 商品更换时必须清空的数据
  initNewProductDetail(state) {
    this.commit('newProductDetail/Middle/updateFirstCommentsTranslateInfo', {}) // 清空首屏 翻译信息
    this.commit('newProductDetail/SizeBox/updateSwitchMallCode', null)

    this.commit('newProductDetail/common/updateStoreFollowInfo', {})
    // this.commit('newProductDetail/common/updateStoreAndBrandDataReady', false)
    this.commit('newProductDetail/common/updateOneClickPayVerifyDoneStatus', false)
    this.commit('newProductDetail/common/updateOneClickPayState', {
      support: false,
      billInfo: null,
      stage: 1,
    })
    this.commit('newProductDetail/common/updateDetailPageOnloadStatus', false)
    this.commit('newProductDetail/common/updateDetailFetchMoreInfoStatus', false)
    // 推荐链路独立出来, 都应该初始化状态
    this.commit('newProductDetail/common/updateNewGtlInfo', [])
    this.commit('newProductDetail/common/updateSimilarItemsRecommend', [])
    // this.commit('newProductDetail/common/updateFrequentlyBoughtRecommend', [])
    // this.commit('newProductDetail/common/updateOtherOptionsRecommend', [])
    this.commit('newProductDetail/common/updateRecommendGoodsIdMap', Object.create(null))
    this.commit('newProductDetail/common/updateRealTimeFirstReady', false)
    // this.commit('newProductDetail/updateStoreRecommendInfo', {})
    this.commit('newProductDetail/updateStoreBrandSeriesRecommendGroup', {})

    // 以下都为人为推出商详页时需要清空的数据（浏览器退出行为）
    // 更新评论弹窗状态
    this.commit('newProductDetail/CommentPopup/closeViewCommentPop')
    this.commit('newProductDetail/common/updateCustomerShow', false)
    this.commit('newProductDetail/CommentPopup/updateDrawerFilterShow', false)
    // sizeGuide弹窗
    this.commit('newProductDetail/SizeGuide/updateShowSizeGuideDrawer', false)
    this.commit('newProductDetail/updateUserManualReady', false, { root: true })
    // 是否已经加车状态
    if(!state.common.fromSwitchColor) {
      this.commit('newProductDetail/common/updateAddCompleteStatus', false)
    }
    // 当前商品是否已经加车过
    this.commit('newProductDetail/common/updateAddSkcCompleteStatus', false)

  },
  initDetailPopStatus() {
    // 初始化 SizeGuide 弹窗状态
    this.commit('newProductDetail/SizeGuide/updateShowSizeGuideDrawer', false)
    // 初始化 混合弹窗状态
    this.commit('newProductDetail/SizeGuide/updateCheckSizeAndSizeGuide', false)
    // 初始化促销状态
    this.commit('newProductDetail/PromotionEnter/updateViewPromotionStatus', { status: false,
      isQuickAdd: false })
    // 初始化视频状态
    this.commit('newProductDetail/common/updatePlayVimeoStatus', false)
  },
  updatePromotionInfoWithCenter(state, payload) {
    state.promotionInfoWithCenter = payload
  },
  updatePdeGetTheLookData(state, payload) {
    state.getTheLookPdeInfo = payload
  },
  updateShowTryOnArContent(state, payload) {
    state.showTryOnArContent = payload
  },
  updateRelationGoodsWithCenter(state, payload) {
    state.relationGoodsWithCenter = payload
  },
  // updateStoreRecommendInfo(state, payload) {
  //   state.storeRecommendInfo = payload
  // },
  updateStoreBrandSeriesRecommendGroup(state, payload) {
    state.storeBrandSeriesRecommendGroup = payload
  },
  updateIsLogin(state, payload) {
    state.isLogin = payload
  },
  updateDescriptionReady(state, payload) {
    state.descriptionReady = payload
  },
  updateUserManualReady(state, payload) {
    state.userManualReady = payload
  },
  updateSizeGuideReady(state, payload) {
    state.sizeGuideReady = payload
  },
  updateSizeRecommandReady(state, payload) {
    state.sizeRecommandReady = payload
  },
  updateBraSizeRecommendReady(state, payload) {
    state.braSizeRecommendReady = payload
  },
  updateOneClickPayTimeStutas(state, payload) {
    state.showOneClickPayCardTimeStatus = payload
  },
  updateCheckSizeAndSizeGuideReady(state, payload) {
    state.checkSizeAndSizeGuideReady = payload
  },
}
