export default {
  namespaced: true,
  state: {},
  getters: {
    ingredient_text(state, getters, rootState) {
      return rootState.productDetail.coldModules.productInfo.beauty_ingredients
    },
    models_info(state, getters, rootState) {
      return rootState.productDetail.coldModules?.productInfo?.models_info || {}
    },
    show_size_guide(state, getters, rootState) {
      return !!rootState.productDetail.coldModules?.saleAttr?.size_guide_url
    }
  },
}
