import { isSwitchBffApiVersion } from '@/public/src/pages/common/bff-new/index.js'

const state = {
  messageAbt: {},
  // 站内信切流开关
  useBffApi: false,
  // userMessage_v2 的开关状态
  useBffApiV2: false
}

const mutations = {
  setMessageAbt(state, payload) {
    state.messageAbt = payload
  },
  setUseBffApi(state, payload) {
    state.useBffApi = payload
  },
  // 新的 mutation，用于更新 userMessage_v2 的开关状态
  setUseBffApiV2(state, payload) {
    state.useBffApiV2 = payload
  }
}

const actions = {
  getUseBffApi({ commit }) {
    const value = isSwitchBffApiVersion(['userMessage_v1', 'userMessage_v2'])
    commit('setUseBffApi', value.userMessage_v1)
    commit('setUseBffApiV2', value.userMessage_v2)
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
