export default {
  assignState ({ commit }, payload) {
    commit('assignState', payload)
  },
  // openQuickAdd ({ commit }, { goods_id, index, config = {} }) {
  //   // console.log('goods_id', goods_id)
  //   commit('changeQuickAddInfo', {
  //     active: true,
  //     config,
  //     goods_id: goods_id,
  //     index
  //   })
  // },
  // closeQuickAdd ({ commit }) {
  //   commit('changeQuickAddInfo', {
  //     active: false,
  //     goods_id: null
  //   })
  // },
  // changeQuickAddInfo ({ commit, state }, payload) {
  //   commit('changeQuickAddInfo', {
  //     ...state.quickAdd,
  //     ...payload
  //   })
  // }
}
