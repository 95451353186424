const filterAttrs = ['size', 'attr_id', 'attr_name', 'attr_value_id', 'attr_value_name', 'attr_value_name_en']
function descriptionDeal (config, state, getters) {
  const result = {
    descriptionList: getters.productDetailsObj,
    descriptionName: config.description == 0 ? state.language.SHEIN_KEY_PWA_14881 : state.language.SHEIN_KEY_PWA_14986,
    description27: null,
    goodsDesc: (state?.getBeautyGoodsDesc?.goodsDesc || '').replace(/\n/g, '<br>'),
    sizeAttrList: []
  }

  if (config.description == 0 && result.descriptionList['27'] && result.goodsDesc) {
    result.description27 = result.descriptionList['27']
  }

  // premium模板的composition名称文案改为outer
  if (config.premiumMaterial == 1 && result.descriptionList['62']) {
    result.descriptionList['62'].name = state.language.SHEIN_KEY_PWA_18075
  }

  const sizeInfoDes = state?.sizeInfoDes?.sizeUnit === 1 ? state.sizeInfoDes.sizeInfoInch : state.sizeInfoDes.sizeInfo
  if (sizeInfoDes instanceof Array && sizeInfoDes.length == 1 && config.description == 0) {
    // 只取第一条的size数据
    for (let key in sizeInfoDes[0]) {
      if (!filterAttrs.includes(key)) {
        result.sizeAttrList.push({
          name: key,
          value: sizeInfoDes[0][key]
        })
      }
    }
  }

  return result
}

function sizeGuideDeal (config) {
  return {
    isShow: config.sizeChart == 1
  }
}

function qtyDeal (config) {
  return {
    isShow: config.countCell == 1
  }
}

function colorDeal (config) {
  return {
    hasDropPop: config.colorType == 1,
    isImgColorType: config.colorType == 2
  }
}

function moreDetailDeal (config, state) {
  const result = {
    isNewComponent: config.detailImg == 0,
    notShow: config.detailImg == 2,
    partList: [],
    mergeList: []
  }

  const key = result.isNewComponent ? 'partList' : 'mergeList'

  if(result.notShow) { 
    result[key] = [] 
  } else {
    result[key] = state.detailImage
  }

  return result
}

function headNameDeal(config) {
  return {
    isBlack: config.premiumHeadName == 1
  }
}

function materialDeal(config, state, getters) {
  let descriptionList = getters.productDetailsObj
  let materialIdList = [160, 62, 1000078, 1000067, 1000069]
  let nameList = [
    state.language.SHEIN_KEY_PWA_18074,
    state.language.SHEIN_KEY_PWA_18077,
    state.language.SHEIN_KEY_PWA_18076,
    state.language.SHEIN_KEY_PWA_18078,
    state.language.SHEIN_KEY_PWA_18079,
  ]
  let materialList = []
  let composition = null
  let index

  Object.keys(descriptionList).forEach(descKey => {
    index = materialIdList.findIndex(materialItem => descKey == materialItem)
    if (index >= 0) {
      materialList[index] = {
        attr_id: descKey,
        name: nameList[index],
        value: descriptionList[descKey].value,
      }
    }
  })

  // inner 和 outer 合并为 composition 展示
  if (materialList[1] || materialList[2]) {
    composition = {
      name: state.language.SHEIN_KEY_PWA_18075,
      value: `${materialList[1] ? `${materialList[1].name}: ${materialList[1].value} ` : ''}${materialList[2] ? `${materialList[2].name}: ${materialList[2].value}` : ''}`,
    }
    materialList[1] = composition
    materialList[2] = null
  }

  return {
    isShow: config.premiumMaterial == 1,
    materialList,
  }
}

function modulesSortDeal(config) {
  return {
    shipAndDetailSortType: config.premiumModulesSort
  }
}

// function descPosDeal(config, state, getters) {
//   const originDescriptionList = state?.detail?.productDetails || []
//   const detailGoodsDesc = state?.detail?.goods_desc?.replace(/\n/g, '<br>')?.replace(/\s/g, ' ') || ''
//   const paragraphDescModule = state.cccConfig.desc?.style?.paragraphDescModule?.toLowerCase?.()?.replace?.(/[\u4e00-\u9fa5\s]/g, '')
//   const showParagraphDescription = state.cccConfig?.desc?.style?.showParagraphDescription || ''

//   const { isAbtCccdescription } = getters
//   // 坑位123展示黑名单
//   const descAttrBlackIds = isAbtCccdescription ? state.cccConfig.descAttrBlack : []

//   const result = {
//     fPosData: {
//       isShow: false
//     },
//     oPosData: [],
//     filterId: [],
//     descriptionImg: [],
//     hiddenDescriptionModule: config.descConfig == 1 && state.cccConfig.desc?.style?.showDescriptionModule == '0',
//     detailGoodsDesc: {
//       html: detailGoodsDesc,
//       adaText: detailGoodsDesc.replace(/<\/?.+?\/?>/g, ''),
//       pos: config.descConfig != 1 ? 'description' : showParagraphDescription == '1' ? paragraphDescModule : showParagraphDescription == '0' ? 'none' : 'description'
//     }
//   }
//   if (config.descConfig != 1) return result
//   // 坑位一至少要一个属性，坑位二三至少两个属性，坑位一属性不足时候全部不展示，分开存储坑位一和坑位二三数据，防止坑位二递进为坑位一
//   let items = state.cccConfig.desc?.items || []
//   if (state.cccConfig.desc?.style?.showDescriptionModule == '1') {
//     items = items.slice(0, items.length - 1)
//   }
//   items.forEach((item, index) => {
//     if (index == 0) {
//       if (item.moduleAttribute?.length) {
//         // 如果坑位1不显示，不走黑名单
//         const _fPosDataShow = item.showModule != '0'
//         const { textAttr, imgAttr } = getModuleAttribute(item.moduleAttribute, result.filterId, originDescriptionList, _fPosDataShow ? descAttrBlackIds : [])
//         result.fPosData = {
//           ...item,
//           imgAttr,
//           textAttr,
//           isShow: textAttr.length + imgAttr.length > 0
//         }
//       } else {
//         result.fPosData = {
//           isShow: false,
//           textAttr: [],
//           imgAttr: []
//         }
//       }
//     }
//     if (index !== 0 && index < 3 && item.moduleAttribute?.length > 1) {
//       const dealItem = getModuleAttribute(item.moduleAttribute, result.filterId, originDescriptionList)
//       result.oPosData.push({
//         ...item,
//         ...dealItem
//       })
//     }
//   })
//   getDescriptionImg(result, originDescriptionList)
//   return result
// }

// function getDescriptionImg (result, originDescriptionList) {
//   let descriptionImgFilter = []
//   const descriptionImg = {}
//   originDescriptionList.forEach(_ => {
//     if (!result.filterId.includes(String(_.attr_id)) && _.attr_image) {
//       descriptionImgFilter.push(String(_.attr_id))
//       descriptionImg[_.attr_id] = descriptionImg[_.attr_id] ? descriptionImg[_.attr_id].concat(_) : [_]
//     }
//   })
//   result.filterId = result.filterId.concat(descriptionImgFilter)
//   result.descriptionImg = Object.values(descriptionImg)
// }

export function getModuleAttribute (moduleAttribute, filterId, originDescriptionList, blackAttrIds = []) {
  const textAttr = {}
  const imgAttr = {}
  const attrIds = {}
  
  moduleAttribute.forEach(moduleItem => {
    const { _whiteAttrGroups = [] } = moduleItem
    const isBlackId = blackAttrIds.some(id => id == moduleItem.attribute_id)
    // 黑名单不计算
    if (isBlackId) return
    if (!attrIds[moduleItem.attribute_id]) {
      attrIds[moduleItem.attribute_id] = true
      // const productDetail = originDescriptionList.find(_ => _.attr_id + '-' + _.attr_value_id == moduleItem.uniqueId) || {}
      const productDetails = originDescriptionList.filter(_ => _.attr_id == moduleItem.attribute_id) || []
      if (productDetails[0]) {
        filterId.push(String(productDetails[0].attr_id))
      }
      productDetails.forEach(productDetail => {

        // 属性值白名单
        if (_whiteAttrGroups.length) {
          const isInWhite = _whiteAttrGroups.some(d => d.attribute_id == productDetail.attr_value_id)
          if (!isInWhite) return
        }
        if (productDetail.attr_image) {
          imgAttr[productDetail.attr_id] = imgAttr[productDetail.attr_id] ? imgAttr[productDetail.attr_id].concat(productDetail) : [productDetail]
          if (textAttr[productDetail.attr_id]) {
            delete textAttr[productDetail.attr_id]
          }
        } else {
          if (!imgAttr[productDetail.attr_id]) {
            textAttr[productDetail.attr_id] = {
              name: productDetail.attr_name,
              value: textAttr[productDetail.attr_id] ? textAttr[productDetail.attr_id].value + ', ' + productDetail.attr_value : productDetail.attr_value
            }
          }
        }
      })
    }
  })

  // topping字段升序
  return moduleAttribute.sort((a, b) => {
    const atp = a.topping || 9999
    const btp = b.topping || 9999
    return atp - btp
  }).reduce((res, curr) => {
    if (imgAttr[curr.attribute_id]) {
      res.imgAttr.push(imgAttr[curr.attribute_id])
    } else if (textAttr[curr.attribute_id]) {
      res.textAttr.push(textAttr[curr.attribute_id])
    }
    return res
  }, { textAttr: [], imgAttr: [] })
}

export default function cccTempalteDeal (state, getters) {
  // 默认数据，color样式组件1，无数量加减器，普通描述，细节图与banner一起展示，sizechart展示
  let config = {
    colorType: 0,
    countCell: 0,
    description: 1,
    detailImg: 1,
    imgSrc: '',
    sizeChart: 1,
    descConfig: 1 // 配置描述坑位123
  }
  // 人工模板
  if (state.cccTemplateData?.tempType == 1 && state.cccTemplateData?.content?.[0]) {
    config = {
      ...state.cccTemplateData.content[0],
      descConfig: 1
    }
  }
  // 定制化模板
  if (state.cccTemplateData?.tempType == 2 && ['PERMIUM-A', 'PERMIUM-B', 'MOTF-C', 'MOTF-D', 'MOTF-E'].includes(state.cccTemplateData?.tempCode)) {
    config = {
      ...config,
      // premium模板
      premiumHeadName: 1,              // 商品名称 (0: 默认, 1: #222)
      // FR-6246 锁定展示在size下方
      // premiumSizeDeviationTips: 1,     // 尺码偏大偏小提示 (0: 展示在尺码描述内, 1: 展示在size模块下方)
      premiumMaterial: 1,              // Material & Wash Care模块 (0: 不展示, 1: 展示)
      premiumModulesSort: state.cccTemplateData?.tempCode ==  'PERMIUM-B' ? 2 : 1,           // 模块排序 (1: 默认, 订阅——物流退换货——推荐位——model面料描述size, 2: 订阅——model面料描述size——推荐位——物流退换货)
      ...(['MOTF-C', 'MOTF-E'].includes(state?.cccTemplateData?.tempCode) ? { detailImg: 0 } : {}),
      descConfig: ['MOTF-D', 'MOTF-E'].includes(state?.cccTemplateData?.tempCode) ? 1 : 0
    }
  }


  return {
    // galleryConf: galleryDeal(state),
    moreDetailConf: moreDetailDeal(config, state),
    colorConf: colorDeal(config, state),
    descriptionConf: descriptionDeal(config, state, getters),
    sizeGuideConf: sizeGuideDeal(config, state),
    qtyConf: qtyDeal(config, state),

    headNameConf: headNameDeal(config, state),
    // sizeDeviationTipsConf: sizeDeviationTipsDeal(config, state),
    materialConf: materialDeal(config, state, getters),
    modulesSortConf: modulesSortDeal(config, state),
    // descPosConf: descPosDeal(config, state, getters)
  }
}
