import { fetchActivityData } from './api'

const actions = {
  fetchCampaignsData: async ({ commit }, params) => {
    let data
    try {
      data = await fetchActivityData(params)
    } catch (error) {
      console.log(error)
    }
    if (data) {
      return Promise.resolve(commit('SET_DATA', data))
    }
  },
}

export default actions
