export default {
  namespaced: true,
  state: {},
  getters: {
    show_parallel_import(state, getters, rootState) {
      return rootState.productDetail.hotModules.storeInfo?.parallelImportInfo?.isShowParallelImportArticle === '1'
    },
  },
  mutations: {},
}
