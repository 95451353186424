export default {
  assignState (state, payload) {
    state = {
      ...state,
      ...payload
    }
  },
  // changeQuickAddInfo (state, payload) {
  //   state.quickAdd = {
  //     ...state.quickAdd,
  //     ...payload
  //   }
  // }
}
