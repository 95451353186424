import schttp from 'public/src/services/schttp'
import itemSer from 'public/src/services/goodsItemInfo/interfaceSer'

function getAllCateIds(parentCatsArr) {
  let arr = [parentCatsArr]
  let res = []
  for(let item of arr) {
    const { cat_id, children } = item
    if(children?.length) arr.push(...children)
    if(cat_id) res.push(cat_id)
  }

  return res.join(',')
}

export default {
  namespaced: true,
  state: {
    storeCategoriesSale: [], // 所有符合条件的品类
    storeCategoryRecommend: [], // 店铺品类聚合推荐
    page: 1, //
  },
  getters: {
    // 店铺人气氛围列表
    storeAtmosphereInfo(state, getters, rootState, rootGetters) {
      // 降级措施: bff的get_goods_detail_realtime_data接口请求取不到数据时，或降级未调用bff接口时。
      // 则数据默认为空数组。店铺人气氛围功能就不展示了。
      const store_atmosphere_info = rootGetters['productDetail/common/store_atmosphere_info']
      return store_atmosphere_info || []
    },
    isShowStoreAtmosphere(state, getters, rootState, rootGetters) {
      const fsAbt = rootGetters['newProductDetail/fsAbt'] || {}
      // 普通店铺
      const showStore = rootGetters['newProductDetail/common/showStore']
      // 品牌集成店
      const isStoreBusinessBrand = rootGetters['newProductDetail/common/isStoreBusinessBrand']
      // 品质店铺
      const isStoreQualityTag = rootGetters['newProductDetail/common/isStoreQualityTag']
      // 时尚/趋势店铺
      const isInFashionStore = rootGetters['newProductDetail/common/isInFashionStore']

      const storehotnews = fsAbt?.storehotnews?.p?.storehotnews
      const hotnewslimited = fsAbt?.storehotnews?.p?.hotnewslimited 
      // 所有店铺都展示
      const allShow = hotnewslimited === 'all' && (showStore || isStoreBusinessBrand || isStoreQualityTag || isInFashionStore)
      // 仅时尚/品牌/品质店铺展示
      const limitShow = hotnewslimited === 'limited' && (isStoreBusinessBrand || isStoreQualityTag || isInFashionStore)
      
      return !!(storehotnews && storehotnews !== 'none' && ( allShow || limitShow ) && getters.storeAtmosphereInfo.length)
    },
    storehotnewsType(state, getters, rootState, rootGetters) {
      const fsAbt = rootGetters['newProductDetail/fsAbt'] || {}
      return getters.isShowStoreAtmosphere ? fsAbt?.storehotnews?.param?.storehotnews : ''
    },
    // 是否展示品类聚合推荐
    isShowStoreCategory(state, getters, rootState, rootGetters) {
      const isInFashionStore = rootGetters['newProductDetail/common/isInFashionStore'] || false
      const fsAbt = rootGetters['newProductDetail/fsAbt'] || {}
      if (fsAbt?.storecate?.param?.storecate !== 'show' || !isInFashionStore)  return false
      return state.storeCategoriesSale.length > 0 && state.storeCategoryRecommend.length > 4
    },
    cccStoreGoodsRecIntervention(state, getters, rootState) {
      const { ccc } = rootState.newProductDetail.hotModules
      const { cccStoreGoodsRecIntervention } = ccc || {}
      return cccStoreGoodsRecIntervention?.goods ?? []
    },
    // 店铺闪购信息
    storeFlashSaleInfo(state, getters, rootState, rootGetters){
      const { store = {} } = rootState.newProductDetail.storeBrandSeriesRecommendGroup || {}
      const { storeFlashSaleInfo = {} } = store
      return storeFlashSaleInfo
    },
    // 是否展示店铺的闪购活动入口
    isShowFlashEntry(state, getters, rootState, rootGetters) {
      const fsAbt = rootGetters['newProductDetail/fsAbt'] || {}
      // return true
      return fsAbt?.storeflash?.param?.storeflash !== 'none' && getters.storeFlashSaleInfo?.whetherEligibleForTheStore == 1
    },
    flashEntryType(state, getters, rootState, rootGetters){
      const fsAbt = rootGetters['newProductDetail/fsAbt'] || {}
      return (getters.isShowFlashEntry && fsAbt?.storeflash?.param?.storeflash) || ''
    },
    flashEntryCountdown(state, getters, rootState, rootGetters){
      const timeStamp = getters.storeFlashSaleInfo?.countdownToTheStore
      const fsAbt = rootGetters['newProductDetail/fsAbt'] || {}  
      const nowStamp = new Date().getTime()
      const endTimestamp = timeStamp * 1000
      const isEndTimeIn24 = endTimestamp - nowStamp > 0 && endTimestamp - nowStamp < 60 * 60 * 24 * 1000
      // 展示得下但abt【FlashSaleCountDown=Hide】命中倒计时不展示，或倒计时大于24h，则右侧均不展示倒计时
      if(fsAbt?.FlashSaleCountDown?.p?.FlashSaleCountDown !== 'Show' || !isEndTimeIn24) {
        return 0
      }
      return endTimestamp - nowStamp
    },
    
  },
  mutations: {
    updateStoreCategoriesSale(state, payload) {
      state.storeCategoriesSale = payload
    },
    updateStoreCategoryRecommend(state, payload) {
      state.storeCategoryRecommend = payload
    },
    updatePage(state, payload) {
      state.page = payload
    },
  },
  actions: {
    // 更新bff-realtime接口数据，主要是为了获取store_atmosphere_info
    async updateBffInfo({ commit, dispatch, rootState, rootGetters }){
      const goods_id = rootGetters['newProductDetail/common/goodsId'] || ''
      const mallCode = rootGetters['newProductDetail/common/mallCode'] || ''
      const bffInfo = await schttp({
        method: 'GET',
        url: '/product/get_goods_detail_realtime_data',
        params: {
          goods_id,
          mallCode: mallCode,
        },
        useBffApi: true,
      })
      if(+bffInfo?.code === 0){
        commit('productDetail/update_hot', bffInfo?.info, { root: true })
      }
    },
    /**
     * @name getStoreCategoriesSale 通过店铺id和站点分页查询店铺在售品类
     */
    async getStoreCategoriesSale({ commit, dispatch, rootState, rootGetters }) {
      const fsAbt = rootGetters['newProductDetail/fsAbt'] || {}
      const isInFashionStore = rootGetters['newProductDetail/common/isInFashionStore'] || false
      const { store, category } = rootState.newProductDetail?.coldModules || {}
      const { quantity, storeInfo } = store || {}
      const { storeCode } = storeInfo || {}

      // 时尚店铺 + 该店铺总在售商品数＞50才继续
      if(quantity < 50 || fsAbt.storecate?.param?.storecate !== 'show' || !storeCode || !isInFashionStore) return

      const { parentCats } = category || {}
      const cateIds = getAllCateIds(parentCats)
      const params = {
        storeCode,
        cateIds,
      }  
      const res = await schttp({
        url: '/api/productInfo/categoriesSales/get',
        method: 'get',
        params
      })
      const list = res?.data ?? []
      commit('updateStoreCategoriesSale', list)
      if(list.length) {
        dispatch('getStoreCategoryRecommend', list)
      }
    },

    /**
     * @name getStoreCategoryRecommend 以类目id去查询推荐接口
     */
    async getStoreCategoryRecommend({ commit, rootState }, payload) {
      const { storeCode } = rootState.newProductDetail?.coldModules?.store?.storeInfo || {}
      if (!storeCode) return

      const storeSelects = await schttp({
        url: '/api/productInfo/storeSelects/get',
        method: 'get',
        params: { store_codes: [storeCode] }
      })
      const selection_id = storeSelects?.data?.info?.[0].sc_url_id ?? ''
      const filters = payload.map(item => ({ cate_id: item.skuCateId }))
      const businessInfo = {
        context_params: {
          return_img_detail: 0,
          selection_id,
          filters,
        },
        limit: 30,
        offset: 0,
        page_enable: 0,
        req_num: 100,
        scene_id: 86
      }

      const result = await schttp({
        url: '/api/productInfo/categoryRecommend/get',
        method: 'post',
        data: businessInfo
      })
      const list = result.info.list

      //推荐返回的并未排序，需要再次根据入参顺序来排序
      const goods = []
      const listObj = list.reduce((obj, item) => {
        obj[item.filter_cate_id] = item
        return obj
      }, {})
      
      filters.forEach(cate => {
        let curItem = listObj[cate.cate_id]
        if(curItem) {
          goods.push({
            cat_id: curItem.filter_cate_id,
            goods_id: curItem.goods_id
          })
        }
      })
      const res = await itemSer.getAtomicInfoNew({
        goods,
        fields: { 
          detailImage: true,
          catName: true,
        }
      })
      commit('updateStoreCategoryRecommend', res.filter(item => item.cat_name && item.main_image))
    }
  }
}
