function label_language (language) {
  return {
    'Backpacks': language.SHEIN_KEY_PWA_17597,
    'Belts': language.SHEIN_KEY_PWA_17598,
    'Boots': language.SHEIN_KEY_PWA_17599,
    'Dresses': language.SHEIN_KEY_PWA_15679,
    'Earrings': language.SHEIN_KEY_PWA_16805,
    'Coats': language.SHEIN_KEY_PWA_15683,
    'Glasses': language.SHEIN_KEY_PWA_16804,
    'GlovesAndMitten': language.SHEIN_KEY_PWA_17600,
    'Hats': language.SHEIN_KEY_PWA_17595,
    'Jackets': language.SHEIN_KEY_PWA_15187,
    'Jumpsuits': language.SHEIN_KEY_PWA_15681,
    'NightMorning': language.SHEIN_KEY_PWA_17601,
    'PouchBag': language.SHEIN_KEY_PWA_17602,
    'Shoes': language.SHEIN_KEY_PWA_15046,
    'Shorts': language.SHEIN_KEY_PWA_15678,
    'Skirts': language.SHEIN_KEY_PWA_15045,
    'SportShoes': language.SHEIN_KEY_PWA_17603,
    'Sunglasses': language.SHEIN_KEY_PWA_17604,
    'Ties': language.SHEIN_KEY_PWA_17605,
    'Trousers': language.SHEIN_KEY_PWA_17594,
    'Underwear': language.SHEIN_KEY_PWA_17596,
    'WalletsPurses': language.SHEIN_KEY_PWA_17606,
    'Watches': language.SHEIN_KEY_PWA_17607,
    'Bags': language.SHEIN_KEY_PWA_17614,
    'Bracelets': language.SHEIN_KEY_PWA_17615,
    'Cufflinks': language.SHEIN_KEY_PWA_17616,
    'Makeup': language.SHEIN_KEY_PWA_17617,
    'Necklaces': language.SHEIN_KEY_PWA_17618,
    'Rings': language.SHEIN_KEY_PWA_17619,
    'Sandals': language.SHEIN_KEY_PWA_17620,
    'Scarfs': language.SHEIN_KEY_PWA_17621,
    'Shirts': language.SHEIN_KEY_PWA_17622,
    'SocksAndTights': language.SHEIN_KEY_PWA_17623,
    'SportsGear': language.SHEIN_KEY_PWA_17624,
    'Suitcases': language.SHEIN_KEY_PWA_17625,
    'SwimwearBikini': language.SHEIN_KEY_PWA_17626,
    'SwimwearBikiniTop': language.SHEIN_KEY_PWA_17627,
    'SwimwearBikiniBottom': language.SHEIN_KEY_PWA_17628,
    'SwimwearOnePiece': language.SHEIN_KEY_PWA_17629,
    'SwimwearShorts': language.SHEIN_KEY_PWA_17630,
    'Vestes': language.SHEIN_KEY_PWA_17631,
    'Blouses': language.SHEIN_KEY_PWA_15680,
    'Kimonos': language.SHEIN_KEY_PWA_17771,
    'ShortCoats': language.SHEIN_KEY_PWA_18409,
    'LongCoats': language.SHEIN_KEY_PWA_18410,
    'Tanktops': language.SHEIN_KEY_PWA_18411,
    'Blazers': language.SHEIN_KEY_PWA_18412,
    'Bra': language.SHEIN_KEY_PWA_18413,
    'Bodysuits': language.SHEIN_KEY_PWA_18414,
    'Jewelry': language.SHEIN_KEY_PWA_18415,
    'HairAccessories': language.SHEIN_KEY_PWA_18416,
    'FaceMasks': language.SHEIN_KEY_PWA_18417,
    'PhoneCases': language.SHEIN_KEY_PWA_18418,
    'Sweaters': language.SHEIN_KEY_PWA_15682,
    'Sweatshirt': language.SHEIN_KEY_PWA_18547,
    'Customize': language.SHEIN_KEY_PWA_19519,
    'Ballerina': language.SHEIN_KEY_PWA_20827,
    'Cardigans': language.SHEIN_KEY_PWA_20828,
    'CoverUps': language.SHEIN_KEY_PWA_20829,
    'DorsayShoes': language.SHEIN_KEY_PWA_20830,
    'DressShoes': language.SHEIN_KEY_PWA_20831,
    'Eyeglasses': language.SHEIN_KEY_PWA_20832,
    'FashionAccessories': language.SHEIN_KEY_PWA_20833,
    'Gladiator': language.SHEIN_KEY_PWA_20834,
    'HairBands': language.SHEIN_KEY_PWA_20835,
    'Hairpins': language.SHEIN_KEY_PWA_20836,
    'MensUnderwear': language.SHEIN_KEY_PWA_20838,
    'Nightwear': language.SHEIN_KEY_PWA_20840,
    'Panties': language.SHEIN_KEY_PWA_20841,
    'Perfumes': language.SHEIN_KEY_PWA_20842,
    'PhoneCovers': language.SHEIN_KEY_PWA_20843,
    'Pumps': language.SHEIN_KEY_PWA_20844,
    'Rompers': language.SHEIN_KEY_PWA_20845,
    'Scarves': language.SHEIN_KEY_PWA_20846,
    'Scrunchies': language.SHEIN_KEY_PWA_20847,
    'SlipOn': language.SHEIN_KEY_PWA_20848,
    'Slippers': language.SHEIN_KEY_PWA_20849,
    'SportsBras': language.SHEIN_KEY_PWA_20850,
    'Suspenders': language.SHEIN_KEY_PWA_20851,
    'SwimwearRashGuard': language.SHEIN_KEY_PWA_20852,
    'Umbrellas': language.SHEIN_KEY_PWA_20853,
    'Vests': language.SHEIN_KEY_PWA_20854,
    'Wallets': language.SHEIN_KEY_PWA_20855,
    'Wristbands': language.SHEIN_KEY_PWA_20856,
  }
}

export default label_language
