import state from './state'
import getters from './getters'
import mutations from './mutations'
import actions from './actions/index'
import components from './components'
import common from './common'

export default {
  namespaced: true,
  modules: { ...components, common },
  state,
  getters,
  mutations,
  actions
}
