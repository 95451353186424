
const mutations = {
  assignState (state, payload) {
    Object.assign(state, payload)
  },
  updateNecessaryData (state, payload) {
    Object.assign(state.necessaryData, payload)
  },
}

export default mutations
