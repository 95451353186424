export default {
  showCommentsPopup: false, // 是否显示评论弹窗
  showTryOnArContent: false, // 是否展示ar试穿模块
  descriptionReady: false,
  userManualReady: false,
  sizeGuideReady: false,
  sizeRecommandReady: false,
  braSizeRecommendReady: false,
  checkSizeAndSizeGuideReady: false,
}
