import { cloneDeep } from 'lodash'

const state = {
  language: {},
  hcsum: 0, // 已废弃？
  status: { // 页面状态
    // quickAdd: false,
    firstLoad: true, // 骨架图状态
    pageLoading: false, // 弹窗蒙层状态
    scrollLoading: false, // 下拉滚动状态
    noData: false,
    filter: false
  },
  products: [], // 接口返回商品数据
  filterAttrs: [], // 接口返回属性筛选数据
  filterCates: [], // 接口返回分类筛选数据
  // 当前选择的固定属性项
  staticSelectedAttr: {},
  // 选中的属性项
  selectedAttrs: [],
  queryParams: { // URL 绑定数据
    limit: 10,
    sort: 0,
    _currency: '',
    page: 0,
    attr_ids: '',
    attr_values: '',
    // 分类筛选
    child_cat_id: 0,
    stockEnough: '',
    reducePrice: '',
    // wishlistTag: '',
    minPrice: '',
    maxPrice: ''
  },
  sum: undefined,
  noData: false,
  hasFilter: false,
  cateLinks: [],
  isDone: false,
  productListId: null,
  needUpdateData: false,
  exposeInfo: {
    listPageId: ''
  },
  totalCount: null, // 无筛选收藏总数
  defaultMinPrice: '',
  defaultMaxPrice: ''
}

const initialState = cloneDeep(state)

const mutations = {
  assignWSState (state, payload) {
    Object.assign(state, payload)
  },
  deleteWishList (state) {
    state.count--
  },
  addWishList (state) {
    console.log(state, 3)
    state.sum++
  },
  changeStatus (state, payload) {
    state.status = { ...state.status, ...payload }
  },
  changeQueryParams (state, { key, value }) {
    state.queryParams[key] = value

    appEventCenter.emit('queryParamsChange', state) // 无用？
  },
  handleAttrItemClick (state, item) {
    if (item.active) {
      item.active = false
    } else {
      item['active'] = true
    }

    const staticSelectedAttr = state.filterAttrs.find(attr => attr.attr_id == item.attr_id)
    if (staticSelectedAttr && staticSelectedAttr.attr_values.some(item => item.active)) state.staticSelectedAttr = staticSelectedAttr
    state.exposeInfo.listPageId = Date.now()
  }
}

const actions = {
  // 属性项跟分类项重置
  handleAttrOrCateReset ({ state, commit }, { resetAll, attr_id }) {
    if (resetAll) {
      state.queryParams = {
        ...{},
        ...state.queryParams,
        ...{
          attr_str: '', // 后期删除
          attr_ids: '',
          attr_values: '',
          minPrice: '',
          maxPrice: '',
          child_cat_id: 0,
          page: 1,
          stockEnough: '',
          reducePrice: '',
          // wishlistTag: ''
        }
      }
      // 这里也会造成 queryParams.attr_str attr_ids变化  所以异步处理不然 watch queryParams会执行两次
      this.$nextTick(() => {
        state.filterAttrs = state.filterAttrs.map(item => {
          item.attr_values.forEach(subAttr => {
            subAttr['active'] = false
          })
          return item
        })
      })
    } else {
      if (attr_id) {
        state.filterAttrs
          .find(item => item.attr_id == attr_id)
          .attr_values.forEach(subAttr => {
            subAttr['active'] = false
          })
      }
    }

    commit('assignWSState', {
      exposeInfo: {
        listPageId: Date.now()
      }
    })
  },
  changePriceRange ({ commit, state }, { minPrice, maxPrice }) {
    const isReset = state.minPrice == minPrice && state.maxPrice == maxPrice

    commit('assignWSState', {
      queryParams: Object.assign(state.queryParams, {
        minPrice: isReset ? '' : minPrice,
        maxPrice: isReset ? '' : maxPrice,
        page: 1
      })
    })
  }
}

const getters = {
  sortMap (state) {
    const { language } = state
    return [
      {
        key: language.SHEIN_KEY_PWA_16936,
        keyEn: 'Recently Added',
        value: 0
      },
      {
        key: language.SHEIN_KEY_PWA_15556,
        keyEn: 'Most popular',
        value: 12
      },
      {
        key: language.SHEIN_KEY_PWA_15558,
        keyEn: 'Price Low To High',
        value: 9
      },
      {
        key: language.SHEIN_KEY_PWA_15559,
        keyEn: 'Price High To Low',
        value: 10
      },
      {
        key: language.SHEIN_KEY_PWA_15557,
        keyEn: 'New Arrivals',
        value: 11
      }
    ]
  }
}

export {
  initialState
}

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations
}
