/**
 * @name Cache 商详预取缓存 策略: 曝光LRU策略
 */
class Cache {
  constructor(maxLength = 100) {
    this.maxLength = maxLength // 缓存最大长度
    this.cachePretchFSInfo = new Map()
  }

  /**
   * @name set
   * @param {*} id goodsId
   * @param {*} value goodsInfo
   * @returns
   */
  set(id, value) {
    this.cachePretchFSInfo.set(+id, value)

    if (this.cachePretchFSInfo.size > this.maxLength) {
      // 如果超出了容量，则删除 Map 最老的元素
      const delKey = this.cachePretchFSInfo.keys().next().value
      this.cachePretchFSInfo.delete(delKey)
    }
  }

  /**
   * @name renewalSet
   * @param {*} id goodsId
   */
  renewalSet(id) {
    const value = this.get(+id)
    if(!value) return
    this.cachePretchFSInfo.delete(+id)
    this.set(+id, value)
  }

  /**
   * @name setAssemble
   * @param {*} list  key: goodsId, value: goodsInfo
   */
  setAssemble(list) {
    Object.keys(list).forEach((id) => {
      if (!this.cachePretchFSInfo.has(+id)) {
        this.set(+id, list[id])
      } else {
        this.renewalSet(+id, list[id])
      }
    })
  }

  /**
   *
   * @param {*} id goodsId
   * @returns
   */
  get(id) {
    if (!this.cachePretchFSInfo.has(+id)) {
      return null
    }
    return this.cachePretchFSInfo.get(+id)
  }

  clearAll() {
    this.cachePretchFSInfo.clear()
  }
}

const detailPretchFSInfoCache = new Cache()
export default detailPretchFSInfoCache
