import { state } from '../state'
import { EAtomFieldsType } from '../const'

/**
 * 服务端异步配置项
 * services/goods/ProductConfigManager/AtomFields/index.js
 */
export const getAtomFieldsByType = ({ type, useNewAbt, isRecommend }) => {
  const { isNewPic2, isNewPic3 } = state.value.commonConfig
  /**
   * 新商卡映射旧商卡枚举
   */
  const typeNewToV1Map = {
    [EAtomFieldsType.Pic2]: isNewPic2
      ? EAtomFieldsType.Pic2
      : EAtomFieldsType.Pic2V1,
    [EAtomFieldsType.Pic3]: isNewPic3
      ? EAtomFieldsType.Pic3
      : EAtomFieldsType.Pic3V1
  }

  const isNew = ![EAtomFieldsType.Pic2V1, EAtomFieldsType.Pic3V1].includes(type)

  // 是否走abt判断新商卡
  const _type = useNewAbt ? typeNewToV1Map[type] || type : type
  const fields = state.value.atomFields[_type]
  if (!fields) throw `$${type} ${_type} type invalid`

  // 兼容推荐场景下的不同原子
  const { starComment, recommendStarComment } = fields
  if (starComment || recommendStarComment) {
    fields.recommendStarComment = fields.starComment = undefined
    fields[isRecommend ? 'recommendStarComment' : 'starComment'] = starComment || recommendStarComment
  }

  return { fields, trueType: _type, type, isNew }
}
