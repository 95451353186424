const state = {
  isSSR: false,
  locals: null,
  abtInfo: {},
  language: {},
  oneClickGoodsWithOrderData: {},
  timeData: {},
  countdownStartTime: 0,
  isOrderFillOneClickPay: true,
  activityFrom: '',
  maximumTimes: 5,
  billno: '',
  langPath: ''
}

export default state
