import { ESTIMATED_DATA_TYPES } from 'public/src/pages/product_app/store/modules/product_detail/utils/price.js'
/**
 * @field 库存相关
 */
export default {
  is_sold_out(state, getters, rootState, rootGetters) { // TODO 旧
    return rootGetters['newProductDetail/common/isSoldOut']
  },
  mall_stock(state, getters, rootState, rootGetters) { // TODO 旧
    return rootGetters['newProductDetail/common/mallStock']
  },
  // 是否下架状态
  is_on_sale(state, getters, rootState) {
    const { is_on_sale } = rootState.productDetail.coldModules.productInfo || {}
    return is_on_sale == '1'
  },
  // skc维度是否售罄
  is_skc_sold_out(state, getters, rootState, rootGetters) {
    const skc_stock = rootGetters['productDetail/skc_stock']
    const { is_on_sale } = rootState.productDetail.coldModules.productInfo || {}
    return !+skc_stock || !+is_on_sale
  },
  will_sold_out_tips_info(state, getters, rootState) {
    // 低库存提示
    const { sku_info } = getters || {}
    const { saleAttr } = rootState.productDetail.hotModules || {}
    const { multiLevelSaleAttribute } = saleAttr || {}
    const { unselected_will_sold_out_tips, lowStockType } = multiLevelSaleAttribute || {}
    if (sku_info?.sku_code) {
      return {
        tip: sku_info?.selected_will_sold_out_tips || '',
        lowStockType: sku_info?.lowStockType,
        scene: 'sku',
      }
    }
    return {
      tip: unselected_will_sold_out_tips || '',
      lowStockType,
      scene: 'skc',
    }
  },
  stock_tip_show_location(state, getters, rootState, rootGetters) {
    const estimated_info = rootGetters['productDetail/price/estimated_info']
    const { productInfo } = rootState.productDetail.hotModules || {}
    const { cccDetailsTemplate } = productInfo || {}
    const { content } = cccDetailsTemplate || {}
    // TODO: 到手价库存相关展示的位置是不是也能收口到这里
    if (ESTIMATED_DATA_TYPES.N_ESTIMATED === estimated_info?.dataType) return ''
    if (content?.[0]?.countCell == '1') return 'qty'
    return 'line'
  },
}
