import Top from './top.js'
import SerialProducts from './innerComponents/SerialProducts'
import Carousels from './innerComponents/Carousels'
import GoodsName from './innerComponents/GoodsName'
import ColorBox from './innerComponents/ColorBox'
import Price from './innerComponents/Price'
import PromotionEnter from './innerComponents/PromotionEnter'
import TopOther from './innerComponents/TopOther'
import SizeBox from './innerComponents/SizeBox'
import PriceBelt from './innerComponents/PriceBelt'
import SaleAttrsFold from './innerComponents/SaleAttrsFold.js'

export default {
  Top,
  SerialProducts,
  Carousels,
  GoodsName,
  ColorBox,
  Price,
  SizeBox,
  PromotionEnter,
  TopOther,
  PriceBelt,
  SaleAttrsFold,
}
