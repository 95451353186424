const state = {
  routerUrl: null,
  isLogin: false,
  userInfo: null,
  isRouterLeaveFlag: false,
  dialogName: 'default',
  isRisk: false, // 是否风控
  SiteUID: '', // 当前站点
  userName: '', // 用户名
  statusType: undefined,
}

const mutations = {
  setStoreState(state, payload) {
    Object.assign(state, payload)
  },
}

export default {
  namespaced: true,
  state,
  mutations
}
