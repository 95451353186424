import getters from '../product_list_v2/getters'
export default {
  ...getters,
  picksMenuList (state) {
    return state.Results.picksMenuList || []
  },
  bgBanner (state) {
    const src = state.Results?.bgBanner?.image?.src || ''
    if (!src || !state?.Results?.listAbtResult?.SheinPicksNavigation?.p?.SheinPicksNavigation) return null

    return {
      src,
      desc: state.Results?.bgBanner?.topDesc,
      color: state.Results?.bgBanner?.topDescColor,
    }
  }
}
