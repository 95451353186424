export default {
  namespaced: true,
  state: {
    videoShowFlag: true
  },
  getters: {
    hasVimeoUrl(_, __, rootState) {
      return Boolean(rootState.newProductDetail.coldModules.productInfo.goods_imgs?.video_url)
    },

    hasVideoUrl(_, __, ___, rootGetters) {
      const videoSourceData = rootGetters['newProductDetail/Carousels/videoSourceData']
      return Boolean(videoSourceData?.link)
    },

    showNativePlayer(state, getters, __, rootGetters) {
      const fsAbt = rootGetters['newProductDetail/fsAbt']
      const isNativePlayer = fsAbt?.nativevideo?.p?.nativevideo === 'new'
      return getters.hasVideoUrl && isNativePlayer && state.videoShowFlag
    },

    showVimeoPlayer(state, getters) {
      return getters.hasVimeoUrl && !getters.showNativePlayer && state.videoShowFlag
    },
  },
  mutations: {
    updateVideoShowFlag(state, payload = false) {
      state.videoShowFlag = payload
    }
  }
}
