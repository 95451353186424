import { recommendPublicProductItemConfig, extendsComplianceModeConfig } from 'public/src/pages/goods_detail/RecommendRelatives/constants.js'
import { getFormatBuyboxProductList } from 'public/src/pages/common/BuyBoxPopup/utils.js'

export default {
  lookbookInfo(state, getters, rootState) {
    return rootState.newProductDetail.coldModules.recommend.lookBookInfo || []
  },
  showSimilarItems(state, getters, rootState, rootGetters) {
    const isOutSite = rootState.newProductDetail.common.isOutSite
    const unifiedRecommend = rootGetters['newProductDetail/unifiedRecommend']
    const showSimilarItems = isOutSite
      ? false
      : ['one', 'two'].includes(unifiedRecommend?.similarRows)
    return showSimilarItems
  },
  // 不显示搭配购（老 + 新）
  hideGtlAndOutfit(state, getters, rootState, rootGetters) {
    const fsAbt = rootGetters['newProductDetail/fsAbt']
    const isForwardCategory = rootGetters['newProductDetail/TopOther/isForwardCategory']
    const { outfitshowcate = 'all' } = fsAbt?.newoutfit?.param || {}
    const hiddenAll = outfitshowcate === 'onlygood' && !isForwardCategory // 命中abt仅正向品类展示搭配模块(老+新)
    if (hiddenAll) return true
    return false
  },
  // 不展示老搭配购
  hideOldGtlAndOutfit(state, getters) {
    const { hideGtlAndOutfit, showNewGtlAndOutfit } = getters || {}
    return hideGtlAndOutfit || showNewGtlAndOutfit
  },
  // 是否展示新搭配购（outfits + pdc gtl）
  showNewGtlAndOutfit(state, getters, rootState, rootGetters) {
    const fsAbt = rootGetters['newProductDetail/fsAbt']
    const { newoutfit } = fsAbt?.newoutfit?.param || {}
    if (getters.hideGtlAndOutfit) return false
    return newoutfit === 'new'
  },
  // 加车弹窗推荐abt配置
  addBagRecommendConfig(state, getters, rootState, rootGetters) {
    const fsAbt = rootGetters['newProductDetail/fsAbt']
    const addbagsuccess = fsAbt?.addbagsuccess || {}
    return {
      addbagRecQuickShow: addbagsuccess?.param?.addbagsuccess === 'quickshow', // 打开加车弹窗，才出现
      addbagRecAllShow: addbagsuccess?.param?.addbagsuccess === 'allshow', // 主商品加车都出现
      addbagRecStyleOne: !['tworec', 'onebigrec'].includes(addbagsuccess?.param?.addbagtype), // 推荐弹窗样式一
      addbagRecStyleTwo: addbagsuccess?.param?.addbagtype === 'tworec', // 推荐弹窗样式二
      addbagRecStyleThree: addbagsuccess?.param?.addbagtype === 'onebigrec', // 推荐弹窗样式三
    }
  },
  // 加车弹窗推荐时机出来逻辑
  showAddBagRecDrawerScene(state, getters, rootState, rootGetters) {
    const { addbagRecAllShow, addbagRecQuickShow, addbagRecStyleOne } = getters.addBagRecommendConfig
    const curCouponList = rootGetters['newProductDetail/PromotionEnter/curCouponList']
    const needCoupon = addbagRecStyleOne ? !!curCouponList.length : true
    let count = 0
    if (typeof sessionStorage !== 'undefined') {
      const _KEY = 'productDetail_addBagRecCount'
      count = Number(sessionStorage.getItem(_KEY))
    }
    return {
      showAddBagRecDrawerSceneOne: addbagRecQuickShow && needCoupon && count < 3,
      showAddBagRecDrawerSceneTwo: addbagRecAllShow && needCoupon && count < 3
    }
  },
  showBuyBoxSellers(state, getters, rootState, rootGetters) {
    const tsp = rootState.newProductDetail.coldModules.tsp?.tsp || {}
    return getters.needBuyBoxSellers && !tsp['4525']
  },
  needBuyBoxSellers(state, getters, rootState, rootGetters) {
    const fsAbt = rootGetters['newProductDetail/fsAbt']
    const { BuyBoxSwitch } = fsAbt?.BuyBoxSwitch?.param || {}
    const tsp = rootState.newProductDetail.coldModules.tsp?.tsp || {}
    return ['A', 'B', 'C'].includes(BuyBoxSwitch) && getters.buyBoxSameId 
  },
  buyBoxSameId(state, getters, rootState, rootGetters) {
    const tsp = rootState.newProductDetail.coldModules.tsp?.tsp || {}
    return tsp['4528'] || ''
  },
  isMainGoodsLowestPrice(state, getters, rootState, rootGetters) {
    // 1.是否是保留款商品, 是则结束
    const tsp = rootState.newProductDetail.coldModules.tsp?.tsp || {}
    const isReservedGood = tsp?.['3598'] || ''
    if(isReservedGood) return 0

    // 2. 是否命中buyboxABT, 否即结束
    const fsAbt = rootGetters['newProductDetail/fsAbt']
    const { BuyBoxSwitch } = fsAbt?.BuyBoxSwitch?.param || {}
    const isBuyBoxSwitch = ['A', 'B', 'C'].includes(BuyBoxSwitch)
    if(!isBuyBoxSwitch) return 0

    // 3. 是否命中评论拼接ABT, 否即结束
    const { Comment_Group } = fsAbt?.Reviewchange?.param || {}
    const isCommentJoin = Comment_Group == 'A'
    if(!isCommentJoin) return 0

    // 4. 当前商品是否有同款组, TSP标签 4528有值，有同款组, 否即结束
    const buyBoxSameId = getters.buyBoxSameId
    if(!buyBoxSameId) return 0

    const mainGoodsId = rootGetters['newProductDetail/common/goodsId']
    const productItemsLanguage = rootGetters['newProductDetail/common/productItemsLanguage']
    const buyBoxRecInfo = rootState.newProductDetail?.common?.buyBoxRecInfo
    const { _newList } = getFormatBuyboxProductList(buyBoxRecInfo, false, productItemsLanguage)
    const lowerPriceGoodId = _newList?.find(item => item._serFormatInfo?.isLowerPice)?.goods_id || ''
    return lowerPriceGoodId == mainGoodsId ? 1 : 0
  },
  // 新搭配购数据
  newGtlEntryInfo(state, getters) {
    const { showNewGtlAndOutfit } = getters || {}
    if (!showNewGtlAndOutfit || !state?.newGtlAndOutfitInfo?.length) return null
    return state.newGtlAndOutfitInfo?.[0]
  },
  /**
   * 使用新组合购模块
   */
  isShowNewComboBuy(state, getters, rootState) {
    // return true
    if (!getters.isShowNewComboBuyAbt) return false

    return !!rootState.newProductDetail.common.hasValidComboBuyRecommend
  },
  isShowNewComboBuyAbt(state, getters, rootState, rootGetters) {
    // return true
    const fsAbt = rootGetters['newProductDetail/fsAbt']
    return fsAbt?.buytogether?.p?.buytogether === 'show'
  },
  // 新组合购模块新样式
  isShowNewComboBuyStyleAbt(state, getters, rootState, rootGetters) {
    // return true
    const fsAbt = rootGetters['newProductDetail/fsAbt']
    return fsAbt?.buytogether?.p?.buytogethershow === 'new'
  },
  // 新组合购模块屏效提升版样式
  isShowNewComboBuyScreenStyleAbt(state, getters, rootState, rootGetters) {
    // return true
    const fsAbt = rootGetters['newProductDetail/fsAbt']
    return fsAbt?.buytogether?.p?.height === 'short'
  },
  // 新组合购模块橙色版屏效提升版样式
  isShowNewComboBuyOrangeScreenStyleAbt(state, getters) {
    const { isShowNewComboBuyScreenStyleAbt, isShowNewComboBuyStyleAbt } = getters || {}
    return isShowNewComboBuyScreenStyleAbt && isShowNewComboBuyStyleAbt
  },
  // 新组合购模块屏效提升版无规格选择器样式 需命中屏效提升
  isHideNewComboBuyScreenStyleSizeAbt(state, getters, rootState, rootGetters) {
    // return true
    const fsAbt = rootGetters['newProductDetail/fsAbt']
    const { isShowNewComboBuyScreenStyleAbt } = getters || {}
    return isShowNewComboBuyScreenStyleAbt && fsAbt?.buytogether?.p?.chooseshow === 'none'
  },
  /**
   * 主商品的重叠类目id，string,必填 ,多个用逗号隔开，131场景使用
   */
  recommendMainGoodsOverlapIds(state, getters, rootState) {
    const tsp = rootState.newProductDetail.coldModules.tsp?.tsp || {}
    return tsp['4607'] || ''
  },
  // 新搭配推荐数据源用 ymals
  isOutfitShowYmalSource(state, getters, rootState, rootGetters) {
    const fsAbt = rootGetters['newProductDetail/fsAbt']
    return fsAbt.newoutfit?.param?.outfitsimilar === 'show-ymal'
  }
}
