function formatList (list = []) {
  list.forEach((item, index) => {
    item.firstImg = item.comment_image?.[0]?.member_image_middle || item.img || ''
    // 红人图没有comment_id,为了统一适配大图，添加comment_id
    !item.comment_id && (item.comment_id = item.id + '_' + index)
  })
  return list
}
export default {
  namespaced: true,
  state: {
    commentScrollLoading: false, 
    noMoreComment: false
  },
  getters: {
    commentsWithImg(state, getters, rootState) {
      const { comment } = rootState.newProductDetail.hotModules
      const { commentsWithImg = {} } = comment || {}
      return commentsWithImg
    },
    // 去重以后的评论数据
    filterCommentConfig(state, getters, rootState) {
      const { recommend } = rootState.newProductDetail.coldModules
      const { lookBookInfo = [] } = recommend || {}
      const excellentReviewList = lookBookInfo.filter(item => item.imageType === 'review') || []
      let commentImgList = getters.commentsWithImg?.info?.commentInfo ?? []
      let excellentReviewMap = {}
      let filterNumber = 0
      excellentReviewList.forEach(item => {
        excellentReviewMap[item.commentId] = item
      })
      commentImgList = commentImgList.filter(item => {
        if(excellentReviewMap[item.comment_id]){
          filterNumber = filterNumber + 1
          return false
        }
        return true
      })
      return {
        filterCommentImgList: commentImgList,
        filterNumber: filterNumber
      }
    },
    customerShowList(state, getters, rootState) {
      const { recommend, comment } = rootState.newProductDetail.coldModules
      const dataSource = getters.reviewAbt.dataSource
      const { freeTrailReports = [] } = comment || {}
      const filterCommentImgList = getters.filterCommentConfig.filterCommentImgList
      const { lookBookInfo = [] } = recommend || {}
      lookBookInfo.forEach(item => item.type = 'gallery')
      const hotPersonList = lookBookInfo.filter(item => item.imageType === 'wear') || []
      const excellentReviewList = lookBookInfo.filter(item => item.imageType === 'review') || []
      if(dataSource === 'Gals'){ //数据源=红人图，社区接口提供
        return formatList(hotPersonList)
      } else if(dataSource === 'FeaturedBuyerShow'){ //数据源=精选评论图，社区接口提供
        return formatList(excellentReviewList)
      }else if(dataSource === 'BuyerShow'){ //数据源=评论图，CMT接口提供
        return formatList(filterCommentImgList)
      }else if(dataSource === 'Gals_FeaturedBuyerShow'){ //数据源=红人图+精选评论图，社区接口提供
        return formatList([...hotPersonList, ...excellentReviewList])
      }else if(dataSource === 'Gals_FeaturedBuyerShow_TrailReport_BuyerShow'){ //数据源=红人图+精选评论图+试用报告+评论图 ，社区接口+CMT接口+试用接口 
        return formatList([...hotPersonList, ...freeTrailReports, ...excellentReviewList, ...filterCommentImgList])
      }else if(dataSource === 'FeaturedBuyerShow_TrailReport'){ //数据源=精选评论图+试用报告，社区接口+试用接口
        return formatList([...excellentReviewList, ...freeTrailReports])
      }else if(dataSource === 'FeaturedBuyerShow_TrailReport_BuyerShow'){ //数据源=精选评论图+试用报告+评论图，社区接口+试用接口+CMT接口
        return formatList([...excellentReviewList, ...freeTrailReports, ...filterCommentImgList])
      }
      return []
    },
    // 所有买家秀总数之和 红人图 + 有图评论 + 报告数（不包括站外评论）
    allCustomerGallerySum(state, getters, rootState) {
      const { recommend, comment } = rootState.newProductDetail.coldModules
      const dataSource = getters.reviewAbt.dataSource
      const { freeTrailReports = [] } = comment || {}
      const commentInfoTotal = (getters.commentsWithImg?.info?.commentInfoTotal ?? 0) - getters.filterCommentConfig.filterNumber
      const { lookBookInfo = [] } = recommend || {}
      lookBookInfo.forEach(item => item.type = 'gallery')
      const hotPersonList = lookBookInfo.filter(item => item.imageType === 'wear') || []
      const excellentReviewList = lookBookInfo.filter(item => item.imageType === 'review') || []
      // return 7
      if(dataSource === 'Gals'){ //数据源=红人图，社区接口提供
        return hotPersonList.length
      } else if(dataSource === 'FeaturedBuyerShow'){ //数据源=精选评论图，社区接口提供
        return excellentReviewList.length
      }else if(dataSource === 'BuyerShow'){ //数据源=评论图，CMT接口提供
        return commentInfoTotal
      }else if(dataSource === 'Gals_FeaturedBuyerShow'){ //数据源=红人图+精选评论图，社区接口提供
        return lookBookInfo.length
      }else if(dataSource === 'Gals_FeaturedBuyerShow_TrailReport_BuyerShow'){ //数据源=红人图+精选评论图+试用报告+评论图 ，社区接口+CMT接口+试用接口 
        return lookBookInfo.length + freeTrailReports.length + commentInfoTotal
      }else if(dataSource === 'FeaturedBuyerShow_TrailReport'){ //数据源=精选评论图+试用报告，社区接口+试用接口
        return excellentReviewList.length + freeTrailReports.length
      }else if(dataSource === 'FeaturedBuyerShow_TrailReport_BuyerShow'){ //数据源=精选评论图+试用报告+评论图，社区接口+试用接口+CMT接口
        return excellentReviewList.length + freeTrailReports.length + commentInfoTotal
      }
      return 0
    },
    reviewAbt(state, getters, rootState, rootGetters) {
      const fsAbt = rootGetters['newProductDetail/fsAbt']
      const dataSource = fsAbt?.Reviewchange?.param?.DataSource_Review || 'Gals_FeaturedBuyerShow'
      const shinerNoMore = ['None', 'Gals', 'FeaturedBuyerShow', 'Gals_FeaturedBuyerShow', 'FeaturedBuyerShow_TrailReport'].includes(dataSource)
      const galleyLayout = fsAbt?.Reviewchange?.param?.Layout_Review || 'BigPic'
      const reviewText = fsAbt?.Reviewchange?.param?.Reviewtext || 'None'
      let minNum = fsAbt?.Reviewchange?.param?.Minmum_Num || 'None'
      minNum = minNum === 'None' ? 0 : +minNum
      return {
        galleyLayout: galleyLayout,
        minNum: minNum,
        dataSource: dataSource,
        shinerNoMore: shinerNoMore,
        reviewText: reviewText
      }
    },
    showSHEINer(state, getters, ) {
      const { customerShowList, allCustomerGallerySum, reviewAbt } = getters
      return customerShowList?.length && allCustomerGallerySum >= reviewAbt.minNum
    },
  },
  mutations: {
    updateCustomerShowList(state, payload) {
      state.customerShowList = payload
    },
  }
}
