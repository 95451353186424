import Middle from './middle'
import MiddleAttr from './innerComponents/MiddleAttr'
import CommentBox from './innerComponents/CommentBox'
import SizeGuide from './innerComponents/SizeGuide'
import Reviews from './innerComponents/Reviews/Reviews'
import RatePanel from './innerComponents/Reviews/components/RatePanel'
import ShippingEnter from './innerComponents/ShippingEnter.js'
import CCCBannerBox from './innerComponents/CCCBannerBox.js'
import Signboard from './innerComponents/Signboard.js'

export default {
  Middle,
  MiddleAttr,
  CommentBox,
  SizeGuide,
  Reviews,
  RatePanel,
  ShippingEnter,
  CCCBannerBox,
  Signboard,
}
