import state from './state'
import getters from './getters'
import mutations from './mutations'
import actions from './actions'

import components from './components'
import common from './common'

export const NEW_PRODUCT_DETAIL = 'newProductDetail'

export default {
  namespaced: true,
  modules: { ...components, common },
  state,
  getters,
  mutations,
  actions,
}
