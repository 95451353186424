class Cache {
  constructor() {
    this.callBackMap = {
      changeColorReady: [],
    }
  }
  
  get(key = '') {
    if (!key) return []
    return this.callBackMap[key]
  }
  
  set(key = '', callback = (() => {})) {
    this.callBackMap?.[key]?.push(callback)
  }

  clear(key = '') {
    if (!key) return
    return this.callBackMap[key] = []
  }
}
  
export default new Cache()
  
