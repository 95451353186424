
export default {
  namespaced: true,
  getters: {
    /**
     * 促销腰带数据模型 skc纬度、sku纬度
     */
    promotion_belt(state, getters, rootState, rootGetters) {
      const skuInfo = rootGetters['productDetail/common/sku_info']
      const goods_id = rootGetters['productDetail/common/goods_id']
      const data = skuInfo?.beltInfo?.promotionBelt || rootState.productDetail.hotModules?.beltInfo?.promotionBelt
      if (!data) return
      return {
        ...(data || {}),
        _goods_id: goods_id, // 商品id，用于埋点判断是否为相同skc
      }
    },
    // 显示促销腰带
    show_promotion_belt(state, getters, rootState, rootGetters) {
      const is_sold_out = rootGetters['productDetail/common/is_sold_out']
      if (is_sold_out) return false

      const promotionBelt = getters?.promotion_belt
      if (!promotionBelt) return false

      // 促销腰带类型 0-无(纯图) 1-折扣类型 2-倒计时类型 3-金额折扣 4-异形倒计时 5-异形折扣 6-普通可省金额 7-异形可省金额
      // 如果belt.type为1 / 5的时候，折扣为0就不展示
      const _isDiscountBelt = [1, 5].includes(Number(promotionBelt.type))
      if (!_isDiscountBelt) return true
      return Number(promotionBelt.discountPercent) !== 0
    },
    show_free_shipping_belt() {
      // todo
      return
    },
    belt_loaded (state, getters) {
      let { show_promotion_belt, show_free_shipping_belt } = getters
      return show_promotion_belt || show_free_shipping_belt
    },
  }
}
