import { isSwitchBffApiVersion } from 'public/src/pages/common/bff-new/index.js'
import commentAdapter from './adapters/comment.js'

const bffApolloKeys = ['goodsDetail_v1', 'goodsDetail_v2']

const isDetailBffApi = (key) => {
  if (typeof window === 'undefined') return false
  if (!key) {
    console.warn('isDetailBffApi key is empty')
    return false
  }
  const bffResults = isSwitchBffApiVersion(bffApolloKeys) || {}
  const detailKey = 'goodsDetail_' + key
  return !!(bffResults?.[detailKey] || false)
}

export {
  isDetailBffApi,
  commentAdapter,
}
