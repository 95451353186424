import schttp from 'public/src/services/schttp'

export const postRecordInit = async (data) => {
  return await schttp({
    method: 'POST',
    url: '/api/prime/v2/creditRecord/init/post',
    data
  })
}

export const postMemberRecord = async (data) => {
  return await schttp({
    method: 'POST',
    url: '/api/prime/v2/creditReward/memberRecord/post',
    data
  })
}

export const postClaimCredit = async (data) => {
  return await schttp({
    method: 'POST',
    url: '/api/prime/v2/creditReward/claimCredit/post',
    data
  })
}

export const postMemberSummary = async (data) => {
  return await schttp({
    method: 'POST',
    url: '/api/prime/v2/creditReward/memberSummary/post',
    data
  })
}
