export default {
  namespaced: true,
  state: {},
  getters: {
    showLocalRating(state, getters, rootState, rootGetters){
      const showLocalReviews = rootGetters['newProductDetail/Reviews/showLocalReviews']
      const hasLocalComments = getters.hasLocalComments
      return showLocalReviews && hasLocalComments
    },
    localRating(state, getters, rootState, rootGetters) {
      const commentOverViewLocal = rootGetters['newProductDetail/commentOverViewLocal']
      return commentOverViewLocal && commentOverViewLocal.comment_rank_average
    },
    hasLocalComments(state, getters, rootState, rootGetters) {
      const commentOverViewLocal = rootGetters['newProductDetail/commentOverViewLocal']
      return Boolean(commentOverViewLocal && commentOverViewLocal.comment_num)
    }
  }
}
