/**
 * @field 标签
*/
export default {
  category_selection_floor_bo(state, getters, rootState) {
    return rootState.productDetail.hotModules.productLabel?.categorySelectionFloorBO || {}
  },
  category_selection_floor_new(state, getters, rootState, rootGetters) {
    const fs_abt = rootGetters['productDetail/common/fs_abt']
    return fs_abt?.Qualityfloor?.p?.Qualityfloor === 'NEW'
  },
}
