import { languageKeys } from 'public/src/pages/user_store_follow/utils/constants'
import { getLanguageDataSer } from 'public/src/pages/user_store_follow/api/index'

const actions = {
  // 获取多语言
  async initLanguageData({ commit }) {
    const { code = '', info = {} } = await getLanguageDataSer({ languageKeys: languageKeys })
    if (code === '0' && !!info?.result) {
      commit('initLanguage', info?.result)
    }
  }
}
export default actions
