import { postMemberRecord, postMemberSummary } from 'public/src/pages/user/child_pages/prime_credit_record/utils/fetch'

const actions = {
  async memberRecordInit({ state }, data) {
    const [record, memberSummary] = await Promise.all([
      postMemberRecord(data),
      postMemberSummary(),
    ])
    state.memberRecord = record.info ?? {}
    state.showError = record.code != 0 && memberSummary.code != 0
    state.memberSummary = memberSummary.info ?? {}
  },
}
export default actions
