import { isCouponAbPrice } from 'public/src/pages/goods_detail_v2/utils/coupon'

export default {
  namespaced: true,
  state: {},
  getters: {
    // 原始券数据
    origin_coupon_list(state, getters, rootState, rootGetters) {
      return rootGetters['productDetail/detail_promotion_floor']?.couponInfo
        ?.couponInfoList
    },
    ab_price_coupon(state, getters) {
      return getters.origin_coupon_list?.find(d => isCouponAbPrice(d))
    },
    /**
     * 是否展示优惠券倒计时
     */
    is_show_coupon_count_down(state, getters, rootState, rootGetters) {
      const fsAbt = rootGetters['productDetail/common/fs_abt']
      return fsAbt?.EstimatedPrice?.p?.E_Time_Limit == 'Show'
    }
  }
}
