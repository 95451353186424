export default {
  // quickAdd: {
  //   active: false,
  //   goods_id: null,
  //   selectedAttrId: null,
  //   config: {},
  //   // from: slefGoods 改为在config 配置
  //   // from: '',
  //   // slefGoods: true,
  //   index: 0
  // }
}
