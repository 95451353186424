
import schttp from 'public/src/services/schttp'
import { storeListMockData } from './mock'
const use_mock = false
import qs from 'qs'

/**
 * 获取多语言数据
 * @param {多语言key数组} params 
 * @returns 
 */
export const getLanguageDataSer = (params) => {
  return schttp({
    url: '/system/configs/multi_language',
    method: 'POST',
    data: params,
    useBffApi: true
  })
}

/**
 * 获取收藏店铺列表
 * @param {*} params 
 * @returns 
 */
export const getStoreListDataSer = (params) => {
  // mock 数据
  if (use_mock) {
    if (params.lowerBound >= 30) return Promise.resolve({ code: '-1' })
    return Promise.resolve(storeListMockData)
  }

  return schttp({
    url: '/user/wishlist/store/list',
    method: 'POST',
    headers: { 
      'Content-Type': 'application/x-www-form-urlencoded'
    },
    data: qs.stringify(params),
    useBffApi: true
  })
}

/**
 * 更新关注状态
 * @param {*} params 
 * @returns 
 */
export const updateStoreFollowing = (params) => {
  return schttp({
    url: '/product/store/update_following',
    method: 'POST',
    headers: { 
      'Content-Type': 'application/x-www-form-urlencoded'
    },
    data: qs.stringify(params),
    useBffApi: true
  })
}

/**
 * 获取收藏店铺列表
 * @param {*} params 
 * @returns 
 */
export const getStoreRecommendListDataSer = (params) => {
  // mock 数据
  if (use_mock) {
    return Promise.resolve(storeListMockData)
  }

  return schttp({
    url: '/product/recommend/store_list',
    method: 'GET',
    headers: { 
      'Content-Type': 'application/x-www-form-urlencoded'
    },
    params: params,
    useBffApi: true
  })
}
