import schttp from 'public/src/services/schttp'

const actions = {
  initPreSearch({ commit }, payload) {
    const { language = {}, abtData = {}, similarItems } = payload
    commit('assignState', { necessaryData: payload, language, abtData, similarItems })
  },

  updateSearchPageData({ commit }, payload) {
    const { default: defaultWords, hot, paramsGoodsId, scene, store_code, similarItems, trendHome, trendDetails } = payload
    // language abtData 暂时不更新
    commit('updateNecessaryData', { default: defaultWords, hot: hot, paramsGoodsId, scene, store_code,  similarItems, trendHome, trendDetails  })
  },

  /**
   * @description 预搜页找相似
   */
  async getSearchSimilarItems(
    { commit },
    { cate_id, goods_id, abt_rule_similar, abt_rule_similar_rule_id, abt_rule_similar_location }
  ) {
    const { code, data, msg } = await schttp({
      method: 'GET',
      url: '/api/searchwords/similarItems/get',
      params: {
        cate_id,
        goods_id,
        abt_rule_similar,
        abt_rule_similar_rule_id,
        abt_rule_similar_location
      }
    })
    if (code === 0) {
      commit('assignState', { similarItems: data })
    } else {
      console.error(msg)
    }
  },
  /**
   * @description 预搜页找相似 - 重置
   */
  resetSearchSimilarItems({ commit }) {
    commit('assignState', { similarItems: null })
  }
}

export default actions
