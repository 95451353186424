// 券展示类型 1-AB定价伪装券，目前只有主商品价格展示了ab价才会返回该券
export const isCouponAbPrice = item => item?.couponShowType == 1

// 新人券：newUserTips
export const isCouponNewUser = item =>!!item?.newUserTips
// 新人券：newUserTips && 强化：newUserCouponInfo
export const isCouponNewUserStrengthen = item => isCouponNewUser(item) && !!item?.newUserCouponInfo

export const getCouponText = (item = {}) => {
  // todo 免邮券app不展示
  // app这边固定取coupon_rule的第0个底下的newCouponShowTip展示
  return item?.coupon_rule?.[0]?.newCouponShowTip || ''


  // const finalData = []
  // filterCouponList.forEach((item) => {

  //   const tempTextList = []
  //   const _couponRuleFirst = item.coupon_rule?.[0] || {}

  //   // 是无门槛券
  //   const _isWithoutThreshold = _couponRuleFirst?.min?.amount && Number(_couponRuleFirst?.min?.amount) === 0 && !_couponRuleFirst?.max?.amountWithSymbol
  //   const _LANG_KEY = _isWithoutThreshold && language.SHEIN_KEY_PWA_27117 || _couponRuleFirst?.coupon_gift_id && language.SHEIN_KEY_PWA_18334 || language.SHEIN_KEY_PWA_15816

  //   const _pushData = (str, useTrim = true) => {
  //     const _str = useTrim ? str?.trim?.() : str
  //     if (!_str) return
  //     tempTextList.push(_str)
  //   }
  //   if (item?.apply_for == 101) {
  //     let discountValue = coupons.find(estimatedCoupons => estimatedCoupons.apply_for == 101)?.discountValue.amountWithSymbol
  //     _pushData(template(discountValue, language.SHEIN_KEY_PWA_26358))
  //   } else if (['5', '6'].includes(item?.apply_for) || (item?.apply_for == '9' && item?.shipping_discount_type == 0)) {
  //     let text = language.SHEIN_KEY_PWA_15807
  //     if(_isWithoutThreshold){
  //       text += (' ' + language.SHEIN_KEY_PWA_27118)
  //     }
  //     _pushData(text)
  //   } else if (item?.apply_for == '9' && item?.shipping_discount_type == 1){
  //     const money = item?.coupon_type_id == 1 ? _couponRuleFirst?.value?.amountWithSymbol : percentFormat(_couponRuleFirst?.value?.amount)
  //     _pushData(money + ' ' + language.SHEIN_KEY_PWA_29122)
  //   } else if (item?.coupon_type_id == 1) {
  //     _pushData(template(
  //       _couponRuleFirst?.value?.amountWithSymbol,
  //       htmlDecode({
  //         text: _LANG_KEY,
  //       })
  //     ))
  //   } else {
  //     _pushData(template(
  //       percentFormat(_couponRuleFirst?.value?.amount),
  //       htmlDecode({
  //         text: _LANG_KEY,
  //       })
  //     ))
  //   }

  //   _pushData(couponSubTitle(item, language), false)
  //   tempTextList.length && finalData.push({ text: tempTextList.join('') })
  // })
}
