export const SET_DATA = 'SET_DATA'
export const ADD_EXPOSURE = 'ADD_EXPOSURE'
export const FLUSH_ALL_EXPOSURE = 'FLUSH_ALL_EXPOSURE'
export const ALLOW_EXPOSURE = 'ALLOW_EXPOSURE'
export const SET_REPORT_COMMON_DATAS = 'SET_REPORT_COMMON_DATAS' // 设置埋点公共信息
export const SET_REPORT_DATA = 'SET_REPORT_DATA' // 设置埋点公共信息
export const SET_REPORT_VIEW = 'SET_REPORT_VIEW' // 设置已曝光状态
export const CLEAR_REPORT_VIEW = 'clearReportView' // 清除已曝光状态

// 投票 & pk
export const GET_VOTE_LIST = 'GET_VOTE_LIST'
export const SUBMIT_VOTE = 'SUBMIT_VOTE'


// 商品列表收藏状态
export const GET_GOODS_SAVE_STATS = 'GET_GOODS_SAVE_STATS'
export const SET_GOODS_SAVE_STATS = 'SET_GOODS_SAVE_STATS'

// 【专题列表商品尺码状态同步收藏列表】记录之前选中的尺寸，以便收藏时拿到之前选中的尺寸
export const SET_SIZE_SAVE_DATA = 'SET_SIZE_SAVE_DATA'

// 专题锚点跳转后的回调函数
export const SET_ANCHOR_CB = 'SET_ANCHOR_CB'

// mall列表
export const SET_MALL_LIST = 'SET_MALL_LIST'
export const GOT_MALL_STATUS = 'GOT_MALL_STATUS'
