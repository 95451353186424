
const mutations = {
  SET_DATA (state, payload) {
    state.context = payload
  },
  assignState (state, payload) {
    Object.assign(state, payload)
  }
}

export default mutations
