import service from '../../../pages/product_app/store/modules/category/service'
import { transfromAndCutImg } from 'public/src/services/resource/index.js'
import {
  BANNER_DESIGN_WIDTH,
  CLSSIFY_DESIGN_WIDTH,
  SLIDER_LOGIN_DESIGN_WIDTH,
  SLIDER_STACK_DESIGN_WIDTH,
  TITLE_DESIGN_WIDTH,
  TWO_IMAGE_DESIGN_WIDTH,
} from '../../../pages/product_app/store/modules/category/const'

function getImgCount() {
  let num = 15 // 预加载图片数量

  if (typeof window !== 'undefined') {
    const screenMap = { // 屏幕高度对应的图片数量
      667: 12,
      896: 15,
      1024: 18,
      2000: 21,
      9999999: 24,
    }
    Object.keys(screenMap)
      .sort((a, b) => a - b)
      .some(key => {
        if (window.screen.height <= Number(key)) {
          num = screenMap[key]
          return true
        }
      })
  }
  return num
}

export function getPreloadImgs({ twoCategory, isSidebar = false }) {
  if (!Array.isArray(twoCategory)) {
    console.error('preloadImgs: twoCategory is not Array')
    return []
  }

  const MAX_PRELOAD_IMG_NUM = getImgCount() // 预加载图片数量
  const preloadImgList = []

  function setImgs({ imgSrc, designWidth, item }) {
    if (!imgSrc || preloadImgList.length >= MAX_PRELOAD_IMG_NUM) return
    if (!item) {
      throw new Error('setImgs: item is undefined')
    }
    preloadImgList.push({ imgSrc, designWidth, item })
  }

  function childForEach({ data, key, isHidden, designWidth }) {
    if (!Array.isArray(data)) {
      return console.error('childForEach: data is not Array')
    }
    data.some(threeCate => {
      if (preloadImgList.length >= MAX_PRELOAD_IMG_NUM) return true

      if (isHidden && threeCate.hidden) {
        return
      }
      const imgSrc = threeCate[key]
      setImgs({ imgSrc, designWidth, item: threeCate })
    })
  }

  twoCategory.some(cate => {
    if (preloadImgList.length >= MAX_PRELOAD_IMG_NUM) return true

    if (!cate.parentCatNode) {
      if (cate.type == 5 || cate.type == 6) {
        // cate-classify
        childForEach({ data: cate.thumb, key: '_target', designWidth: CLSSIFY_DESIGN_WIDTH })
      }

      if (cate.type == 2) {
        // cate-title
        if (
          !isSidebar &&
          cate.style &&
          cate.style.type == 'IMAGE_TEXT_EXTENSIBLE' &&
          cate.imgSrc
        ) {
          preloadImgList.push({ item: cate, imgSrc: cate.imgSrc, designWidth: TITLE_DESIGN_WIDTH })
        }
        if (cate.show && cate.expandChild) {
          childForEach({
            data: cate.expandChild?.thumb,
            key: '_target',
            designWidth: CLSSIFY_DESIGN_WIDTH,
          })
        }
      }

      if (cate.type == 3) {
        // cate-banner
        childForEach({ data: cate.thumb, key: '_target', designWidth: BANNER_DESIGN_WIDTH })
      }

      if (cate.type == 4) {
        // cate-two-image
        for (let i = 0; i < Math.ceil(cate.thumb.length / 2); i++) {
          const firstItem = service.getTwoImgThumb({ cate, lineIndex: i })
          if (!!firstItem) {
            setImgs({ imgSrc: firstItem._target, designWidth: TWO_IMAGE_DESIGN_WIDTH, item: firstItem })
          }
          const secondItem = service.getTwoImgThumb({
            cate,
            lineIndex: i,
            offset: 1,
          })
          if (!!secondItem) {
            setImgs({ imgSrc: secondItem._target, designWidth: TWO_IMAGE_DESIGN_WIDTH, item: secondItem })
          }
        }
      }

      if (cate.type == 7) {
        // cate-slider
        if (cate.style?.type === 'SECOND_IMAGE_CAROUSEL_LONG') {
          // cate-slider-long
          const carouselWay = cate.style?.carouselWay
          let temp = cate.thumb || []
          if (carouselWay === 'optimization') {
            let totalWeights = temp.reduce(
              (acc, cur) => acc + Number(cur.weights || 0),
              0
            )
            temp.forEach(i => {
              i.weights = i.weights / totalWeights + Math.random()
            })
            temp.sort((a, b) => b.weights - a.weights)
          } else if (carouselWay === 'random') {
            temp.sort(() => Math.random - 0.5)
          }
          childForEach({ data: temp, key: '_target', designWidth: SLIDER_LOGIN_DESIGN_WIDTH })
        }

        if (cate.style.type === 'SECOND_IMAGE_CAROUSEL_SHORT') {
          // cate-slider-stack
          const data = cate.thumb || []
          childForEach({ data, key: '_target', designWidth: SLIDER_STACK_DESIGN_WIDTH })
        }
      }
    }
  })

  return preloadImgList
}

export function preLoadImagesInstall({ images }, cb) {
  const RESOURCE_SDK = gbCommonInfo.RESOURCE_SDK

  if (!RESOURCE_SDK) {
    return console.error('preLoadImagesInstall: RESOURCE_SDK is undefined')
  }

  let loadedNum = 0
  const imgLoadHandle = () => {
    loadedNum++
    if(loadedNum === images.length) {
      cb?.()
    }
  }

  const loadImage = (url, designWidth, item) => {
    const image = new Image()
    const {
      deviceData = '',
      isSupportWeb = '',
      isSupprotCut = false,
      sceneMap = {},
    } = RESOURCE_SDK || {}
    url = transfromAndCutImg({
      deviceData,
      isSupportWebp: Boolean(isSupportWeb),
      isSupprotCut,
      imgUrl: url,
      designWidth: Number(designWidth),
      sceneMap,
    })
    item._preloadUrl = url // 用于预加载图片的url
    image.onload = imgLoadHandle

    image.onerror = imgLoadHandle
    
    image.src = url
  }

  images.forEach(item => {
    loadImage(item.imgSrc, item.designWidth, item.item)
  })
}
