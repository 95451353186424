import { template } from '@shein/common-function'

export default {
  namespaced: true,
  getters: {
    config_v2(state, getters, rootState, rootGetters) {
      const { saleAttr } = rootState.productDetail.coldModules || {}
      const { isDisplayFeedbackTips } = saleAttr || {}
      const showSizeFeedBackEnter = isDisplayFeedbackTips === '1'
      const show_multi_layer = rootGetters['productDetail/common/show_multi_layer']
      const fs_abt = rootGetters['productDetail/common/fs_abt']
      const showSizeAttributeEntrance = fs_abt?.SizeAttributeEntrance?.p?.SizeAttributeEntrance === 'on' 
      const isShowAttrPicInfo = fs_abt?.goodsMainAttPicShow?.p?.goodsMainAttPicShow === 'on'
      const defaultlocalsize = fs_abt?.defaultlocalsize?.p?.defaultlocalsize || 'old'
      const notShowDefaultSizeText = defaultlocalsize === 'local_size_no_default' || defaultlocalsize === 'default_size_no_default'
      const shouldWeakenDefaultSize = fs_abt?.defaultlocalsize?.p?.localstrong === 'on'
      const goodsSizeGroupRelated = fs_abt.goodsSizeGroupRelated?.p?.goodsSizeGroupRelated
      const showSizeGroupPosition = ['mid', 'bottom', 'fold', 'size'].includes(goodsSizeGroupRelated) && goodsSizeGroupRelated
      const showQuickShip = fs_abt.DetailPageQuickShipShow?.param == 'DetailShowQuick'
      const hiddenPartialQuickShip = fs_abt?.goodsdetailTips?.param?.quickshipshow === 'off'
      const isAutoSwitchMall = fs_abt?.goodsdetailmall?.param?.mallautoswitch === 'on'
      const openAutoCheckMall = fs_abt?.mallchange?.param?.mallshow === 'auto'
      const isNoSkuClick = fs_abt?.NoSkuClick?.['param'] === 'type=2'
      const showNewBubbleStyle = fs_abt?.goodsdetailTips?.param?.sizebubble === 'A'
      const showOnlyOneLocalSize = fs_abt?.defaultlocalsize?.param?.onlyonelocalsize === 'on'

      return {
        showSizeGroupPosition,
        isNewSizeLocal: true,
        defaultlocalsize,
        shouldWeakenDefaultSize,
        notShowDefaultSizeText,
        isShowAttrPicInfo,
        showSizeAttributeEntrance,
        showQuickShip,
        hiddenPartialQuickShip,
        showSizeFeedBackEnter,
        isAutoSwitchMall,
        openAutoCheckMall,
        showMultiLayer: show_multi_layer,
        showSizeRecommend: true,
        isNoSkuClick,
        showNewBubbleStyle,
        showOnlyOneLocalSize,
        from: 'detailPage',
      }
    },
    size_constant(state, getters, rootState) {
      const { commonPageInfo } = rootState.productDetail.coldModules
      const { GB_cssRight } = commonPageInfo
      return {
        cssRight: GB_cssRight
      }
    },
    local_size_language(state, getters, rootState, rootGetters) {
      const language_v2 = rootGetters['productDetail/common/language_v2']
      const {    
        SHEIN_KEY_PWA_16223,
        SHEIN_KEY_PWA_23345,
        SHEIN_KEY_PWA_23343,
        SHEIN_KEY_PWA_15254,
      } = language_v2 || {}
      return {
        SHEIN_KEY_PWA_16223,
        SHEIN_KEY_PWA_23345,
        SHEIN_KEY_PWA_23343,
        SHEIN_KEY_PWA_15254,
      }
    },
    data_map_compose(state, getters, rootState) {
      return rootState.productDetail.coldModules.saleAttr?.multiLevelSaleAttribute?.comboStock || {}
    },
    is_base_size(state, getters, rootState) {
      const { saleAttr } = rootState.productDetail.coldModules || {}
      const { isBraRecommendSize } = saleAttr || {}
      return isBraRecommendSize === '1'
    },
    hide_one_size_module(state, getters, rootState, rootGetters) {
      const skc_sale_attr = rootGetters['productDetail/common/skc_sale_attr']
      const { attr_value_list, isDisplayOneSize } = skc_sale_attr?.[0] || {}
      if (skc_sale_attr?.length > 1 || attr_value_list?.length > 1) return false
      const { attr_value_id } = attr_value_list?.[0] || {}
      return isDisplayOneSize === '0' && attr_value_id === '474'
    },
    size_tips_info(state, getters, rootState, rootGetters) {
      const { attrModuleComponentConfig } = rootState.productDetail.coldModules?.productInfo || {}
      const { detailAttrTips } = attrModuleComponentConfig || {}
      const { heelHeightRangeTips, attr_size_tips } = rootState.productDetail.coldModules?.saleAttr || {}
      const { reviewSizeFitState, trueToSizePercent } = rootState.productDetail.hotModules?.saleAttr || {}
      const fs_abt = rootGetters['productDetail/common/fs_abt']
      const language_v2 = rootGetters['productDetail/common/language_v2']
      const sizeTipsBold = fs_abt?.goodsdetailtipstitlebold?.param?.goodsdetailtipstitlebold === 'bold'
      const tipsshow = fs_abt?.goodsdetailTips?.param?.tipsshow === 'default'
      // ccc优先级 > 评论偏大偏小 > pdc
      let tipText = ''
      let tipType = ''
      let tipLogFrom = ''
      if (detailAttrTips) {
        tipText = detailAttrTips || ''
        tipType = 'ccc'
        tipLogFrom = 'ccc'
      }else if(trueToSizePercent){
        tipType = 'tts'
        tipLogFrom = 'reviews'
      }else if(reviewSizeFitState) {
        tipText = reviewSizeFitState == '1' ? language_v2?.SHEIN_KEY_PWA_23773 : language_v2?.SHEIN_KEY_PWA_23774
        tipType = 'fit'
        tipLogFrom = 'reviews'
      }else if(attr_size_tips) {
        tipText = attr_size_tips || ''
        tipType = 'pdc'
        tipLogFrom = 'pdc'
      }
      return {
        sizeTipsBold,
        heelHeightRangeTips,
        tipText,
        tipType,
        isLargeSizeTips: reviewSizeFitState == '1',
        trueToSizePercent,
        tipLogFrom,
        tipsshow,
      }
    },
    mall_info_list(state, getters, rootState) {
      const { mallInfo } = rootState.productDetail.coldModules || {}
      const { mallInfoList } = mallInfo || {}
      return mallInfoList || []
    },
    show_mall_list(state, getters, rootState) {
      return !!(rootState.productDetail.coldModules?.mallInfo?.isShowMall === '1' && getters.mall_info_list?.length)
    },
    size_recommend_info(state, getters, rootState, rootGetters) {
      // TODO: 旧vuex的状态
      const { currentMySize, currentMyBraSize } = rootState.newProductDetail.common
      // --end
      const fs_abt = rootGetters['productDetail/common/fs_abt']
      const { saleAttr } = rootState.productDetail.coldModules || {}
      const { checkSizeConfig, shoesRecRuleId, ruleType, size_guide_url } = saleAttr || {}
      const sizeGuidPlanA = fs_abt?.sizeguideyouhua520?.param?.sizeguideyouhua520 === 'planA'
      const SelfsizerecommendedParam = fs_abt?.Selfsizerecommended?.param || ''
      const isSupportSizeRecommend = SelfsizerecommendedParam !== 'type=C'
      const isSupportExternalRecommend = !!checkSizeConfig && isSupportSizeRecommend
      const isSupportCustomRecommend = shoesRecRuleId > 0 && isSupportSizeRecommend && (SelfsizerecommendedParam === 'type=A' || sizeGuidPlanA)
      const sizeRecommendType = isSupportCustomRecommend ? 1 : (isSupportExternalRecommend ? 2 : 0)
      return {
        sizeRecommendType,
        ruleType,
        sizeGuidPlanA,
        showSizeGuide: !!size_guide_url,
        currentMySize,
        currentMyBraSize,
      }
    },
    show_check_size_and_size_guide(state, getters, rootState, rootGetters) {
      const sizeAttrInfo = rootGetters['productDetail/common/size_attr_info']
      const { sizeRecommendType, showSizeGuide } = getters.size_recommend_info || {}
      const hasOneSize = sizeAttrInfo?.attr_value_list?.some?.(item => item?.attr_value_name_en === 'one-size')
      return sizeRecommendType === 1 && showSizeGuide && !hasOneSize
    },
    delivery_floor_quickship_title(state, getters, rootState, rootGetters) {
      const fs_abt = rootGetters['productDetail/common/fs_abt']
      const language_v2 = rootGetters['productDetail/common/language_v2']
      const Quickshipdetailfloorshow = fs_abt?.quickshipdetailshow?.p?.Quickshipdetailfloorshow || ''
      const floorQuickshipStyle = Quickshipdetailfloorshow === 'quickship' // quickship强化样式
      const floorNDeliveryStyle = +(Quickshipdetailfloorshow) > 0 // n天达
      const floorNDeliveryNumber = +(Quickshipdetailfloorshow) ? +(Quickshipdetailfloorshow) : 0
      const { SHEIN_KEY_PWA_30599, SHEIN_KEY_PWA_30600 } = language_v2 || {}
      let text = ''
      // TODO: quick_ship_time好像不需要做这个判断
      if((floorQuickshipStyle || floorNDeliveryStyle)) { // 命中quickship增强样式 && 要有qs时效
        text = floorQuickshipStyle ? SHEIN_KEY_PWA_30599 : template(floorNDeliveryNumber, SHEIN_KEY_PWA_30600 || '{0} Days Delivery')
      }
      return text
    },
    real_time_size_info(state, getters, rootState, rootGetters) {
      const { sku_list, comboStock } = rootState.productDetail.hotModules?.saleAttr?.multiLevelSaleAttribute || {}
      const { dataMap } = comboStock || {}
      // TODO: 旧状态，数据没返回的时候不用实时的数据
      const allDataReady = rootGetters['newProductDetail/common/allDataReady']
      if (!allDataReady) return {}
      return { realDataMap: dataMap, realSkuList: sku_list }
    },
  },
  actions: {
    open_size_guide_pop({ commit, rootState, getters }, config = {}) {
      const sizeGuideFromSceneMap = {
        'detail_title': 1,
        'detail_pop': 3,
        'cart_title': 2,
        'cart_pop': 4,
        'detail_section': 5,
        'detail_tips': 7,
        'cart_tips': 8
      }
      // TODO: 旧状态
      const { sizeGuideReady, checkSizeAndSizeGuideReady } = rootState.newProductDetail || {}
      if (getters?.show_check_size_and_size_guide) {
        if (!checkSizeAndSizeGuideReady) {
          commit('newProductDetail/updateCheckSizeAndSizeGuideReady', true, { root: true })
        }
        commit('newProductDetail/SizeGuide/updateCheckSizeAndSizeGuide', true, { root: true })
        commit('newProductDetail/SizeGuide/updateSizeActivityFrom', 'sizeGuide', { root: true })
      }else {
        if (!sizeGuideReady) {
          commit('newProductDetail/updateSizeGuideReady', true, { root: true })
        }
        commit('newProductDetail/SizeGuide/updateShowSizeGuideDrawer', true, { root: true })
      }
      daEventCenter.triggerNotice({
        daId: '1-6-1-227',
        extraData: {
          from: sizeGuideFromSceneMap?.[config?.fromScene]
        }
      })
    },
    /** 尺码推荐（鞋子）弹窗 */
    open_size_recommend_pop({ commit, rootState, getters }) {
      // TODO: 旧状态
      const { sizeRecommandReady, checkSizeAndSizeGuideReady } = rootState.newProductDetail || {}
      if (getters?.show_check_size_and_size_guide) {
        if (!checkSizeAndSizeGuideReady) {
          commit('newProductDetail/updateCheckSizeAndSizeGuideReady', true, { root: true })
        }
        commit('newProductDetail/SizeGuide/updateCheckSizeAndSizeGuide', true, { root: true })
        commit('newProductDetail/SizeGuide/updateSizeActivityFrom', 'checkMySize', { root: true })
      } else {
        if (!sizeRecommandReady) {
          commit('newProductDetail/updateSizeRecommandReady', true, { root: true })
        }
        commit('newProductDetail/SizeGuide/updateSizeRecommand', true, { root: true })
      }
    },
    /** 尺码推荐（内衣）弹窗 */
    open_bra_size_recommend_pop({ commit, rootState, getters }) {
      const { braSizeRecommendReady, checkSizeAndSizeGuideReady } = rootState.newProductDetail || {}
      if (getters?.show_check_size_and_size_guide) {
        if (!checkSizeAndSizeGuideReady) {
          commit('newProductDetail/updateCheckSizeAndSizeGuideReady', true, { root: true })
        }
        commit('newProductDetail/SizeGuide/updateCheckSizeAndSizeGuide', true, { root: true })
        commit('newProductDetail/SizeGuide/updateSizeActivityFrom', 'checkMySize', { root: true })
      
      } else {
        if (!braSizeRecommendReady) {
          commit('newProductDetail/updateBraSizeRecommendReady', true, { root: true })
        }
        commit('newProductDetail/SizeGuide/updateBraSizeRecommend', true, { root: true })
      }
    }
  }
}
