import { ref } from 'vue'

export const state = ref({
  loaded: false,
  isPaidUser: false,
  itemConfigAsync: {},
  atomFields: {},
  commonConfig: {
    /**
     * 是否使用新价格
     */
    isNewPrice: true,
    isNewPic2: true,
    isNewPic3: true,
    /**
     * 合规价模式
     */
    isHitComplianceMode: false,
    /**
     * 一行二图瀑布流样式
     */
    isShowWaterFallStyle: false,
  },
  commonAtomConfig: {
    abtInfo: {
      newProductCard: true,
    },
    // 原子请求ccc的参数
    cccParams: {
      displayWindowAbtBranch: '',
    },
  },
  isDebug: false,
})
