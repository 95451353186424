import { getGoodsUrl } from 'public/src/pages/common/utils/index.js'
import { transformImg } from '@shein/common-function'

export default {
  namespaced: true,
  getters: {
    titlesellpoint_abt(state, getters, rootState, rootGetters) {
      const fs_abt = rootGetters['productDetail/common/fs_abt']
      const titlesellpoint = fs_abt?.titlesellpoint?.p?.titlesellpoint
      if (['plana', 'planb'].includes(titlesellpoint)) return titlesellpoint
      return ''
    },
    goods_name(state, getters, rootState) {
      return rootState.productDetail.coldModules?.productInfo?.goods_name || ''
    },
    is_black(state, getters, rootState) {
      const { tempType, tempCode } = rootState.productDetail.coldModules?.productInfo?.cccDetailsTemplate || {}
      return tempType == 2 && ['PERMIUM-A', 'PERMIUM-B', 'MOTF-C'].includes(tempCode)
    },
    goods_name_comment_info(state, getters, rootState) {
      const titlesellpoint_abt = getters.titlesellpoint_abt
      const { comment } = rootState.productDetail.hotModules || {}
      const { comments_overview } = comment || {}
      const { commentNumShow, comment_rank_average = 0 } = comments_overview || {}
      const show_goods_name_comment = !titlesellpoint_abt && commentNumShow != '0' && commentNumShow && comment_rank_average > 0
      return { 
        show_goods_name_comment,
        commentNumShow,
        comment_rank_average,
      }
    },
    show_evolushein_in_title(state, getters, rootState, rootGetters) {
      const fs_abt = rootGetters['productDetail/common/fs_abt']
      const { productLabel } = rootState.productDetail.coldModules || {}
      const { isShowEvoluOnTitle } = productLabel || {}
      return fs_abt?.evolushein?.p?.evolushein === 'show' &&  isShowEvoluOnTitle == 1
    },
    is_brand_product(state, getters, rootState) {
      const { brandDetailInfo } = rootState.productDetail.coldModules || {}
      // const { brandDetailInfo } = productInfo || {}
      return brandDetailInfo?.authenticBrand == 1
    },
    goods_name_labels(state, getters, rootState, rootGetters) {
      const { storeInfo, productLabel } = rootState.productDetail.coldModules || {}
      const { featureSubscript = [], trendInfo = {} } = productLabel || {}
      const language_v2 = rootGetters['productDetail/common/language_v2']
      const fs_abt = rootGetters['productDetail/common/fs_abt']
      const { show_evolushein_in_title, titlesellpoint_abt, is_brand_product } = getters || {}
      const { storeLabels, inFashionLabel, isBrandStore, storeStatus, isFashionLabel, temporaryFashionLabel } = storeInfo || {}
      const preferredSellerLabelName = storeLabels?.find?.(item => item?.labelCode === 'preferredSeller')?.labelName      
      const { feature_bg_color, feature_text_color, feature_name } = featureSubscript?.[0] || {}
      const trendIpImg = trendInfo?.trendIpImg || inFashionLabel?.trendIpImg || null
      const showBrandLabel = ((isBrandStore == 1 && storeStatus == 1) || is_brand_product) && titlesellpoint_abt
      const showEvolusheinLabel = show_evolushein_in_title && !titlesellpoint_abt
      const isTemporaryTrendStoreAbt = fs_abt?.TemporaryTrendStore?.p?.TemporaryTrendStore === 'B'
      // 时尚店铺&临时时尚店铺&趋势商品
      const showFashionLabel = isFashionLabel == 1 || (temporaryFashionLabel == 1 && isTemporaryTrendStoreAbt) || trendInfo?.trendIpImg
      // 优质卖家标签
      const preferredSellerLabel = { key: 'preferredSeller', value: preferredSellerLabelName, show: preferredSellerLabelName }
      // 新品标签
      const newLabel = { key: 'new', value: feature_name, bgColor: feature_bg_color, textColor: feature_text_color,  show: feature_name }
      // 趋势标签
      const trendLabel = { key: 'trend', imgInfo: trendIpImg, show: showFashionLabel }
      // 环保标签
      const evolusheinLabel = { key: 'evolushein', value: 'evoluSHEIN', show: showEvolusheinLabel }
      // 品牌标签
      const brandLabel = { key: 'brand', value: language_v2?.SHEIN_KEY_PWA_24319, show: showBrandLabel }
      return [preferredSellerLabel, newLabel, trendLabel, evolusheinLabel, brandLabel].filter(i => !!i.show).slice(0, 2)
    },
    title_sell_points_list(state, getters, rootState, rootGetters) {
      const { titlesellpoint_abt, show_evolushein_in_title, is_brand_product } = getters || {}
      if (!titlesellpoint_abt) return []
      const fs_abt = rootGetters['productDetail/common/fs_abt']
      const language_v2 = rootGetters['productDetail/common/language_v2']
      const { productInfo } = rootState.productDetail.coldModules || {}
      const { comment, productLabel } = rootState.productDetail.hotModules || {}
      const { shoppingSecurityInfo } = productInfo || {}
      const { goodsImgBenefitInfo, hotNews } = productLabel || {}
      const { isFreeReturn } = goodsImgBenefitInfo || {}
      const { comments_overview } = comment || {}
      const { comment_rank_average = 0 } = comments_overview || {}
      const showShippingGuarantee = fs_abt?.Guarantee?.p?.Guarantee === 'show' && shoppingSecurityInfo?.title
      const positiveReviewsContent = hotNews?.find?.(item => item?.tagId == '3726')?.content || ''
      let labels = []
      // 环保标签
      const evolusheinLabel = { key: 'evolushein', value: 'evoluSHEIN', colorClass: 'golden', show: show_evolushein_in_title, tagType: 'evolushein'  }
      // 免费退换货标签
      const freeReturnLabel = { key: 'freeReturn', value: language_v2?.SHEIN_KEY_PWA_31109, colorClass: 'green', show: isFreeReturn == 1, tagType: 'free return' }
      // 放心购标签
      const guaranteeLabel = { key: 'guarantee', value: shoppingSecurityInfo?.title, colorClass: 'green', show: showShippingGuarantee, tagType: 'shopping security' }
      // 100%正品标签
      const brandTagLabel = { key: 'brandTag', value: language_v2?.SHEIN_KEY_PWA_26778, colorClass: 'blue', show: is_brand_product, tagType: '100% authentic' }
      // 评分标签
      const rankAverageLabel = { key: 'rankAverage', value: comment_rank_average, colorClass: 'golden', show: comment_rank_average, tagType: 'rate' }
      // 好评数标签
      const positiveReviewsLabel = { key: 'positiveReviews', value: positiveReviewsContent, colorClass: 'golden', show: positiveReviewsContent, tagType: 'positive reviews' }

      if (titlesellpoint_abt === 'plana') {
        // titlesellpoint=plana时，展示顺序：evoluSHEIN环保、评分、好评数、免费退换货、放心购、100%正品标签
        labels = [evolusheinLabel, rankAverageLabel, positiveReviewsLabel, freeReturnLabel, guaranteeLabel, brandTagLabel]
      }else if(titlesellpoint_abt === 'planb'){
        // titlesellpoint=planb时，展示顺序：evoluSHEIN环保、免费退换货、放心购、100%正品标签、好评数、评分
        labels = [evolusheinLabel, freeReturnLabel, guaranteeLabel, brandTagLabel, positiveReviewsLabel, rankAverageLabel]
      }
      labels = labels.filter(i => !!i.show)
      if (labels.length < 2) return []
      return labels
    },
    init_share_url(state, getters, rootState, rootGetters) {
      const { host, langPath } = gbCommonInfo || {}
      const cur_main_sale_attribute = rootGetters['productDetail/common/cur_main_sale_attribute']
      const { goods_url_name, goods_id, cat_id } = cur_main_sale_attribute || {}
      const url = host + langPath + getGoodsUrl(goods_url_name, goods_id, cat_id)
      return url
    },
    pinterest_img(state, getters, rootState, rootGetters) {
      // TODO: 分享首图，后续大图迁移收尾时，需修改
      const productImgs = rootGetters['newProductDetail/common/productImgs']
      return transformImg({ img: productImgs?.[0]?.origin_image?.replace('.jpg', '_thumbnail_405x552.jpg') }) 
    }
  }
}
