const getState = (context) => {
  let footerData
  if (context?.footerData) {
    footerData = { ...context.footerData }
  }

  return {
    footerData
  }
}
export default getState
