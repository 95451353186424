import actions from '../product_list_v2/actions'

export default {
  ...actions,
  initState({ getters, commit }) {
    commit('initPage', getters)
    // commit('initFilterBar', getters)
    commit('initProductList', getters)
    commit('initItemConfig', getters)
    commit('initListBottomInfo', getters)
    commit('initNoResult', getters)
  },
  async fetchComponentAsyncData() {
    await Promise.all([
      // dispatch('fetchAtomicInfo'),
    ])
  },
}
