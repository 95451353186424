/**
 *
 * @param {*} obj
 *  @param {Object}
 * @returns is_place_holder 是否仅作占位用
 */
export const getColorsInfo = obj => {
  const {
    relationProducts = [],
    detail = {},
    soldoutColor = [],
    skcSort = {},
    loadConf = {},
    mallCode = '',
    webClient = '',
    hotColorList = {},
    evoluColorList = {},
    mallSoldoutAb = false,
    is_place_holder = false,
    LAZY_IMG = ''
  } = obj
  const data = []
  const goodsMap = {}

  let colorList = []
  if (is_place_holder) {
    colorList = [...relationProducts]
  } else if (loadConf?.filterDetail) {
    // 这种场景不需要排序
    colorList = [...relationProducts, ...soldoutColor]
  } else {
    colorList = [detail, ...relationProducts, ...soldoutColor]
  }

  for (const item of colorList) {
    if (goodsMap[item.goods_id]) continue // 售罄的颜色可能和已有的重复
    goodsMap[item.goods_id] = true
    const goodsTag = getGoodsTag(item.goods_id, item.goods_sn, hotColorList, evoluColorList, webClient)
    const mainSaleAttr = item.mainSaleAttribute?.[0]
    const sort = mainSaleAttr || {
      attr_sort: Infinity,
      value_sort: 0,
      attr_value_id: Infinity
    }

    const { mall_stock, stock, is_on_sale, goods_id } = item || {}
    const mallSoldout = mallSoldoutAb
      ? !mall_stock?.find?.(mall => mall?.stock != 0)
      : mall_stock?.find?.(mall => mall.mall_code == mallCode)?.stock == 0
    const isSoldOut =
      mallSoldout || (goods_id && (!Number(stock) || !Number(is_on_sale)))
    const SoldoutGoodsIds = getSoldoutGoodsIds(soldoutColor, mallCode)

    data.push({
      goods_id: +item.goods_id,
      cat_id: item.cat_id,
      goods_color_image:
        item.color_image || item.goods_color_image || item.goods_color_img,
      goods_thumb: item.goods_thumb || LAZY_IMG, // 空值替代使用默认图片
      original_img: item.original_img,
      goods_img: item.goods_img,
      goods_name: item.goods_name,
      goods_sn: item.goods_sn,
      goods_url_name: item.goods_url_name,
      goods_title: mainSaleAttr?.attr_value || '',
      goods_stock: Number(item.is_on_sale == 1 ? item.stock : 0),
      ...goodsTag,
      isSoldoutColor: is_place_holder
        ? false
        : isSoldOut || !!SoldoutGoodsIds[item.goods_id],
      sort,
      skc_sort: skcSort[item.goods_sn] || 0
    })
  }
  if (!loadConf?.filterDetail) {
    return sortColorData(data)
  } else {
    return data
  }
}

export const getColorsInfoForCacheScreenData = ({
  relationProducts = [],
  LAZY_IMG = ''
}) => {
  let colorList = [...relationProducts]
  return colorList.map(item => ({
    goods_color_image: item.color_img,
    goods_thumb: item.thumbnail || LAZY_IMG,
    goods_id: item.goods_id,
    isSoldoutColor: false,
  }))
}

export const getHotGoodSnList = (colorsInfo, showEco = true) => {
  return colorsInfo.reduce((res, item) => {
    if (item.isHot && (!showEco || !item.isEco)) {
      res.push(item.goods_sn)
    }
    return res
  }, [])
}

const getSoldoutGoodsIds = (soldoutColor, mallCode) => {
  if (!soldoutColor.length) return {}
  let result = {}
  soldoutColor.forEach(item => {
    let mall = item.mall_stock.find(mall => mall.mall_code == mallCode)
    mall &&
      (mall.stock === 0 || Number(item.is_on_sale) === 0) &&
      (result[item.goods_id] = true)
  })
  return result
}

const sortColorData = colorList => {
  // 需求变更，排序规则按 SKC排序值正序>attr_sort正序 > value_sort倒序 > attr_value_id正序 > goods_id正序
  return colorList.sort((a, b) => {
    if (a.isSoldoutColor && !b.isSoldoutColor) return 1
    if (!a.isSoldoutColor && b.isSoldoutColor) return -1
    return (
      a.skc_sort - b.skc_sort ||
      a.sort.attr_sort - b.sort.attr_sort ||
      b.sort.value_sort - a.sort.value_sort ||
      a.sort.attr_value_id - b.sort.attr_value_id ||
      a.goods_id - b.goods_id
    )
  })
}
  
const getGoodsTag = (goods_id, goods_sn, hotColorList, evoluColorList, webClient) => {
  const goodsTag = hotColorList?.[goods_id] || {}
  const evoluRule = !!evoluColorList?.[goods_sn]
  const rwEco = goodsTag?.tag?.indexOf?.(60004511) > -1
  return {
    isHot: goodsTag?.hot_color == 1,
    isEco: webClient == 'shein' ? evoluRule : rwEco,
  }
}
  
// const tagId = (webClient) => {
//   return webClient == 'shein' ? '60008057' : '60004511'
// }
