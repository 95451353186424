// main goods_detail组件
import Main from './main'
// top组件
import top from './top'
// middle组件
import middle from './middle'
// other 一些不属于首屏的组件 如评论弹窗
import other from './other'
// 推荐相关
import recommend from './other/Recommend'

export default {
  Main,
  ...top,
  ...middle,
  ...other,
  ...recommend,
}
