import { abtUserAnalysis } from '@shein-aidc/basis-abt-router'

const COMMENT_TYPE_MAP = {
  1: 'COMMENT',
  2: 'REPORT',
  3: 'OUT_STORE_COMMENT'
}

export default {
  namespaced: true,
  state: {
    firstCommentTranslateInfo: {},
    top4CommentsTranslateInfo: {},
  },
  getters: {
    middleBaseInfo() {
      return {}
    },
    // showPromotionEnter(state, getters, rootState, rootGetters) {
    //   const fsAbt = rootGetters['newProductDetail/fsAbt']
    //   let abtValue = fsAbt?.EstimatedPrice?.p?.E_Price_Cal
    //   let isShowEstimatedStyle = [
    //     'Have_Threshold',
    //     'Non_Threshold',
    //     'A',
    //     'B',
    //     'C',
    //     'D',
    //     'E',
    //     'F',
    //     'G',
    //     'H'
    //   ].includes(abtValue)
    //   if (isShowEstimatedStyle) return false // 命中到手价实验时隐藏旧入口
    //   const promotionInfoDesc = rootGetters['newProductDetail/common/promotionInfoDesc']
    //   if (promotionInfoDesc.length && promotionInfoDesc[0].typeId) return true
    //   const storeCouponListFormated = rootGetters['newProductDetail/common/storeCouponListFormated']
    //   if (storeCouponListFormated.length) return true
    //   const showCouponsUnusedCommend = rootGetters['newProductDetail/showCouponsUnusedCommend']
    //   return showCouponsUnusedCommend
    // },
    designerInfo(state, getters, rootState) {
      return rootState.newProductDetail.coldModules.productInfo?.designerInfo || {}
    },
    // showMiddleDesignerInfo(state, getters, rootState, rootGetters) {
    //   const fsAbt = rootGetters['newProductDetail/fsAbt']
    //   return (
    //     fsAbt?.sheinxreinforce?.p?.sheinxreinforce === 'new1' && getters.designerInfo?.designer_name
    //   )
    // },
    // showRecommendCccGoods2(state, getters, rootState, rootGetters) {
    //   const fsAbt = rootGetters['newProductDetail/fsAbt']
    //   const RankAggregaAbt = fsAbt?.RankAggrega?.p?.RankAggrega
    //   if (RankAggregaAbt === 'A' || RankAggregaAbt === 'B') return false
    //   const cccRecommendPosition =
    //     rootState.newProductDetail.coldModules.ccc?.cccRecommendPosition || []
    //   return cccRecommendPosition.includes('GOODS-2')
    // },
    showRecommendCccGoods3(state, getters, rootState) {
      const cccRecommendPosition =
        rootState.newProductDetail.coldModules.ccc?.cccRecommendPosition || []
      return cccRecommendPosition.includes('GOODS-3')
    },
    signData(state, getters, rootState, rootGetters) {
      const isStoreBusinessBrand = rootGetters['newProductDetail/common/isStoreBusinessBrand']
      const storeData = rootGetters['newProductDetail/common/localStoreInfo'] || {}
      const hasStoreData = Object.keys(storeData).length > 0
      const showStore = rootGetters['newProductDetail/common/showStore']
      const fsAbt = rootGetters['newProductDetail/fsAbt']
      const showStoreAndIsNotBrand = showStore && fsAbt?.BrandCollectionAdjust?.p?.BrandCollectionAdjust !== 'old'
      const brandSeriesDetail = rootGetters['newProductDetail/common/brandSeriesDetail'] || {}
      const hasBrandSeriesDetailData = Object.keys(brandSeriesDetail).length > 0
      // TODO 这里判断逻辑有问题
      if (hasStoreData && (isStoreBusinessBrand || showStoreAndIsNotBrand)) {
        // 显示店铺类型的信息 否则显示品牌或系列信息
        // 如果是品牌集成店铺 这里应该是展示 店铺信息
        return { ...storeData, type: 'store' }
      } else if (hasBrandSeriesDetailData) {
        return brandSeriesDetail
      } else {
        return hasStoreData ? storeData : void 0
      }
    },
    labelList (state, getters, rootState) {
      return rootState.newProductDetail.coldModules.store.labelList || []
    },
    signConfig(state, getters, rootState, rootGetters) {
      const isStoreBusinessBrand = rootGetters['newProductDetail/common/isStoreBusinessBrand']
      const signBoardStyle = rootGetters['newProductDetail/common/signBoardStyle']
      return {
        showStoreBrand: isStoreBusinessBrand,
        position: signBoardStyle.position,
        style: signBoardStyle.style,
        abtest: abtUserAnalysis({ posKeys: 'Storegoodspicture' }).sa
      }
    },
    similarItemsLayout(state, getters, rootState, rootGetters) {
      const unifiedRecommend = rootGetters['newProductDetail/unifiedRecommend']
      const showSimilarItems = rootGetters['newProductDetail/common/showSimilarItems']
      if (!showSimilarItems) {
        return [false, false]
      }
      if (unifiedRecommend.similarLocation === 'under') {
        return [false, true]
      }
      return [true, false]
    },
    // frequentlyBoughtLayout(state, getters, rootState, rootGetters) {
    //   const unifiedRecommend = rootGetters['newProductDetail/unifiedRecommend']
    //   const showFrequentlyBought = rootGetters['newProductDetail/common/showFrequentlyBought']
    //   if (!showFrequentlyBought) {
    //     return [false, false]
    //   }
    //   if (unifiedRecommend?.frequentlyLocation === 'top') {
    //     return [true, false]
    //   }
    //   return [false, true]
    // },
    cccCatSelectionTag(state, getters, rootState) {
      return (
        rootState.newProductDetail.hotModules.ccc?.cccCatSelectionTag ||
        rootState.newProductDetail.coldModules.ccc?.cccCatSelectionTag ||
        {}
      )
    },
    showCatSelectionTagMiddle(state, getters, rootState, rootGetters) {
      const fsAbt = rootGetters['newProductDetail/fsAbt']
      return getters.cccCatSelectionTag?.descKeyList?.[0]?.langKeyEn && fsAbt.Qualityfloor?.p?.Qualityfloor === 'SHOW'
    },
    // 以下为评论相关 ⬇️
    // 是否有评论分数 老链路 commentHide
    hasCommentAverage(state, getters, rootState, rootGetters) {
      const commentOverView = rootGetters['newProductDetail/commentOverView'] || {}
      const average = commentOverView?.comment_rank_average
      if (!average || isNaN(average) || average <= 0) {
        return false
      }
      return true
    },
    // 所有评论数之和 评论 + 报告数（不包括站外评论）
    allCommentSum(state, getters, rootState, rootGetters) {
      const { freeTrailReports = [] } = rootState.newProductDetail.coldModules.comment || {}
      // const commentOverView = rootGetters['newProductDetail/commentOverView']
      const commentInfo = rootGetters['newProductDetail/comments']?.info || {}
      const freeTrailCount = freeTrailReports.length
      const totalComment = Number(commentInfo.commentInfoTotal || 0) + freeTrailCount
      return totalComment
    },
    fsComments(state, getters, rootState, rootGetters) {
      const freeTrailReports =
        rootState.newProductDetail.coldModules.comment?.freeTrailReports || []
      const isMainGoodsLowestPrice = rootGetters['newProductDetail/common/isMainGoodsLowestPrice']
      const commentInfo = rootGetters['newProductDetail/comments']?.info || {}
      const outStoreCommentsInfo = rootState.newProductDetail.hotModules.comment?.outStoreCommentsInfo || {}
      // 非lowest price商品时，过滤掉realtime的同款组拼接评论
      const filterCommentList = commentInfo?.commentInfo?.filter?.(item => item.same_flag != 1 || (isMainGoodsLowestPrice == 1 && item.same_flag == 1)) || []
      const commentsResult = {
        commentInfoTotal: commentInfo.commentInfoTotal,
        commentNumStr: commentInfo.commentNumStr,
        commentFuzzy: commentInfo.commentFuzzy,
        shopRank: commentInfo.shopRank,
        spuTag: commentInfo.spuTag,
        reportTotal: freeTrailReports.length,
        buyBoxTotalNum: (isMainGoodsLowestPrice == 1 && commentInfo.buyBoxTotal) || 0
      }

      let commentType = COMMENT_TYPE_MAP['1']
      let firstComment = {} // 商详页显示评论逻辑 先报告 后评论 后站外评论
      if (freeTrailReports.length) {
        firstComment = freeTrailReports[0]
      } else if (filterCommentList?.length) {
        firstComment = filterCommentList?.[0]
        commentType = COMMENT_TYPE_MAP['2']
      } else if (outStoreCommentsInfo.data?.length) {
        firstComment = outStoreCommentsInfo.data[0]
        commentType = COMMENT_TYPE_MAP['3']
        commentsResult.outStoreCommentsTotal = outStoreCommentsInfo.meta?.count || 0
      }

      // 商详页显示前三条评论 逻辑 先报告 后评论 后站外评论
      let top4Comment = []
      if (freeTrailReports.length) {
        top4Comment.push(...freeTrailReports.slice(0, 4))
      } 
      if (top4Comment.length < 4 && filterCommentList?.length) {
        top4Comment.push(...filterCommentList?.slice(0, 4 - top4Comment.length))
      } 
      if (top4Comment.length < 4 && outStoreCommentsInfo.data?.length) {
        top4Comment.push(...outStoreCommentsInfo.data.slice(0, 4 - top4Comment.length))
      }

      commentsResult.firstComment = firstComment // 第一条评论
      commentsResult.firstCommentType = commentType

      commentsResult.top4Comment = top4Comment

      return commentsResult
    },
    showReviews(state, getters) {
      const allCommentSum = getters.allCommentSum
      return (
        Boolean(allCommentSum) ||
        Boolean(getters.fsComments.buyBoxTotalNum) ||
        Boolean(getters.fsComments.outStoreCommentsTotal)
      )
    },
    // 评论会员尺码数据
    commentMemberSizeConfig(state, getters, rootState) {
      const { memberSizeConfig } = rootState.newProductDetail.coldModules.comment || {}
      return (memberSizeConfig?.memberSize && memberSizeConfig?.memberSize?.result) || []
    },
    // hasFit(state, getters, rootState) {
    //   const { memberSizeConfig } = rootState.newProductDetail.coldModules.comment || {}
    //   return (
    //     (memberSizeConfig.memberFit &&
    //       memberSizeConfig.memberFit[0] &&
    //       memberSizeConfig.memberFit[0].hasFit) ||
    //     0
    //   )
    // },
    commentTranslateConfig(state, getters, rootState) {
      return (
        rootState.newProductDetail.coldModules.comment?.commentTranslateConfig?.languageConfig || {}
      )
    },
    showParallelimport(state, getters, rootState, rootGetters) {
      const fsAbt = rootGetters['newProductDetail/fsAbt']
      const { store } = rootState.newProductDetail.coldModules || {} 
      return fsAbt?.parallelimport?.param?.parallelimport === 'show' && !!store?.sellerLabelInfo?.find?.(item => item?.labelCode === 'international_imported_products')
    },
    // 是否显示付费评论
    showPointProgram(state, getters, rootState, rootGetters) {
      const fsAbt = rootGetters['newProductDetail/fsAbt']
      return fsAbt?.PointProgram?.param === 'type=A'
    },
    // 以上为评论相关 ⬆️
    // 推荐位是否使用单独样式
    recommendWithSoleType(state, getters, rootState, rootGetters) {
      const isOutSite = rootState.newProductDetail.common.isOutSite
      if (isOutSite) return false
      const unifiedRecommend = rootGetters['newProductDetail/unifiedRecommend']
      return unifiedRecommend?.nonRepetitive
    },
    // Matching Style 模块是否展示
    matchingStyleBlockShow(state, getters, rootState, rootGetters) {
      const fsAbt = rootGetters['newProductDetail/fsAbt']
      const relationGoods = rootGetters['newProductDetail/common/relationGoods'] || []
      const matchingStyles = fsAbt.matchingstyles?.p?.matchingstyles !== 'none'
      return relationGoods.length && matchingStyles
    }
  },
  mutations: {
    updateFirstCommentsTranslateInfo(state, payload) {
      state.firstCommentTranslateInfo = payload
    },
    updatetop4CommentsTranslateInfo(state, payload) {
      state.top4CommentsTranslateInfo = payload
    },
  }
}
