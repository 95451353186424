// 红点逻辑
import dayjs from 'dayjs'
import schttp from 'public/src/services/schttp'
import { isLogin } from 'public/src/pages/common/utils/index.js'
import UserInfoManager from 'public/src/services/UserInfoManager/UserInfoManager.js'
import { isSwitchBffApiVersion } from 'public/src/pages/common/bff-new/index.js'
import { checkRedpointExpireTime, setRedpointExpiredTime, getRedpointStatus } from '@shein-aidc/bs-sdk-wish-list-red-point-status'

const useBffApi = isSwitchBffApiVersion(['wishlistGroup_v3']) || {}

class RedPoint {
  constructor() {
    this.redpoint = 0  // 红点状态，0=不显示，1=降价红点，2=低库存红点 3=重新上架（Back in stock） 4=有库存（in stock）
    this.redpointFnQueue = [] // 红点回调队列，红点 API 请求完毕后会遍历执行此队列
    this.redpointReq = null // 红点请求对象
    this.callback = null
  }

  /**
   * 处理结果，执行所有回调
   * @param {*} redpoint 红点对应的值
   * @param {*} popDisabled 是否禁用红点弹窗
   */
  _afterForEachFn(redpoint, popDisabled = false) {
    const result = Number(redpoint)
    this.callback && this.callback(result)
    
    this.redpoint = result
    this.redpointFnQueue.forEach((f) => f?.(result, !!popDisabled))
    this.redpointFnQueue = []
  }

  // 处理红点状态
  async changeRedpoint (fn = () => {}, options = {}) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve) => {
      this.callback = resolve
      this.redpointFnQueue.push(fn)

      if (!isLogin()) {  // 未登录隐藏红点
        return this._afterForEachFn(0)
      }

      // InStock标签云优先级最高
      const InStockStorage = this.getInStockStorageRedpoint()
      if (InStockStorage.redpoint < 2 && options?.WishlistInStock === 'B') {

        this.setInStockStorageRedpoint({
          state: InStockStorage.state,
          redpoint: 1
        })
        this._afterForEachFn(4, true)
      } else {
        // 当前位于频次控制范围内（被动消失后 24 小时内不显示红点）
        const isExpired = this._checkRedpointStoreExpire()
        if (!isExpired) { // 还没有过期直接返回
          return this._afterForEachFn(0)
        }
  
        if (this.redpointReq) { // 等待上一个请求完成执行
          return
        }
        this.redpointReq = true
        let redPointStatus = 0
        try {
          if (!window?.gbCommonInfo.IS_NEW_HEAD) { // 老头部掉小红点接口
            const params = useBffApi?.['wishlistGroup_v3'] ? { 
              url: '/user/get_wishlist_red_dot',
              useBffApi: true,
              headers: {
                'Content-Type': 'multipart/form-data',
              },
            } : { url: '/api/user/wishlist_v2/redpoint/get' }
            let res = {}
            try {
              res = await schttp(params)
            } catch(err) {
              res = await schttp({ url: '/api/user/wishlist_v2/redpoint/get' })
            }
            if (Number(res.code) !== 0 || !res.info.result) {
              this._afterForEachFn(0)
              return
            }
            redPointStatus = res.info.result
          } else { // 新头在业务组件里调了，这里就从localstorage拿
            const memberId = UserInfoManager.get({ key: 'memberId', actionType: 'wishlist/redpoint' })
            redPointStatus = getRedpointStatus(memberId)
          }
          const popDisabled = options?.WishlistInStock === 'C' && InStockStorage.redpoint !== 2

          this._afterForEachFn(redPointStatus, popDisabled)
        } catch(e) {
          this._afterForEachFn(0)
        } finally {
          this.redpointReq = false
        }
      }
    })
  }

  // 隐藏红点
  closeRedpoint () {
    const isExpired = this._checkRedpointStoreExpire()
    if (!isExpired && this.redpoint === 0) { // 没有过期
      return 0
    }
    this.redpoint = 0
    this._setRedpointStoreExpired()
    return this.redpoint
  }

  // 检查本地缓存红点间隔请求过期时间
  _checkRedpointStoreExpire () {
    // const expired = this._getRedpointStoreExpired()
    // return dayjs().valueOf() > expired
    const memberId = UserInfoManager.get({ key: 'memberId', actionType: 'wishlist/redpoint' })
    this.redpoint = getRedpointStatus(memberId)
    return checkRedpointExpireTime(memberId)
  }

  // 获取本地暂存红点间隔请求过期时间
  _getRedpointStoreExpired () {
    try {
      const memberId = UserInfoManager.get({ key: 'memberId', actionType: 'wishlist/redpoint' })
      const storeData = JSON.parse(localStorage.getItem(`wishlistRedpoint-${memberId}`))
      this.redpoint = storeData.redpoint || 0
      return storeData.expired || 0
    } catch (e) {
      return 0
    }
  }

  // 设置本地暂存红点间隔请求过期时间
  _setRedpointStoreExpired () {
    const memberId = UserInfoManager.get({ key: 'memberId', actionType: 'wishlist/redpoint' })
    setRedpointExpiredTime(memberId)
    // localStorage.setItem(`wishlistRedpoint-${memberId}`, JSON.stringify({
    //   expired: dayjs().add(24, 'h').valueOf(),
    //   redpoint: this.redpoint
    // }))
  }

  getInStockStorageRedpointKey() {
    const memberId = UserInfoManager.get({ key: 'memberId', actionType: 'wishlist/instock' })
    return `wishlistInStock-${memberId}`
  }

  // 收藏商品页对InStock标签云有些特殊处理，需要单独记录
  // state: 0初始化WishlistInStock=C时默认选中  1选中 2未选中 redpoint：0未点击未显示气泡 1 未点击但已显示红点，但下次红点会继续展示 2已点击不显示红点
  getInStockStorageRedpoint() {
    const wishlistInStock = JSON.parse(localStorage.getItem(this.getInStockStorageRedpointKey())) || {
      state: 0,
      redpoint: 0
    }
  
    return wishlistInStock
  }

  setInStockStorageRedpoint(data) {
    localStorage.setItem(this.getInStockStorageRedpointKey(), JSON.stringify(data))
  }
}

export default new RedPoint()
