/**
 * @field 销售属性
*/
export default {
  main_sale_attribute(state, getters, rootState) {
    return rootState.productDetail.coldModules?.saleAttr?.mainSaleAttribute || {}
  },
  main_sale_attribute_list(state, getters) {
    return getters.main_sale_attribute?.info || []
  },
  cur_main_sale_attribute(state, getters, rootState, rootGetters) {
    const goods_id = rootGetters['productDetail/common/goods_id']
    return getters.main_sale_attribute_list?.find(item => item.goods_id === goods_id)
  },
  main_sale_attr_desc(state, getters) {
    return getters.main_sale_attribute?.mainSaleAttrDesc?.replace(/\n/g, '<br>') || ''
  },
  sale_attrs_fold_ab(state, getters, rootState) {
    const cache_screen_fs_abt = rootState.productDetail.cache_screen_fs_abt
    const { main_sale_attribute } = getters
    const { saleAttrFold } = main_sale_attribute || {}
    const goodssaleattributefold = cache_screen_fs_abt?.goodssaleattributefold?.param?.goodssaleattributefold || 'off'
    if (saleAttrFold !== '1' || goodssaleattributefold === 'off') return ''
    return goodssaleattributefold
  },
  skc_sale_attr(state, getters, rootState) {
    return rootState.productDetail.coldModules.saleAttr?.multiLevelSaleAttribute?.skc_sale_attr || []
  },
  size_attr_info(state, getters) {
    const { skc_sale_attr } = getters
    return skc_sale_attr?.find(item => item?.isSize === '1') || {}
  },
  default_country_code(state, getters) {
    // 默认本地化国家 => 当前站点的国家
    const { size_attr_info } = getters
    const { attr_value_list } = size_attr_info || {}
    return attr_value_list?.find?.(item => !!item.country_code)?.country_code || ''
  },
  current_country(state, getters, rootState, rootGetters) {
    // TODO: 旧vuex数据流
    // 默认先走本地, 本地没有用接口返回默认的数据

    const currentLocalCountry = rootState.newProductDetail.common?.currentLocalCountry
    if (currentLocalCountry === '' || currentLocalCountry === 'default') return ''
    if (currentLocalCountry) return currentLocalCountry
    const fs_abt = rootGetters['productDetail/common/fs_abt']
    const defaultlocalsize = fs_abt.defaultlocalsize?.p?.defaultlocalsize || 'old'
    if (defaultlocalsize === 'new' || defaultlocalsize === 'default_size_no_default') return ''

    return getters?.default_country_code || ''
  },
  current_unit(state, getters, rootState) {
    const { size_attr_info } = getters || {}
    const { defaultSizeUnit } = size_attr_info || {}
    // TODO: 尺码单位
    return rootState.newProductDetail.common?.currentLocalUnit || (defaultSizeUnit != '1' ? 'cm' : 'inch')
  },
  show_multi_layer(state, getters, rootState, rootGetters) {
    const is_show_Main_Attr = !!rootGetters['productDetail/MainSaleAttr/is_show_Main_Attr']
    const { skc_sale_attr } = getters 
    return (skc_sale_attr?.length + Number(is_show_Main_Attr)) <= 2
  },
  sale_attr_groups(state, getters, rootState) {
    const { saleAttr } = rootState.productDetail.coldModules || {}
    const { saleAttrGroups } = saleAttr || {}
    return saleAttrGroups || {}
  },
}
  
