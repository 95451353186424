import schttp from 'public/src/services/schttp'
import { markPoint } from 'public/src/services/mark/index.js'

/**
 * 获取Ccc首页配置的内容
 */
export const getCccIndexPageContent = async ({ url, ...data } = {}) => {
  if (!url) {
    markPoint('apiPreFetchParamsError', 'public')
    return Promise.resolve({
      code: -1,
      msg: 'miss url'
    })
  }

  const res = await schttp({
    method: 'POST',
    url,
    data
  })
  return res
}

// 动态注册预请求参数
function generateTabPreRequestParams({ triggerParams }) {
  // triggerParams：调用TMG.triggerQuickRequests传入的自定义参数
  const { preRequestTabData } = triggerParams
  const { channelId, channelName } = preRequestTabData || {}

  if (!channelId) {
    return {}
  }

  return {
    [channelId]: {
      url: '/api/home/switchChannel/get',
      switchChannelName: channelName,
      switchChannelId: channelId,
      channelInfo: preRequestTabData,
    }
  }
}


export const configIndexQuickRequests = {
  quickRequests: {
    getCccIndexPageContent: {
      generateParams: generateTabPreRequestParams,
      request: getCccIndexPageContent,
    },
  },
}
