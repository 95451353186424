import Vue from 'vue'
import * as types from './types'

const mutations = {
  SET_DATA (state, payload) {
    state.context = payload
  },
  ADD_EXPOSURE (state, payload) {
    if (!state.exposureTag.includes(payload)) {
      state.exposureTag.push(payload)
    }
  },
  FLUSH_ALL_EXPOSURE (state) {
    state.exposureTag = []
    state.exposureAllowed = true
  },
  ALLOW_EXPOSURE (state, payload) {
    state.exposureAllowed = payload
  },
  // openQuickAdd (state, { id = '', goods_id, index }) {
  //   state.quickAdd.active = true
  //   state.quickAdd.goods_id = goods_id
  //   state.quickAdd.id = id
  //   state.quickAdd.index = index
  // },
  // closeQuickAdd (state) {
  //   state.quickAdd.active = false
  //   state.quickAdd.goods_id = null
  // },
  // commitQuickAddOptions (state, data) {
  //   Object.entries(data).forEach(([key, val]) => {
  //     state.quickAddOptions[key] = val
  //   })
  //   state.quickAddOptions.title = data.title || ''
  // },
  assignState (state, payload) {
    Object.assign(state, payload)
  },
  setGoodsDetail (state, payload) {
    const {
      goods_sn
    } = payload
    state.goodsDetail = Object.assign({
      [goods_sn]: payload
    }, state.goodsDetail)
  },
  setProductDescSku (state, payload) {
    state.productDescSku = payload
  },
  setCommonInfo (state, payload) { // 改变公共信息
    for (const [key, val] of Object.entries(payload)) {
      Vue.set(state.commonInfo, key, val)
    }
  },
  [types.SET_REPORT_COMMON_DATAS] (state, payload = []) { // 改变埋点组件级别公共信息
    state.reportCommonDatas = payload
  },
  [types.SET_REPORT_DATA] (state, payload = {}) { // 改变当前组件级别公共信息
    const { index, data } = payload
    for (const [key, val] of Object.entries(data)) {
      Vue.set(state.reportCommonDatas[index], key, val)
    }
  },
  [types.GET_GOODS_SAVE_STATS] (state, payload) {
    const { saveList = {} } = payload
    state.saveList = Object.assign({}, state.saveList, saveList)
    // console.log(state.saveList)
  },
  [types.SET_REPORT_VIEW] (state, payload) { // 设置曝光状态
    const { key, val, index = 0 } = payload
    if (!state.reportView[index]) {
      state.reportView[index] = {}
    }
    if (!state.reportView[index][key]) {
      state.reportView[index][key] = []
    }
    state.reportView[index][key].push(...val)
  },
  [types.CLEAR_REPORT_VIEW] (state, payload) { // 清除曝光状态
    const { key, val, index, } = payload
    if (!index) {
      state.reportView = []
      return
    }
    if (!key) {
      state.reportView[index] = {}
      return
    }
    if (!val) {
      state.reportView[index][key] = []
      return
    }
    if (Array.isArray(val)) {
      val.forEach((v) => {
        const vIndex = state.reportView[index][key].findIndex((item) => {
          return v === item
        })
        if (vIndex > -1) {
          state.reportView[index][key].splice(vIndex, 1)
        }
      })
    }else {
      const vIndex = state.reportView[index][key].findIndex((item) => {
        return val === item
      })
      if (vIndex > -1) {
        state.reportView[index][key].splice(vIndex, 1)
      }
    }
  },
  [types.GET_VOTE_LIST] (state, payload) {
    const { voteList = [] } = payload
    state.voteList = voteList
  },
  [types.SUBMIT_VOTE] (state, payload) {
    const { activityDataId, voteId, voteData } = payload
    const { voteList } = state
    let list = []
    const i = state.voteList.findIndex(_ => _.actDataId === activityDataId && _.voteId === voteId)
    if (i > -1) {
      voteList.splice(i, 1)
    }
    list = [...voteList, voteData]
    state.voteList = list
  },
  [types.SET_SIZE_SAVE_DATA] (state, payload) {
    const { goods_id, curAttrSize = {} } = payload
    const { sizeInfo = {}, skuInfo = {} } = curAttrSize
    let recordSizeObj = { ...sizeInfo, ...skuInfo }
    recordSizeObj.attr_value = sizeInfo.attr_value_name
    recordSizeObj.attr_value_en = sizeInfo.attr_value_name_en
    state.recordSizeObj = Object.assign({}, state.recordSizeObj, { [goods_id]: recordSizeObj })
  },
  [types.SET_ANCHOR_CB] (state, callback) {
    state.anchorCb = callback
  },
  [types.SET_MALL_LIST] (state, paload) {
    const { actMallList = [] } = state
    state.actMallList = [...actMallList, ...paload]
  },
  [types.GOT_MALL_STATUS] (state, paload) {
    state.gotMall = paload
  },
}

export default mutations
