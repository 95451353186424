import schttp from 'public/src/services/schttp'
import detailPretchFSInfoCache from './cache'

const API_LOCATION = {
  productDetailPrefetch: '/api/productInfo/productDetailPrefetch/get',
}


export const detailFSInfoCache = detailPretchFSInfoCache

// 挂在到windows全局对象上 方便列表组件 或其他场景引用
if(typeof window !== 'undefined'){
  window.__detail_fs_info_cache__ = detailFSInfoCache
}



export const prefetchRequests = (goodsList) => {
  schttp({
    url: `${API_LOCATION.productDetailPrefetch}`,
    params: {
      goodsList: JSON.stringify(goodsList),
    }
  }).then(({ code, data = {} } = {}) => {
    if(+code === 0){
      detailFSInfoCache.setAssemble(data)
    }
  })
}

