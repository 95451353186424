import { fetchVoteList, reportVote, fetchGoodsSaveStats, fetchMallInfo, fetchActivityData } from './api'
import * as types from './types'
import itemSer from 'public/src/services/goodsItemInfo/interfaceSer'

const {
  SET_DATA, ADD_EXPOSURE, FLUSH_ALL_EXPOSURE, ALLOW_EXPOSURE,
  GET_VOTE_LIST, SUBMIT_VOTE, GET_GOODS_SAVE_STATS, SET_MALL_LIST, GOT_MALL_STATUS
} = types

const actions = {
  fetchActData: async ({ commit }, params) => {
    try {
      var { langPath } = gbCommonInfo
    } catch (error) {
      //
    }
    const data = await fetchActivityData(params).then(null, () => {
      location.href = `${langPath}/404.html?from=${encodeURIComponent(location.href)}`
    })
    if (data) {
      const { content: { expand_data: { seo: { title = '' } = {} } = {}, name = '' } = {} } = data
      commit('changeSeo', { pageTitle: title || name }, { root: true })
      return Promise.resolve(commit(SET_DATA, data))
    }
  },
  addExposure: ({ commit }, tag) => commit(ADD_EXPOSURE, tag),
  allowExposure: ({ commit }, isAllowed) => commit(ALLOW_EXPOSURE, isAllowed),
  flushAllExposure: ({ commit }) => commit(FLUSH_ALL_EXPOSURE),
  fetchVoteList: async ({ state, commit }, params) => {
    const { activityDataId } = params
    if (state.voteList && state.voteList[activityDataId]) return
    const voteList = await fetchVoteList(params)
    return commit(GET_VOTE_LIST, { activityDataId, voteList })
  },
  submitVote: async ({ commit }, params) => {
    const { activityDataId, voteId } = params
    const voteData = await reportVote(params)
    commit(SUBMIT_VOTE, { activityDataId, voteId, voteData })
  },
  async fetchGoodsSaveStats ({ commit }, params) {
    const {
      langPath, products = [], isRanking = false,
      showWish = false, isLogin = false,
    } = params

    // 未登录 || 不展示收藏 || 商品列表为空 则不请求
    if (!products.length || !showWish || !isLogin) {
      commit(GET_GOODS_SAVE_STATS, { saveList: {} })
    } else {
      let goodsIds = []
      if(isRanking) {
        products.forEach(item => {
          if(item.relatedColor && item.relatedColor.length) {
            item.relatedColor.forEach(color => {
              goodsIds.push(color.goods_id)
            })
          } else {
            goodsIds.push(item.goods_id)
          }
        })
      } else {
        goodsIds = products.map(item => item.goods_id)
      }
      const saveList = await fetchGoodsSaveStats({ langPath, goodsIds })
      commit(GET_GOODS_SAVE_STATS, { saveList })
      return saveList
    }
  },
  async fetchGoodSave({ commit }, params) {
    const {
      goodsId, isLogin = false,
    } = params
    if (goodsId && isLogin) {
      const goods = [{ goods_id: goodsId }]
      await itemSer.getAtomicInfo({
        goods,
        fields: {
          wish: 1
        },
      })
      const { wishStatus = false } = goods[0] || {}
      // const result = await fetchGoodSave({ langPath, goodsId })
      commit(GET_GOODS_SAVE_STATS, {
        saveList: {
          [goodsId]: wishStatus
        }
      })
    }
  },
  fetchMallList: async ({ state, commit }) => {
    if (state.gotMall) return
    commit(GOT_MALL_STATUS, true)
    const mallList = await fetchMallInfo()
    return commit(SET_MALL_LIST, mallList)
  },
}

export default actions
