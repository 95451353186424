import state from './state'
import actions from './actions'
import mutations from './mutations'
import getters from './getters'

export const storeModuleName = 'prime_credit_record'
export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions
}
