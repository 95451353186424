export default {
  // 添加apollo需要去服务端加一下，不然服务端拿不到
  MAIN_BFF_APOLLO: {
    v1: false, // 0716
    v2: false, // 0730
    v3: false, // 0813
    v4: false, // 0827
    v5: false, // 0903
    v6: false, // 0910
    v6Rec: false, // 0910
  },

  // 页面缓存abt
  cache_screen_fs_abt: {},

  // 静态模型 CDN接口
  // 注: 精准更新
  // 接口文档: https://soapi.sheincorp.cn/application/1406/versions/test01/get_product_get_goods_detail_static_data/doc
  coldModules: {
    priceInfo: {},
    detailPromotionFloor: {},
    productInfo: {
      beauty_ingredients: '',
      goods_id: '',
      goods_name: '',
      selectedMallCode: '',
      models_info: {},
      is_on_sale: '',
      stock: '',
      cccDetailsTemplate: {},
      attrModuleComponentConfig: {},
      productDescriptionInfo: {},
      // brandDetailInfo: {},
      shoppingSecurityInfo: {},
      cat_id: '',
      isRetentionProduct: '',
      currentSkcImgInfo: {
        skcImages: [],
        skuImages: {},
        spuImages: [],
        maskLayer: {},                        // Beauty品类蒙版
      },                                      // 商品主图
      videoInfo: {},                          // 视频数据
      videoSort: '',
      allColorDetailImages: {}
    },
    brandDetailInfo: {
      authenticBrand: '',
    },
    mallInfo: {},
    storeInfo: {
      storeLabels: [],
      inFashionLabel: {},
      isBrandStore: '',
      storeStatus: '',
      isFashionLabel: '',
      temporaryFashionLabel: ''
    },
    productLabel: {
      isShowEvoluOnTitle: '',
      featureSubscript: [],
      trendInfo: {},
      isEvolushein: '0',                    // 是否 Evolushein 商品
    }, // 商品标签
    saleAttr: {
      mainSaleAttribute: {},
      multiLevelSaleAttribute: {},
      findMyShadeH5Url: '',
    },
    commonPageInfo: {},
    commonInfo: {},
  },

  // 动态模型
  // 注: 精准更新
  // 接口文档: https://soapi.sheincorp.cn/application/1406/versions/test01/get_product_get_goods_detail_realtime_data/doc
  hotModules: {
    /**
     * priceInfo:
      estimatedPriceCalculateProcess 到手价计算过程
      npcsEstimatedPriceCalProcess N件到手价计算过程
     */
    priceInfo: null,
    detailPromotionFloor: null,
    storeInfo: {},
    productInfo: {
      cccDetailsTemplate: {},
    },
    saleAttr: {
      arEntrance: false,                // 是否展示ar试装
    },
    store: {},
    productLabel: {
      premiumFlagList: [],                // 主图角标
      hotNews: [],                        // 主图/大图人气氛围
      carHotNews: [],                     // 加车按钮人气氛围
    },
    beltInfo: {
      // // 保留款低价腰带
      // sameLabelBelt: {},
      // 促销腰带相关
      promotionBelt: {},
      sameLabelBelt: {}
    },
    // 商品促销信息	
    promotionInfo: {},
    userInfo: {
      isPaidMember: '0',
    },
    // 真实库存信息（包含大件商品标识和低库存标识）
    inventory: [],
    comment: {},
    shipInfo: {}
  },

  // 动静之外的异步模型
  asyncModules: {
    currentSkcImgInfo: {
      detailImage: []              // 细节图
    },
    getTheLookRelatedIdSeries: [],
    topRanking: {},                //  榜单数据
    newCompanionInfo: {},          //  新搭配数据（outfits ｜ gtl）
  }
}
