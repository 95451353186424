import { trackNewApis } from 'public/src/pages/trackNew/apis/index.js'

const actions = {
  async queryPackageCommentList({ commit, state }, showPackageComment = true) {
    let commentList = []
    const currentPackage = state.currentPackage?.[0]?.reference_number
    const billno = state.trackInfo?.billno
    if (!showPackageComment || billno.indexOf('U') === 0) {
      commit('updateCommentList', commentList)
      return
    }
    try {
      const res = await trackNewApis.queryLogisticComment({
        billno,
        package_number: currentPackage
      })
      if (res.code == 0) {
        commentList = res?.info || []
        commit('updateCommentList', commentList)
      }
    } catch (error) {
      console.log(error)      
    }
  },
}

export default actions
