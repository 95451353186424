export default {
  // 是否批量翻译
  isBatchTranslate(state, getters, rootState, rootGetters) {
    const fsAbt = rootGetters['newProductDetail/fsAbt']
    const isNotBatchTranslate = fsAbt?.Reviewchange?.param?.Batch_translate === 'No'
    return (
      rootState.newProductDetail.coldModules.comment?.commentTranslateConfig?.isBatchTranslate && !isNotBatchTranslate
    )
  }
}
