class recommendExposureList {
  constructor() {
    this.reset()
  }

  list = []
  queen = []
  timer = null

  reset() {
    this.list = []
    this.queen = []
    this.timer = null
  }

  pushQueen(params) {
    this.queen.push(params)
    clearTimeout(this.timer)
    this.timer = setTimeout(() => {
      clearTimeout(this.timer)
      this.updateQueen()
    }, 300)
  }

  updateQueen() {
    this.queen.forEach(item => {
      this.update(item)
    })
    this.queen = []
  }

  update({
    targets = [],
    isDomList = true,
  }) {
    if (!targets?.length) return
    if (isDomList) {
      const exposeGoods = []
      for(let dom in targets) {
        const target = targets[dom]
        const goods_id = target?.getAttribute?.('data-id') || target?.getAttribute?.('data-goods-id')
        if (goods_id) exposeGoods.push(goods_id)
      }
      targets = exposeGoods
    }
    this.list.push(...targets)
    // 最大存储100个商品
    if (this.list.length > 100) {
      this.list = this.list.slice(this.list.length - 100)
    }
  }

  getList(maxNum = 100) {
    const len = this.list.length
    if (len > maxNum) {
      return this.list?.slice?.(len - maxNum, len)
    }
    return this.list
  }

  getListTree(maxNum = 100) {
    // 转成树结构
    const list = this.getList(maxNum)
    const tree = {}
    list.forEach(item => {
      if (!tree[item]) {
        tree[item] = 1
      } else {
        tree[item]++
      }
    })
    return tree
  }
}
  
const recommendExposureListInstance = new recommendExposureList()

if (typeof location !== 'undefined' && location.search.includes('debugger')) {
  window.recommendExposureListInstance = recommendExposureListInstance
}
export default recommendExposureListInstance
  
