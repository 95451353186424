import { getDetailRecPreTreat } from 'public/src/pages/goods_detail/utils/productDetail.js'
import { getUserAbtData } from '@shein-aidc/basis-abt-router'
import schttp from 'public/src/services/schttp'
import recommendExposureListInstance from 'public/src/pages/goods_detail/utils/recommendExposureList.js'
import { getFormatCommonCartProductItem, setCommonCartProductSkuInfo, isSkuCodeCanSale } from '../CommonCart/utils'
import { getNewCardAtomGoodsByGoods } from '../utils'
import itemSer from 'public/src/services/goodsItemInfo/interfaceSer'
import { getSiteMallInfo } from 'public/src/pages/common/getSiteMallInfo'
import { useAtomConfig, EAtomFieldsType } from 'public/src/pages/goods_detail/RecommendRelatives/ProductConfigManager/index'
// import { resMock } from './mock'

/**
 * 最少主商品 + 8推荐
 */
export const MIN_COMBOBUY_RECOMMEND_NUM = 9

/**
 * 获取组合购files
 * @param {*} options 
 * @returns 
 */
export const getComboBuyNewCardAtomParams = async (options = {}) => {
  const {
    subPageKey = 'other_recommend_3pic',
    recPosKey
  } = options
  const abtRules = await getUserAbtData()

  const recReqParams = {}
  if (recPosKey) {
    recReqParams.location = recPosKey
    recReqParams.jsonRuleId = JSON.stringify(abtRules?.[recPosKey]?.param || {})
  }

  const isPic3 = subPageKey === 'other_recommend_3pic'
  const { atomConfigRec } = await useAtomConfig({
    useNewAbt: false,
    type: isPic3 ? EAtomFieldsType.sPic3Sku : EAtomFieldsType.Pic1,
    isRecommend: true
  })

  return {
    ...recReqParams,
    ...atomConfigRec,
    atomScene: {
      pageKey: 'page_goods_detail_rec',
      subPageKey
    }
  }
}

/**
 * 获取推荐数据
 */
export const getComboBuyRecommendList = async (options = {}) => {
  const { goods_id, cat_id, mall_code, itemConfig, price_abt } = options
  const scene_id = 144
  const recPosKey = 'FrequentBoughtTogether'
  // 外部一行三图
  const subPageKey = 'other_recommend_3pic'
  const url = '/api/recommend/facadeAtom/get'

  const atomParams = await getComboBuyNewCardAtomParams({
    itemConfig,
    subPageKey,
    recPosKey
  })
  /**
   * 组合购场景只返回11个商品，不进行分页，page_enable 设置为0， limit设置为11，req_num=120
   * 返回值中添加promotion_group标识商品是优惠券组合商品， 标识为空或者0则表示为折扣商品。
   */

  /**
   * 主商品信息混入推荐数据前
   */
  const mixinProducts = [
    {
      goods_id,
      mall_code
    }
  ]

  const data = {
    scene_id,
    req_num: 120, // 请求数量
    limit: 11,
    pageEnable: 0,
    offset: 0,
    pageNum: 1,
    contextParams: {
      goods_ids: goods_id,
      cate_ids: cat_id,
      mall_code,
      price_abt,
    },
    caller: 'Recommend',
    ...atomParams,
    mixinProducts,
    filterSoldOutConfig: {
      on: true,
      isPatch: true, // 过滤后补齐
      ignoreGoodsIds: [goods_id] // 主商品不过滤
    },
  }

  const res = await schttp({ url, method: 'POST', data })
  // const res = resMock

  if (!res?.info?.products?.length) return []

  // 新商品卡片的场景
  const products = await getDetailRecPreTreat({
    products: res?.info?.products,
    itemConfig
  })

  return products
}

/**
 * 根据外部一行三推荐数据获取弹窗一行一图数据
 */
const ROWS_KEY_MAP = {
  1: ['other_recommend_1pic', 'ONE_IN_A_ROW'],
  3: ['other_recommend_3pic', 'THREE_IN_A_ROW']
}
export const getComboBuyPopupProductListByGoods = async (options = {}) => {
  const { goods, itemConfig, rows = 1 } = options

  const [subPageKey, sceneKey] = ROWS_KEY_MAP[rows] || []
  const atomParams = await getComboBuyNewCardAtomParams({
    itemConfig,
    subPageKey
  })

  console.log('getComboBuyPopupProductListByGoods', goods, atomParams)

  return await getNewCardAtomGoodsByGoods({
    goods,
    fields: atomParams.atomFields,
    cccParams: atomParams.atomCccParams,
    abtInfo: atomParams.abtInfoV3,
    itemConfig,
    subPageKey,
    sceneKey,
    isPaid: atomParams.isPaid,
  })
}

export const getFormatComboBuyProductList = (list = []) => {
  if (!list.length) return []
  const _newList = list.map(getFormatCommonCartProductItem)
  _newList[0]._cartInfo.isMainGoods = true
  // TODO 后接简易价格组件配置
  _newList.forEach((item, index) => {
    if(index !== 0) {
      item.estimatedPriceInfo = {}
    }
  })
  return _newList
}

/**
 * 首次打开时自动勾选
 */
export const setComboBuyProductListAutoSelect = (list = []) => {
  if (!list.length) return []
  list.forEach(d => {
    /**
     * skc售罄忽略
     */
    if (d._cartInfo.isSoldOut) return
    /**
     * 主商品
     */
    if (d._cartInfo.isMainGoods) {
      d._cartInfo.selectStatus = true
      /**
       * 组合商品
       */
    } else if (d._cartInfo.isPromotionGroup) {
      d._cartInfo.selectStatus = true
    }
  })

  return list
}

/**
 * 首次打开弹窗时自动选择sku
 */
export const setComboBuyProductListAutoSkuInfo = (list = [], ignoreMainGoods = false) => {
  if (!list.length) return []
  list.forEach(d => {
    if (ignoreMainGoods && d._cartInfo.isMainGoods) return
    // skc维度售罄
    if (d._cartInfo.isSoldOut) return
    const skuCode = getOnlyStockSkuCode(d)
    if (!skuCode) return

    setCommonCartProductSkuInfo(skuCode, d)
  })

  return list
}

/**
 * 外露模块设置排序 按照推荐接口的顺序，优先排完套组的商品，再排单个商品
 */
export const setComboBuyOutSideProductListSort = (list = []) => {
  list.sort((a, b) => {
    if (a._cartInfo.isMainGoods) return -1
    if (b._cartInfo.isMainGoods) return 1
    if (a._cartInfo.isPromotionGroup && b._cartInfo.isPromotionGroup)
      return 0
    if (a._cartInfo.isPromotionGroup) return -1
    if (b._cartInfo.isPromotionGroup) return 1
    return 0
  })
  return list
}

/**
 * 设置排序 选中的在前，主商品永远在最前
 */
export const setComboBuyProductListSort = (list = []) => {
  list.sort((a, b) => {
    if (a._cartInfo.isMainGoods) return -1
    if (b._cartInfo.isMainGoods) return 1
    if (a._cartInfo._selectStatusOutSide && b._cartInfo._selectStatusOutSide)
      return 0
    if (a._cartInfo._selectStatusOutSide) return -1
    if (b._cartInfo._selectStatusOutSide) return 1
    return 0
  })
  return list
}

/**
 * 批量加车前请求售罄状态
 */
export const setComboBuyProductListSoldStatus = async (options = {}) => {
  const { itemConfig, goods } = options
  if (!goods.length) return
  // 每次点击加车更新库存数据
  const popupProductMap = getFormatComboBuyProductList(
    await getComboBuyPopupProductListByGoods({
      goods,
      itemConfig,
    })
  ).reduce((map, d) => {
    map[d.goods_id] = d
    return map
  }, {})

  const selectSoldOut = []
  const diffSoldOutList = []
  goods.forEach((d, i) => {
    const newItem = popupProductMap[d.goods_id]
    const _isSelect = d._cartInfo.selectStatus
    const _isSkcSoldOut = d._cartInfo.isSoldOut && !d._cartInfo.isOnlySkuSoldOut
    const _hasDiffSoldOut = _isSkcSoldOut !== newItem._cartInfo.isSoldOut

    if (_hasDiffSoldOut) {
      diffSoldOutList.push(newItem)
      if (d._cartInfo.isMainGoods) {
        newItem._cartInfo.isMainGoods = true
      }
      goods.splice(i, 1, newItem)
      _isSelect && selectSoldOut.push(newItem)
      return
    }

    // 选择状态检查sku是否售罄
    if (_isSelect && d._cartInfo.skuCode && !isSkuCodeCanSale(d._cartInfo.skuCode, newItem)) {
      if (d._cartInfo.isMainGoods) {
        newItem._cartInfo.isMainGoods = true
      }
      goods.splice(i, 1, newItem)
      selectSoldOut.push(newItem)
      return
    }
  })

  return { selectSoldOut, diffSoldOutList }
}

/**
 * 选中只有单sku或者只有单sku有库存的商品
 */
const getOnlyStockSkuCode = (item = {}) => {
  const { sale_attr_list = {}, mall_code, _cartInfo = {} } = item
  // 多个次级销售属性不处理 todo
  const { sku_list = [] } = sale_attr_list

  // 无销售属性
  if (_cartInfo.isNotSku) {
    // 无销售属性，从sku_list第一条取数据
    const _skuItem = sku_list[0]
    if (!_skuItem) return
    const _mallStock = _skuItem.mall_stock?.find?.(
      d => d.mall_code === mall_code
    )
    if (_mallStock?.stock > 0) {
      return _skuItem.sku_code
    } else {
      return
    }
  } else {
    const canSaleMallSkuList = []
    for (let i = 0; i < sku_list.length; i++) {
      const d = sku_list[i]
      const _mallStock = d.mall_stock?.find(d => d.mall_code === mall_code)
      if (_mallStock?.stock > 0) {
        canSaleMallSkuList.push(d)
      }
      // 超过两个可售sku，不处理
      if (canSaleMallSkuList.length > 1) return
    }

    // 必须仅剩一个可售
    if (canSaleMallSkuList.length !== 1) return
    return canSaleMallSkuList[0].sku_code
  }
}

/**
 * ---------- 埋点相关 ----------
 */
export const baseExposeData = {
  activity_from: 'togetherbuy',
  reportCode: 'togetherbuy',
  exposeCallback: params => {
    recommendExposureListInstance.pushQueen({
      targets: params?.targets || []
    })
  }
}

export const getExposeBoxData = (options = {}) => {
  const { isPopup, poskey } = options
  const _bindData = {
    module: 'module',
    'review-location': '-',
    location: isPopup ? 'popup' : 'page',
    style: isPopup ? 'popup' : 'detail',
    tab_list: '-',
    'active-from': baseExposeData.activity_from
  }

  if (poskey) {
    _bindData.poskey = poskey
  }

  const bindData = {}
  for (let key in _bindData) {
    bindData[`data-${key}`] = _bindData[key]
  }
  return bindData
}

/**
 * 异步获取商品列表Spu售罄状态
 */
export async function setProductListSpuSoldOut(options = {}) {
  const { goods = [], rows = 3 } = options
  if (!goods.length) return

  const reqRelatedColor = []
  let reqRelatedColorIdMap = {}
  const checkItemList = []

  goods.forEach(item => {
    // 如果当前商品未售罄，则不会spu售罄
    if (!item._cartInfo.isSoldOut) return
    if (!item?.relatedColor?.length) return

    let _hasColor = false
    // 清理数据
    item.relatedColor.forEach(d => {
      // 当前商品不查询
      if (d.goods_id == item.goods_id) return
      _hasColor = true

      reqRelatedColor.push({
        goods_id: d.goods_id,
        cat_id: d.cat_id,
        mall_code: d.mall_code,
        goods_sn: d.goods_sn
      })

      reqRelatedColorIdMap[d.goods_id] = item.goods_id
    })

    if (_hasColor) checkItemList.push(item)
  })
  if (!reqRelatedColor.length) return

  const [subPageKey, sceneKey] = ROWS_KEY_MAP[rows] || []
  const { mallInfoForSite = [] } = await getSiteMallInfo()

  const fields = {
    'stock': 1,
  }

  const opt = {
    fields,
    cccParams: {},
    abtInfo: {},
    pageKey: 'page_goods_detail_rec',
    subPageKey,
    sceneKey,
    goods: reqRelatedColor,
    mallInfo: mallInfoForSite
  }

  await itemSer.getAtomicInfoNew(opt)

  console.log('setProductListSpuSoldOut', reqRelatedColor)

  const reqRelatedColorGoodMap = reqRelatedColor.reduce((map, d) => {
    map[d.goods_id] = d
    return map
  })

  checkItemList.forEach(item => {

    const checkColorList = []
    const checkColorListHasStock = []
    item.relatedColor.forEach(d => {
      const stockGood = reqRelatedColorGoodMap[d.goods_id]
      // 当前商品不查询
      if (!stockGood) return
      checkColorList.push(stockGood)

      const isSoldOut = d.stock == 0 || d.is_on_sale == 0
      if (!isSoldOut) checkColorListHasStock.push(d)
    })

    // // todo test
    // if (item._cartInfo.isMainGoods) item._cartInfo.isSpuSoldOut = true

    if (checkColorList.length !== checkColorListHasStock.length) {
      item._cartInfo.isSpuSoldOut = true

      console.log('setProductListSpuSoldOut checkItem isSpuSoldOut', item)
    } else {
      console.log('setProductListSpuSoldOut checkItem notSpuSoldOut', checkColorList, checkColorListHasStock)
    }
  })

  console.log('setProductListSpuSoldOut checkItem', checkItemList)
}


/**
 * 传入价格字符串,返回价格的整数(numberBeforeDot)、小数部分(numberAfterDot)、前后的币种符号(currencySymbolPrefix、currencySymbolSuffix)
 * @param {*} originString 
 * @returns 
 */
export const handlePriceHtml = (originString) => {
  // 货币符号在前
  const currencySymbolPrefix = originString?.match(/(^\D+)/)?.[0] || ''
  // 货币符号在后
  const currencySymbolSuffix = originString?.match(/(\D+)$/)?.[0] || ''
  // 去除货币符号的价格
  const priceWithoutSymbol =
    originString
      ?.replace(currencySymbolPrefix, '')
      ?.replace(currencySymbolSuffix, '') || ''
  // 价格是否有小数
  const isDecimal = !!priceWithoutSymbol?.includes('.') || !!priceWithoutSymbol?.includes(',')
  // 价格小数部分
  const numberAfterDot = isDecimal ? priceWithoutSymbol.match(/\D\d+$/)?.[0] || '' : ''
  // 价格整数部分
  const numberBeforeDot = priceWithoutSymbol.replace(numberAfterDot, '')

  return { currencySymbolPrefix, numberBeforeDot, numberAfterDot, currencySymbolSuffix }
}
