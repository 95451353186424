import schttp from 'public/src/services/schttp'

// 获取轨迹信息
export function fetchTrackInfo (options) {
  return new Promise((resolve, reject) => {
    schttp({
      url: `/api/orders/base/get_track/get`,
      params: options
    }).then(res => {
      if (res.code == 0 && res.info) {
        resolve(res.info)
      } else {
        resolve([])
      }
    },).catch(()=>{
      reject()
    })
  })
}

const TRACK_API_PREFIX = '/api/orders/track'
export const trackNewApis = {
  // 物流商留评论
  async addLogisticsComment(params) {
    return await schttp({
      url: `${ TRACK_API_PREFIX }/packageComment/add`,
      method: 'POST',
      data: params
    })
  },
  // 物流商评论查询
  async queryLogisticComment(params) {
    return await schttp({
      url: `${ TRACK_API_PREFIX }/commentList/query`,
      method: 'POST',
      data: params
    })
  },
  async fetchOrderForTrack(params) {
    return await schttp({
      method: 'GET',
      url: `${TRACK_API_PREFIX}/orderTrack/get`,
      params: params,
    }).then(res => {
      return res?.orderInfo || {}
    }).catch(() => {
      return {}
    })
  },
  // 客户端查询阿波罗
  async getApolloConfig(key) {
    return await schttp({
      method: 'POST',
      url: '/api/config/apolloConfig/query',
      data: { apolloKeys: key }
    })
  }
}
