import { template } from '@shein/common-function'
import { mapRootGetters } from 'public/src/pages/goods_detail/utils/storeUtils.js'
import salePriceWordSize from 'public/src/pages/goods_detail/utils/salePriceWordSize'

export default {
  namespaced: true,
  state: {
    sheinEurSite: ['pwfr', 'pwuk', 'pwde', 'pwes', 'pwit', 'pwnl', 'mse', 'meur', 'mpl', 'mpt', 'mch', 'mroe', 'meuqs'],
    rwEurSite: ['rwmfr', 'rwmde', 'rwmuk', 'rwmes', 'rwmit'],
  },
  getters: {
    sheinClubInfo(state, getters, rootState, rootGetters) {
      const { user } = rootState.newProductDetail.hotModules
      const isPaidUser = rootGetters['newProductDetail/isPaidUser']
      const { paidDetail = {} } = user || {}
      return {
        isSheinClubUser: isPaidUser,
        prime_level: paidDetail?.product_info?.product_cycle_type || 0,
        total_saving: (paidDetail?.info?.discount_summary_info?.discount_price || 0).toFixed(2),
      }
    },
    getPrice(state, getters, rootState, rootGetters) {
      return rootGetters['newProductDetail/getPrice']
    },
    // 欧洲站点
    isEur(state, getters) {
      const { priceCommon } = getters
      const { IS_RW, SiteUID } = priceCommon
      const { sheinEurSite, rwEurSite } = state
      return IS_RW ? rwEurSite.includes(SiteUID) : sheinEurSite.includes(SiteUID)
    },
    // 欧盟(不含uk)
    isEurUnion(state, getters) {
      const { priceCommon } = getters
      const { IS_RW, SiteUID } = priceCommon
      if(SiteUID === 'pwuk' || SiteUID === 'rwmuk' ) return false
      
      const { sheinEurSite, rwEurSite } = state
      return IS_RW ? rwEurSite.includes(SiteUID) : sheinEurSite.includes(SiteUID)
    },
    // app专享价数据
    appExclusiveData(state, getters, rootState, rootGetters) {
      const { currentMallDetail } = mapRootGetters(rootGetters, 'common', ['currentMallDetail'])
      return currentMallDetail?.appPromotion?.find?.((item) => item.typeId == 8)
    },
    // 更多优惠icon列表
    iconListData(state, getters, rootState, rootGetters) {
      const result = {}
      const { promotionInfo } = mapRootGetters(rootGetters, 'common', ['promotionInfo'])
      const { priceCommon } = getters
      const { IS_RW, is_include_critical_css } = priceCommon
      const list = promotionInfo.filter((item) => item.typeId == 3)
      result.showMoreDiscount = !list?.[0]?.isMoreDiscount
      if (result.showMoreDiscount) {
        result.iconList = list
        result.iconClass = IS_RW
          ? [is_include_critical_css ? 'suiiconfont-critical' : 'suiiconfont', 'sui_icon_time_15px']
          : [is_include_critical_css ? 'iconfont-critical' : 'iconfont', 'icon-limitedoff-new']
      }
      return result
    },
    salePrice(state, getters, rootState, rootGetters) {
      const { sheinClubPromotionInfo, retentionPositionConfig, isShowFollowBelt, price, flashGoods, isFlash, isSpecialFlash, newFlashPromotion, flashType, complianceModeDe, getEstimatedInfo } = mapRootGetters(rootGetters, 'common', [
        'sheinClubPromotionInfo',
        'retentionPositionConfig',
        'isShowFollowBelt',
        'price',
        'flashGoods',
        'isFlash',
        'isSpecialFlash',
        'newFlashPromotion',
        'flashType',
        'complianceModeDe',
        'getEstimatedInfo'
      ])
      const { discountInfo, estimatedInfo } = getters
      const bestDealConfig = rootGetters['newProductDetail/Top/bestDealConfig']
      const showLowest = bestDealConfig.text && !!~bestDealConfig.type.indexOf('Text')
      // 展示保留款腰带、跟价款腰带需要显示折扣样式
      const isShowDiscountRelatedBelt = retentionPositionConfig?.show || isShowFollowBelt
      const isPaidUser = rootGetters['newProductDetail/isPaidUser']
      let salePrice = price?.salePrice?.amountWithSymbol || ''
      let salePriceAmount = price?.salePrice?.amount || 0
      let salePriceUsdAmount = price?.salePrice?.usdAmount || 0
      let hitPromotion = null
      let classNames = []
      if (isPaidUser && sheinClubPromotionInfo?.price?.amountWithSymbol) {
        salePrice = sheinClubPromotionInfo?.price?.amountWithSymbol ?? ''
        salePriceAmount = sheinClubPromotionInfo?.price?.amount ?? ''
        salePriceUsdAmount = sheinClubPromotionInfo?.price?.usdAmount ?? ''
        classNames = ['goods-price__from_paid-vip']
      } else if (isSpecialFlash) {
        salePrice = newFlashPromotion?.price?.amountWithSymbol || ''
        salePriceAmount = newFlashPromotion?.price?.amount || ''
        salePriceUsdAmount = newFlashPromotion?.price?.usdAmount || ''
        hitPromotion =  newFlashPromotion
        classNames = ['goods-price__from_special-flash']
      } else if (isFlash && !getters.isInversion && !getters.isInversionDe) {
        if (flashType === 4 && newFlashPromotion?.price?.amountWithSymbol) {
          // 专区闪购-分人群闪购4，价格从 newFlashPromotion 取
          salePrice = newFlashPromotion?.price?.amountWithSymbol || ''
          salePriceAmount = newFlashPromotion?.price?.amount || ''
          salePriceUsdAmount = newFlashPromotion?.price?.usdAmount || ''
        }
        hitPromotion = flashGoods
        classNames = ['goods-price__from_special-flash']
      } else if (
        ((discountInfo.retailPrice.value !== salePrice || discountInfo.suggestedSalePrice.show) || isShowDiscountRelatedBelt) && 
        !getters.isInversion && !getters.isInversionDe
      ) {
        classNames = ['goods-price__from_has-discount']
      } else if (showLowest) {
        classNames = ['goods-price__from_has-discount']
      } else if (complianceModeDe) {
        let { retailDiscountPercent, suggestedDiscountShow } = discountInfo.deDiscountInfo || {}
        if (retailDiscountPercent?.show || suggestedDiscountShow?.show) classNames = ['goods-price__from_has-discount']
      }
      const fsAbt = rootGetters['newProductDetail/fsAbt']
      const isEstimatedOldStyle = estimatedInfo?.hasEstimated && !(['New1', 'New2'].includes(getEstimatedInfo?.EstimatedNothreShowType) && getEstimatedInfo?.estimatedCompProps?.price?.addOnAmountWithSymbol && getEstimatedInfo?.estimatedCompProps?.price?.needPrice) && !getEstimatedInfo?.estimatedCompProps?.price?.discountPercent 
      const isSmallStyle = fsAbt?.middleast?.p?.middleast === 'new' && getEstimatedInfo?.isSatisfied && isEstimatedOldStyle
      let isSheinappwordsizeNew = fsAbt?.sheinappwordsize?.p?.sheinappwordsize === 'newprice' && !isSmallStyle
      let text = salePriceWordSize({ priceStr: salePrice, gate: isSheinappwordsizeNew })
      return {
        text,
        classNames,
        salePriceAmount,
        salePriceUsdAmount,
        ada: salePrice,
        isSheinappwordsizeNew,
        hitPromotion,
        isSmallStyle
      }
    },
    // 价格模块公共属性
    priceCommon(state, getters, rootState, rootGetters) {
      const { pageInfo, commonInfo } = rootState.newProductDetail.coldModules
      const fsAbt = rootGetters['newProductDetail/fsAbt']
      const { language = {} } = pageInfo || {}
      const { IMG_LINK, IS_RW, is_include_critical_css, lang_rp, PUBLIC_CDN_rp, GB_cssRight_rp, SiteUID, WEB_CLIENT, appLanguage } =
        commonInfo || {}
      const showSheinClub = fsAbt?.sheinclubprice?.p == 'exposure_prime_price'
      const IS_JP_LANGUAGE = appLanguage === 'ja'
      return {
        IS_RW,
        IMG_LINK,
        language,
        is_include_critical_css,
        lang_rp,
        showSheinClub,
        PUBLIC_CDN_rp,
        GB_cssRight_rp,
        SiteUID,
        WEB_CLIENT,
        IS_JP_LANGUAGE,
      }
    },
    // 是否命中品牌id，用于展示凑单（abt参数caterestr + 端配配置品类id,去匹配商品一级品类id，看是否命中
    isMatchCateId(state, getters, rootState) {
      const { promotion } = rootState.newProductDetail.hotModules
      const { isMatchCateId } = promotion?.addOnItemCate?.result || {}
      return isMatchCateId
    },
    // abt转化
    priceAbtConfig(state, getters, rootState, rootGetters) {
      const fsAbt = rootGetters['newProductDetail/fsAbt']
      const { UnderPrice, EstimatedPrice, discountLabel, NewCoupon, EstimatedShowType, middleast } = fsAbt || {}
      // const { Member, Flash } = PriceOneLine?.p || {}
      return {
        priceVipOneLine: false,
        priceFlashOneLine: true, // 下掉PriceOneLine，默认推A
        // priceVipOneLine: Member === 'A',
        // priceFlashOneLine: Flash === 'A',
        UnderPriceShow: UnderPrice?.param?.UnderPriceShow === 'A',
        E_Price_Cal: EstimatedPrice?.p?.E_Price_Cal,
        discountLabel: discountLabel?.param?.discountLabel === 'Label',
        NewCoupon: NewCoupon?.p?.NewCoupon,
        EstimatedShowType: EstimatedShowType?.p?.EstimatedShowType,
        EstimatedPrice,
        isMiddleastNew: middleast?.p?.middleast === 'new'
      }
    },
    // s3会员信息
    exclusiveInfo(state, getters, rootState, rootGetters) {
      const result = {}
      let promotionInfo = rootGetters['newProductDetail/common/promotionInfo']
      // sku 不参与s3会员
      if (!promotionInfo.find(item => item.typeId == '12')) return { showLocation: false }
      const { exclusivePromotionPrice } = mapRootGetters(rootGetters, '', ['exclusivePromotionPrice'])
      const { priceAbtConfig } = getters
      // abt判断显示在price下方还是price右侧
      result.showLocation = !!exclusivePromotionPrice ? 'line' : ''
      if (result.showLocation && !rootGetters['newProductDetail/common/complianceMode']) {
        const { pageInfo, commonInfo } = rootState.newProductDetail.coldModules
        const { language } = pageInfo || {}
        const { IS_RW } = commonInfo || {}
        ;(result.amountWithSymbol = exclusivePromotionPrice?.amountWithSymbol),
        (result.exclusiveText = language?.SHEIN_KEY_PWA_24353),
        (result.exclusiveDescText = IS_RW ? language?.SHEIN_KEY_PWA_18682 : language?.SHEIN_KEY_PWA_17305),
        (result.exclusivePromotionPrice = exclusivePromotionPrice)
      }
      return result
    },
    // 价格模块闪购相关信息聚合
    priceFlashInfo(state, getters, rootState, rootGetters) {
      const { isSpecialFlash, isFlash, flashGoods, flashType, currentMallDetail, newFlashPromotion } = mapRootGetters(rootGetters, 'common', [
        'isFlash',
        'flashGoods',
        'flashType',
        'currentMallDetail',
        'newFlashPromotion',
        'isSpecialFlash'
      ])
      const { productInfo } = rootState.newProductDetail.coldModules
      const { detail } = productInfo || {}
      const { priceAbtConfig } = getters
      const hasNewFlashPromotion = newFlashPromotion?.price?.amountWithSymbol
      // 普通闪购样式
      const flashSaleDiff = [1, 5].includes(Number(flashType)) || ([4].includes(Number(flashType)) && hasNewFlashPromotion)
      // 显示闪购信息
      const showFlashSale = !!(flashGoods || detail?.unit_discount)
      const showFlashSaleDiff = isFlash && !priceAbtConfig.priceFlashOneLine
      let detailPrice = currentMallDetail
      if (([4].includes(Number(flashType)) && hasNewFlashPromotion)) {
        // 可省金额、比例取促销接口
        detailPrice = {
          ...currentMallDetail,
          discountPrice: newFlashPromotion?.discountValue || {},
          unit_discount: newFlashPromotion?.discountPercent || '',
        }
      }
      return {
        isSpecialFlash,
        flashSaleDiff,
        showFlashSale,
        isFlash,
        showFlashSaleDiff,
        detailPrice,
        flashType,
        flashGoods,
        isBrandFlash: flashType == 6
      }
    },
    // 是否使用付费会员接口返回的 estimated_anti文案， 到手价(不露出专属折扣金额)场景
    isEstimatedAntiContent(state, getters, rootState, rootGetters) {
      const isPaidUser = rootGetters['newProductDetail/isPaidUser']
      const { getEstimatedInfo, sheinClubPromotionInfo } = mapRootGetters(rootGetters, 'common', [
        'getEstimatedInfo',
        'sheinClubPromotionInfo',
      ])
      let estimatedPrice = getEstimatedInfo?.value?.amount || ''
      // 付费会员到手价
      let clubEstimatedPrice = getEstimatedInfo?.sheinClubAfterCouponsInfo?.after_coupon_price?.amount || ''
      // 付费会员价
      let clubPrice = sheinClubPromotionInfo?.price?.amount || ''
      // 
      /**
       * 1. 未开通付费会员，有原价到手价、付费会员到手价，且原价到手价<付费会员到手价
       * 2. 未开通付费会员，有原价到手价，付费会员没有到手价，且原价到手价<付费会员原价
       * 满足以上两者条件之一，则取sheinClubContent中estimated_anti字段文案
       * */
      return !isPaidUser && (!!(estimatedPrice && clubEstimatedPrice && estimatedPrice < clubEstimatedPrice) || !!(estimatedPrice && !clubEstimatedPrice && estimatedPrice < clubPrice))
    },
    detailSheinClubContent(state, getters, rootState, rootGetters) {
      const sheinClubContent = rootGetters['newProductDetail/common/sheinClubContent']
      const isEstimatedAntiContent = getters.isEstimatedAntiContent
      const contentKey = isEstimatedAntiContent ? 'estimated_anti' : 'estimated_normal'
      let detailSheinClubContent = sheinClubContent?.goodsDetail?.[contentKey]?.fieldContent || ''
      const fsAbt = rootGetters['newProductDetail/fsAbt']
      let vipEstimatedClass = fsAbt?.SHEINclubshowtype?.p?.SHEINclubshowtype
      let vipEstimatedNew = ['New1', 'New2'].includes(vipEstimatedClass)
      if(detailSheinClubContent) {
        detailSheinClubContent = detailSheinClubContent.replace(/<\/&(.+?)&\/>/g, (match, p1) => {
          if(vipEstimatedNew) {
            return `<span class="vip-estimated-price__heighlight">${p1}</span>`
          }else {
            return p1
          }
        })
      }
      return detailSheinClubContent
    },
    // 到手价相关逻辑
    estimatedInfo(state, getters, rootState, rootGetters) {
      const fsAbt = rootGetters['newProductDetail/fsAbt']
      const isPaidUser = rootGetters['newProductDetail/isPaidUser']
      const cacheScreenData = rootGetters['newProductDetail/cacheScreenData']
      const { getEstimatedInfo, sheinClubPromotionInfo, sheinClubShippingSiteConfig, price } = mapRootGetters(rootGetters, 'common', [
        'getEstimatedInfo',
        'sheinClubPromotionInfo',
        'sheinClubShippingSiteConfig',
        'price',
      ])
      const realTimeReady = rootState.newProductDetail.common.realTimeReady
      const { priceAbtConfig, priceCommon } = getters
      const isFreeReceiveUser = rootGetters['newProductDetail/isFreeReceiveUser']
      const { E_Price_Cal } = priceAbtConfig
      const { showSheinClub, language } = priceCommon || {}
      const isEstimatedMatch = E_Price_Cal === 'Non_Threshold' || E_Price_Cal === 'Have_Threshold'
      const estimatedCompProps = getEstimatedInfo?.estimatedCompProps

      // todo caterestr
      const hasEstimated = !!estimatedCompProps?.config
      const showVipEstimatedTag = (isPaidUser || (!isPaidUser && showSheinClub)) && sheinClubPromotionInfo && (realTimeReady || cacheScreenData)
      // const showVipEstimatedTag = (isEstimatedMatch || priceAbtConfig.priceVipOneLine) && (isPaidUser || (!isPaidUser && showSheinClub)) && sheinClubPromotionInfo && (realTimeReady || cacheScreenData)
      // const amountWithSymbol = price?.vipDiscountPrice?.amountWithSymbol
      const amountWithSymbol = price?.prev_discount_value?.amountWithSymbol

      // 付费会员入口样式
      let vipEstimatedClass = fsAbt?.SHEINclubshowtype?.p?.SHEINclubshowtype
      let amountWithSymbolText = amountWithSymbol
      
      let vipEstimatedText = ''
      
      // 付费会员接口返回文案
      if(getters.detailSheinClubContent) {
        vipEstimatedText = getters.detailSheinClubContent
      } else if(amountWithSymbol) {
        
        let vipEstimatedNew = ['New1', 'New2'].includes(vipEstimatedClass)
        if(vipEstimatedNew){
          amountWithSymbolText = `<span class="vip-estimated-price__heighlight">${amountWithSymbol}</span>`
        }
        if (isPaidUser) {
          vipEstimatedText = template(amountWithSymbolText, language.SHEIN_KEY_PWA_25110)
        } else {
          if (!sheinClubShippingSiteConfig.IS_NEW_SHEIN_CLUB_SHIPPING) {
            if(isFreeReceiveUser && fsAbt?.clubtype?.p?.clubtype === 'new') {
              vipEstimatedText = template(amountWithSymbolText, language.SHEIN_KEY_PWA_27454)
            } else{
              vipEstimatedText = template(amountWithSymbolText, language.SHEIN_KEY_PWA_25109)
            }
          } else {
            const { VALUE_DETAIL = {} } = sheinClubShippingSiteConfig
            if (VALUE_DETAIL.single_coupon_value) { // 当有付费会员运费券时
              let text = vipEstimatedNew ? `<span class="vip-estimated-price__heighlight">${VALUE_DETAIL.single_coupon_value_with_symbol}</span>` : VALUE_DETAIL.single_coupon_value_with_symbol
              vipEstimatedText = template(text, language.SHEIN_KEY_PWA_29173)
            } else if (VALUE_DETAIL.multiple_points) { // 当没有会员运费券 但有奖励积分权益时
              let text = vipEstimatedNew ? `<span class="vip-estimated-price__heighlight">${VALUE_DETAIL.multiple_points}</span>` : VALUE_DETAIL.multiple_points
              vipEstimatedText = template(text, language.SHEIN_KEY_PWA_29174)
            } else { // 当既没有运费券又没有积分权益时
              vipEstimatedText = language.SHEIN_KEY_PWA_29175
            }
          }
        }
      }
      return {
        getEstimatedInfo,
        isEstimatedMatch,
        estimatedCompProps,
        hasEstimated,
        showVipEstimatedTag,
        vipEstimatedText,
        vipEstimatedClass,
      }
    },
    isShowCountdown(state, getters) {
      const { estimatedCompProps } = getters.estimatedInfo
      const { isShowCountdown = false } = estimatedCompProps || {}
      return isShowCountdown
    },
    estimatedPriceEndTime(state, getters) {
      const { estimatedCompProps = {} } = getters.estimatedInfo
      const { estimatedPriceEndTime } = estimatedCompProps || {}
      return estimatedPriceEndTime
    },
    // 价格倒挂
    isInversion(state, getters, rootState, rootGetters) {
      if (rootGetters['newProductDetail/common/complianceModeDe']) return false
      if (!rootGetters['newProductDetail/common/complianceMode']) return false
      const newFlashPromotion = rootGetters['newProductDetail/common/newFlashPromotion']
      if (newFlashPromotion?.price?.amountWithSymbol) {
        if (newFlashPromotion.is_inversion || !newFlashPromotion.suggested_sale_price?.amountWithSymbol || parseFloat(newFlashPromotion.suggested_sale_price?.amount) === 0) return true
        return false
      }
      const price = rootGetters['newProductDetail/common/price']
      if (price.isInversion || !price.suggestedSalePrice?.amountWithSymbol || parseFloat(price.suggestedSalePrice?.amount) === 0) return true
      return false
    },
    // 划线价信息
    discountInfo(state, getters, rootState, rootGetters) {
      const isPaidUser = rootGetters['newProductDetail/isPaidUser']
      const { price, flashGoods, sheinClubPromotionInfo, complianceMode, newFlashPromotion, isSpecialFlash, isFlash, flashType, complianceTipsMode } = mapRootGetters(rootGetters, 'common', [
        'price',
        'flashGoods',
        'sheinClubPromotionInfo',
        'complianceMode',
        'complianceTipsMode',
        'newFlashPromotion',
        'isSpecialFlash',
        'isFlash',
        // 'showFrom',
        'flashType'
      ])
      const { estimatedInfo, priceFlashInfo, priceCommon, priceAbtConfig, isInversion } = getters
      const { discountLabel, priceFlashOneLine } = priceAbtConfig
      const { IS_RW, language } = priceCommon || {}
      const { flashSaleDiff, showFlashSale } = priceFlashInfo
      const hasNewFlashPromotion = (isSpecialFlash || flashType == 4) && newFlashPromotion?.discountPercent
      const { hasEstimated } = estimatedInfo
      const newFlashPromotionPriceWithSymbol = newFlashPromotion?.price?.amountWithSymbol || ''


      /* 合规划线价 start */ 
      let suggestedValue = newFlashPromotion?.suggested_sale_price?.amountWithSymbol || price?.suggestedSalePrice?.amountWithSymbol || ''
      let suggestedShow = complianceMode && !!suggestedValue && !isInversion
      let tip = complianceTipsMode && suggestedShow ? language.SHEIN_KEY_PWA_30762 : ''
      let suggestedSalePrice = {
        show: suggestedShow,
        value: suggestedValue,
        tip
      }
      /* 合规划线价 end */ 


      /* 划线价 start */ 
      const showDiscount =
        (isPaidUser && sheinClubPromotionInfo?.price?.amountWithSymbol) ||
        (isFlash && priceFlashOneLine) ||
        (isSpecialFlash || (flashType == 4 && newFlashPromotionPriceWithSymbol)
          ? price?.retailPrice?.amount != newFlashPromotion?.price?.amount
          : price?.salePrice?.amount != price?.retailPrice?.amount)
      
      // 有到手价情况下只有未满足门槛 New1, New2 样式展示到手价
      let showDiscountWithEstimated = true
      if (hasEstimated) {
        const { getEstimatedInfo } = mapRootGetters(rootGetters, 'common', [
          'getEstimatedInfo',
        ])
        let { EstimatedNothreShowType, isSatisfied, thresholdValue, needPrice } = getEstimatedInfo
        showDiscountWithEstimated = 
          (['New1', 'New2'].includes(EstimatedNothreShowType) &&
          !isSatisfied &&
          !!thresholdValue?.amountWithSymbol &&
          needPrice) ? true : false
      }

      let retailPrice = {
        show: !!showDiscount && showDiscountWithEstimated && !complianceMode,
        value: price?.retailPrice?.amountWithSymbol,
      }
      /* 划线价 end */ 


      /* 折扣率 start */ 
      const isNewDisCountStyle = !(flashGoods && IS_RW) && !!discountLabel 
      const extendClass = {
        'price-new__discount': isNewDisCountStyle,
        'special-flash': isSpecialFlash, // 特殊闪购 2 3
        flash: !isSpecialFlash && flashGoods, // 常规 + 专区 1 4 5
      }
      // 常规闪购不需要展示折扣比例
      let showPrecent =
        isSpecialFlash || 
        (isFlash ? priceFlashOneLine : price?.unit_discount > 0 && !flashSaleDiff && !!showFlashSale)
      if (complianceMode && hasEstimated) showPrecent = false
      // if (showFrom) showPrecent = false
      let percent = hasNewFlashPromotion ? newFlashPromotion?.discountPercent : price?.unit_discount
      if (isPaidUser && sheinClubPromotionInfo?.price?.amountWithSymbol) {
        percent = price.discountValue || price?.unit_discount
        showPrecent = price.discountValue > 0
      }
      let discountPercent = {
        show: showDiscountWithEstimated && showPrecent && percent && !isInversion,
        value: percent,
        extendClass,
      }
      /* 折扣率 end */ 


      /* 德国合规专用 start */
      let retailPriceAmount = parseFloat(price?.retailPrice?.amount || 0)
      let suggestedSalePriceAmount = parseFloat(newFlashPromotion?.suggested_sale_price?.amount || price?.suggestedSalePrice?.amount || 0)
      let mainPriceAmount = parseFloat((isPaidUser && sheinClubPromotionInfo) ? sheinClubPromotionInfo?.price?.amount : newFlashPromotion?.price?.amount || price?.salePrice?.amount) || 0

      let retailShow = false
      let suggestedShowDe = false
      let suggestedDiscountShow = false
      if (suggestedSalePriceAmount > 0) {
        if (suggestedSalePriceAmount > mainPriceAmount) {
          suggestedShowDe = true
          suggestedDiscountShow = percent > 0 // 30 天最低价没倒挂展示折扣率，倒挂不展示折扣率
          if (retailPriceAmount != mainPriceAmount && retailPriceAmount > suggestedSalePriceAmount) {
            retailShow = true
            suggestedShowDe = true
          }
        } else { // 30 天最低价倒挂
          if (retailPriceAmount != mainPriceAmount && retailPriceAmount != suggestedSalePriceAmount) {
            retailShow = true // 30 天最低价倒挂仍然可以展示原价
            suggestedShowDe = true
          }
        }
      }

      let suggestedDiscountPercentValue = newFlashPromotion?.discountPercent || price?.unit_discount
      let retailDiscountPercentValue = newFlashPromotion?.retail_discount_percent || price?.retailDiscountPercent
      if (isPaidUser && sheinClubPromotionInfo) {
        suggestedDiscountPercentValue = price.discountValue
        retailDiscountPercentValue = price?.retailDiscountValue
      }
      let deDiscountInfo = {
        retailPrice: {
          show: retailShow,
          value: price?.retailPrice?.amountWithSymbol
        },
        retailDiscountPercent: {
          show: retailShow,
          value: retailDiscountPercentValue,
          extendClass
        },
        retailDiscountPrice: {
          show: retailShow,
          value: newFlashPromotion?.retail_discount_price?.amountWithSymbol || price?.retailDiscountPrice?.amountWithSymbol,
        },
        suggestedSalePrice: {
          show: suggestedShowDe,
          value: suggestedValue
        },
        suggestedDiscountPercent: {
          show: suggestedDiscountShow,
          value: suggestedDiscountPercentValue,
          extendClass
        },
        isInversionDe: !retailShow && !suggestedShowDe
      }
      /* 德国合规专用 end */


      return {
        retailPrice,
        discountPercent,
        suggestedSalePrice,
        deDiscountInfo
      }
    },
    isInversionDe(state, getters, rootState, rootGetters) {
      if (rootGetters['newProductDetail/common/complianceModeDe']) return getters.discountInfo.deDiscountInfo?.isInversionDe
      return false
    },
    currMallInfo(state, getters, rootState, rootGetters) {
      const { currentMallDetail, mallCode } = mapRootGetters(rootGetters, 'common', ['currentMallDetail', 'mallCode'])
      return rootState.newProductDetail?.common?.skuInfo?.mall?.[mallCode] || currentMallDetail
    },
    activityTotalDiscount(state, getters, rootState, rootGetters){
      const { currMallInfo, discountInfo } = getters
      const isDeMode = rootGetters['newProductDetail/common/complianceModeDe']
      const retailDiscount = discountInfo.deDiscountInfo?.retailDiscountPrice.value
      return (isDeMode ? retailDiscount : currMallInfo?.discountPrice?.amountWithSymbol) ?? ''
    },
    complianceDiscountInfo(state, getters, rootState, rootGetters){
      const { currMallInfo, discountInfo } = getters
      const { sheinClubPromotionInfo, complianceModeDe } = mapRootGetters(rootGetters, 'common', ['sheinClubPromotionInfo', 'complianceModeDe'])
      const isPaidUser = rootGetters['newProductDetail/isPaidUser']
      let priceObj = {}
      const isByRetail = complianceModeDe && discountInfo?.deDiscountInfo?.suggestedSalePrice?.show && discountInfo?.deDiscountInfo?.retailDiscountPrice?.show
      if (isPaidUser && sheinClubPromotionInfo?.price?.amountWithSymbol) {
        if(isByRetail){
          // 打开德国合规价开关时，如果30天最低价 和原价同时展示，那么腰带折扣率/折扣金额 取主价格和原价的折扣率 或者折扣金额展示
          priceObj.price = currMallInfo?.retailVipDiscountPrice?.amountWithSymbol ?? ''
          priceObj.percent = String(currMallInfo?.retailDiscountValue)
        } else{
          // 如果只有30天最低价，那么腰带的折扣率取主价格和30天最低价的折扣率 或者折扣金额展示
          priceObj.price = currMallInfo?.vipDiscountPrice?.amountWithSymbol ?? ''
          priceObj.percent = String(currMallInfo?.discountValue)
        }
      } else {
        if(isByRetail){
          priceObj.price = discountInfo?.deDiscountInfo?.retailDiscountPrice?.value ?? ''
          priceObj.percent = String(discountInfo?.deDiscountInfo?.retailDiscountPercent?.value)
        } else{
          priceObj.price = currMallInfo?.discountPrice?.amountWithSymbol ?? ''
          priceObj.percent = String(currMallInfo?.unit_discount)
        }
      }
      return priceObj
    },
    complianceCurrentPrice(state, getters, rootState, rootGetters){ // （售价，非标签价）
      const { price } = mapRootGetters(rootGetters, 'common', ['price'])
      return price?.salePrice
    },
    // 会员相关
    vipInfo(state, getters, rootState, rootGetters) {
      const result = {}
      const isPaidUser = rootGetters['newProductDetail/isPaidUser']
      const { sheinClubPromotionInfo } = mapRootGetters(rootGetters, 'common', ['sheinClubPromotionInfo'])
      const { paidVipData, estimatedInfo, priceCommon } = getters
      // const { priceVipOneLine } = priceAbtConfig || {}
      const { isEstimatedMatch } = estimatedInfo || {}
      const { showSheinClub, language, GB_cssRight_rp } = priceCommon || {}
      result.showPaidVipTag = !isEstimatedMatch && !!isPaidUser
      if (result.showPaidVipTag) {
        const discountValue = sheinClubPromotionInfo?.discountValue
        result.paidTagText =
          discountValue && language?.SHEIN_KEY_PWA_24210
            ? template(GB_cssRight_rp ? `%${discountValue}-` : `-${discountValue}%`, language?.SHEIN_KEY_PWA_24210)
            : ''
      }
      result.showVipUnpaidTag = !isEstimatedMatch && !isPaidUser && !!showSheinClub
      if (result.showVipUnpaidTag) {
        result.unpaidProps = {
          price: sheinClubPromotionInfo?.price?.amountWithSymbol,
          text: language?.SHEIN_KEY_PWA_24209,
        }
      }
      // result.showPaidVip = !!(sheinClubPromotionInfo && !priceVipOneLine) && !isEstimatedMatch
      result.paidVipData = paidVipData
      result.sheinClubPromotionInfo = sheinClubPromotionInfo
      return result
    },

    paidVipData(state, getters, rootState, rootGetters) {
      const { promotionInfo } = mapRootGetters(rootGetters, 'common', ['promotionInfo'])
      return promotionInfo?.find?.((_) => _.typeId == 29)
    },

    // CommentOverview相关
    commentOverview(state, getters, rootState, rootGetters) {
      const { freeTrailReports } = rootState.newProductDetail.coldModules?.comment || {}
      const commentOverView = rootGetters['newProductDetail/commentOverView'] || {}
      const cacheScreenData = rootGetters['newProductDetail/cacheScreenData']
      const freeTrailCount = freeTrailReports?.length
      const totalComment = Number(commentOverView?.comment_num || 0) + freeTrailCount
      if (cacheScreenData) {
        const { starComment = {} } = cacheScreenData
        const { comment_num = 0 } = starComment
        return {
          totalComment: comment_num,
          commentOverView: starComment
        }
      }
      
      return {
        totalComment,
        commentOverView,
      }
    },

    // MemberTag 相关

    //afterPay 相关
    afterPayInfoList(state, getters, rootState, rootGetters) {
      const afterpayInfo = rootGetters['newProductDetail/afterpayInfo'] || {}
      const fontShowList = afterpayInfo?.front_show_list || []
      const afterPayInfoList = []
      fontShowList.forEach((item) => {
        const showDescBatch = item.show_desc_batch || []
        if ((showDescBatch.length && showDescBatch.every((i) => i.desc || item.show_desc)) || item.show_desc) afterPayInfoList.push(item)
      })
      return (afterpayInfo.is_display && afterPayInfoList) || []
    },
    taxIncludedConfig(state, getters, rootState, rootGetters) {
      const { cccAllTaxesIncluded } = rootState.newProductDetail.coldModules?.ccc || {}
      const fsAbt = rootGetters['newProductDetail/fsAbt']
      const { Taxshowtype } = fsAbt
      const type = Taxshowtype?.p?.Taxshowtype == 'A' ? 'popover' : 'text'
      const { language } = rootState.newProductDetail.coldModules?.pageInfo
      if (rootGetters['newProductDetail/common/complianceModeDe'] && getters.discountInfo.deDiscountInfo.suggestedSalePrice.show) {
        return {
          type: 'popover',
          show: true,
          content: type == 'popover' && cccAllTaxesIncluded ? (cccAllTaxesIncluded || 'TVA incluse.') : '',
          subContent: template(getters.discountInfo.deDiscountInfo.suggestedSalePrice.value, language.SHEIN_KEY_PWA_31308),
          placemen: 'bottom-start'
        }
      }
      return {
        type,
        show: !!['A', 'B'].includes(Taxshowtype?.param?.Taxshowtype) && cccAllTaxesIncluded,
        content: cccAllTaxesIncluded || 'TVA incluse.',
        subContent: '',
        placemen: 'top'
      }
    },
    showPriceTextTips(state, getters, rootState, rootGetters) {
      const { taxIncludedConfig } = getters
      const bestDealConfig = rootGetters['newProductDetail/Top/bestDealConfig']
      const { text, type } = bestDealConfig
      const showTaxIncludedText = taxIncludedConfig?.show && taxIncludedConfig?.type == 'text'
      const showLowest = text && !!~type.indexOf('Text')
      return showLowest || showTaxIncludedText
    },
    deShowOriginalDetails(state, getters, rootState, rootGetters){ 
      const { getEstimatedInfo, complianceModeDe } = mapRootGetters(rootGetters, 'common', [
        'getEstimatedInfo',
        'complianceModeDe'
      ])
      const retailPrice = getEstimatedInfo?.retailPrice?.amount || 0
      const salePrice = getters.complianceCurrentPrice?.amount || 0
      return complianceModeDe && Number(retailPrice) > Number(salePrice)
    },
    // original_discount_price = 原价 - pmsc的特价
    originalDiscountPriceObj(state, getters, rootState, rootGetters) {
      const { currMallInfo, deShowOriginalDetails } = getters
      const { getEstimatedInfo, price } = mapRootGetters(rootGetters, 'common', [
        'price',
        'getEstimatedInfo',
      ])
      let { isSatisfiedBuyMultiple, isSatisfiedBuyMultipleFromPromotion, isSatisfiedBuyMultipleFromCoupon, mulSaleInfo } = getEstimatedInfo || {}
      if (isSatisfiedBuyMultiple) {
        if (isSatisfiedBuyMultipleFromPromotion) {
          return mulSaleInfo?.origin_discount_price
        }
        if (isSatisfiedBuyMultipleFromCoupon) {
          return price.double_price_info?.double_original_discount_price
        }
      }
      return (deShowOriginalDetails ? currMallInfo?.retailOriginalDiscountPrice : currMallInfo?.original_discount_price) ?? {}
    },
    // 促销活动原始信息
    promotionDiscountInfo(state, getters, rootState, rootGetters) {
      const { currentMallDetail, mallCode, getEstimatedInfo, price } = mapRootGetters(rootGetters, 'common', ['currentMallDetail', 'mallCode', 'getEstimatedInfo', 'price'])
      let { isSatisfiedBuyMultiple, isSatisfiedBuyMultipleFromCoupon } = getEstimatedInfo || {}
      let skuInfoMallInfo = rootState.newProductDetail?.common?.skuInfo?.mall?.[mallCode]
      let currPromotionDiscountInfo = skuInfoMallInfo?.promotion_discount_info || currentMallDetail?.promotion_discount_info
      if (isSatisfiedBuyMultiple) {
        if (isSatisfiedBuyMultipleFromCoupon) {
          return currPromotionDiscountInfo.map(item => ({ ...item, discount_price: price.double_price_info?.double_discount_amount }))
        }
      }
      return currPromotionDiscountInfo ?? {}
    },
    // prev_discount_value = 售价 - 付费会员价
    prevDiscountValueObj(state, getters, rootState, rootGetters) {
      const { currMallInfo } = getters

      const { getEstimatedInfo, price } = mapRootGetters(rootGetters, 'common', [
        'price',
        'getEstimatedInfo',
      ])
      let { isSatisfiedBuyMultiple, isSatisfiedBuyMultipleFromPromotion, isSatisfiedBuyMultipleFromCoupon, mulSaleInfo } = getEstimatedInfo 

      if (isSatisfiedBuyMultiple) {
        if (isSatisfiedBuyMultipleFromPromotion) {
          return mulSaleInfo.total_vip_discount_price
        }
        if (isSatisfiedBuyMultipleFromCoupon) {
          return price.double_price_info?.double_display_price?.double_discount_amount
        }
      }

      return currMallInfo?.prev_discount_value ?? {}
    },
  }
}
