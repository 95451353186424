import schttp from 'public/src/services/schttp'

const actions = {
  async fetchOcpLandingClientData({ commit }, params) {
    const result = await schttp({
      url: '/api/pay/ocp/clientInfo/query',
      params
    })
    if (result?.code == 0 && result?.info) {
      commit('assignState', {
        ...result.info,
        isSSR: false,
        language: result.info.languageText,
        oneClickGoodsWithOrderData: result.info.oneClickGoodsWithOrderData || {},
        timeData: result.info.timeData || {},
        countdownStartTime: result.info.countdownStartTime || 0,
        isOrderFillOneClickPay: result.info.isOrderFillOneClickPay || false,
        activityFrom: result.info.activityFrom || '',
        maximumTimes: result.info.maximumTimes || 5,
        billno: result.info.billno || '',
      langPath: result.info.langPath || ''
      })
    }
    return result
  }
}
export default actions
