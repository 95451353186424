export default {
  namespaced: true,
  state: {
    detail_pic_fold: true
  },
  getters: {
    detail_pic_box(state, getters, rootState, rootGetters) {
      const language_v2 = rootGetters['productDetail/common/language_v2']
      const imgs = rootState.productDetail.asyncModules.currentSkcImgInfo.detailImage
      const { cccDetailsTemplate } = rootState.productDetail.hotModules.productInfo || {}
      const content = cccDetailsTemplate?.content?.[0] || {}

      if (content.detailImg == 1 || content.detailImg == 2 || !imgs?.length) {
        return {
          show: false
        }
      }

      const text = {
        title: language_v2.SHEIN_KEY_PWA_15687,
        more: language_v2.SHEIN_KEY_PWA_15698,
        less: language_v2.SHEIN_KEY_PWA_16454
      }

      return {
        show: true,
        text,
        imgs
      }

    }
  },
  mutations: {
    update_detail_pic_fold(state, payload) {
      state.detail_pic_fold = payload
    }
  }
}
