const mutations = {
  assignState (state, payload) {
    Object.assign(state, payload)
  },
  resetLocals(state) {
    if (state?.locals) return
    if (typeof window === 'undefined') return
    let { WEB_CLIENT, IS_RW, host, lang, langPath, currency, appLanguage, GB_cssRight, SiteUID, PUBLIC_CDN } = gbCommonInfo
    state.locals = { WEB_CLIENT, IS_RW, host, lang, langPath, currency, appLanguage, GB_cssRight, SiteUID, PUBLIC_CDN }
  },
  updateTrackInfo(state, payload) {
    state.trackInfo = payload
  },
  updateOrderTrackInfo(state, payload) {
    state.orderTrackInfo = payload
  },
  updateCurrentPackage(state, payload) {
    state.currentPackage = payload
  },
  setCustomsInterception(state, payload) {
    state.customsInterception = payload
  },
  updateSaleTypeInfo(state, payload) {
    state.saleTypeInfo = payload
  },
  updateCommentList(state, payload) {
    state.packageCommentList = payload
  },
  updateApollo(state, payload) {
    state.apolloInfo = { 
      ...state.apolloInfo,
      ...payload
    }
  },
  resetSsrState(state) {
    state.isSSR = false

    if (typeof window === 'undefined') return
    delete gbCommonInfo.contextForSSR
    window.trackSsrData = null
  }
}

export default mutations
