export default {
  namespaced: true,
  state: {
    videoShowFlag: true
  },
  getters: {
    // 视频地址
    vimeo_source_data(_, __, rootState) {
      return rootState.productDetail.coldModules.productInfo.videoInfo?.videoUrl || ''
    },

    has_vimeo_url(_, __, rootState) {
      return Boolean(rootState.productDetail.coldModules.productInfo.videoInfo?.videoUrl)
    },

    // 视频地址
    video_source_data(_, __, rootState) {
      const { link, linkExpirationTimeTs } = rootState.productDetail.coldModules.productInfo.videoInfo || {}
      if (!link || !linkExpirationTimeTs) return {}

      // 在客户端判断视频是否过期
      const _isValid = Number(linkExpirationTimeTs) >= Number(new Date())
      if (!_isValid) return {}
      return { link }
    },

    has_video_url(_, getters) {
      const videoSourceData = getters.video_source_data
      return Boolean(videoSourceData?.link)
    },

    show_native_player(state, getters, __, rootGetters) {
      const fsAbt = rootGetters['productDetail/common/fs_abt']
      const isNativePlayer = fsAbt?.nativevideo?.p?.nativevideo === 'new'
      return getters.has_video_url && isNativePlayer && state.videoShowFlag
    },

    show_vimeo_player(state, getters) {
      return getters.has_vimeo_url && !getters.show_native_player && state.videoShowFlag
    },
  },
  mutations: {
    update_video_show_flag(state, payload = false) {
      state.videoShowFlag = payload
    }
  }
}
