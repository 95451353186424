export default {
  updateBrandAndSeries(state, payload) {
    state.coldModules.brandAndSeries = payload
  },
  updateProductInfo(state, payload) {
    state.coldModules.productInfo = payload
  },
  updateOtherOptions(state, payload) {
    state.coldModules.otherOptions = payload
  },
  updateGetTheLook(state, payload) {
    state.coldModules.getTheLook = payload
  },
  updatePromotion(state, payload) {
    state.coldModules.promotion = payload
  },
  updateRecommend(state, payload) {
    state.coldModules.recommend = payload
  },
  updateCategory(state, payload) {
    state.coldModules.category = payload
  },
  updatePageInfoLanguageField(state, payload) {
    state.coldModules.pageInfo.language = Object.freeze(payload)
  },
  updatePageInfo(state, payload) {
    const {
      fsAbt = {},
      language,
      metaInfo,
      showAgeLimit,
      isSsr,
      googleSEO,
      closeEstimatedAndAbPrice,
      productItemsLanguage,
      complianceMode,
      complianceTipsMode,
      complianceModeDe,
      isHitComplianceMode,
      DETAIL_SECOND_CONFIG
    } = payload || {}
    state.coldModules.pageInfo.isSsr = isSsr
    state.coldModules.pageInfo.showAgeLimit = showAgeLimit
    state.coldModules.pageInfo.metaInfo = metaInfo
    state.coldModules.pageInfo.googleSEO = googleSEO
    state.coldModules.pageInfo.complianceMode = complianceMode
    state.coldModules.pageInfo.complianceTipsMode = complianceTipsMode
    state.coldModules.pageInfo.complianceModeDe = complianceModeDe
    state.coldModules.pageInfo.isHitComplianceMode = isHitComplianceMode
    state.coldModules.pageInfo.closeEstimatedAndAbPrice = closeEstimatedAndAbPrice
    state.coldModules.pageInfo.productItemsLanguage = Object.freeze(productItemsLanguage)
    state.coldModules.pageInfo.DETAIL_SECOND_CONFIG = Object.freeze(DETAIL_SECOND_CONFIG)
    // closeEstimatedAndAbPrice 特殊需求 特殊场景需要关闭所有到手价功能 在fsAbt里面中去重置
    if (closeEstimatedAndAbPrice) {
      fsAbt.EstimatedPrice = {}
    }

    state.coldModules.pageInfo.fsAbt = Object.freeze(fsAbt)

    state.coldModules.pageInfo.cacheScreenFsAbt = state.coldModules.pageInfo.cacheScreenFsAbt || {
      goodssaleattributefold: fsAbt?.goodssaleattributefold || {}
    }

    // language 特殊处理只需要更新一次
    if (language) { // ssr 时携带language
      this.commit('newProductDetail/updatePageInfoLanguageField', language)

      // if (typeof window !== 'undefined') {
      //   setTimeout(() => {
      //     sessionStorage.setItem('language_productDetail', JSON.stringify(language))
      //   })
      // }
    }
    // if (!language && !state.coldModules.pageInfo.language && typeof window !== 'undefined') {
    //   const language = JSON.parse(sessionStorage.getItem('language_productDetail'))
    //   state.coldModules.pageInfo.language = Object.freeze(language)
    // }
  },
  updateShipping(state, payload) {
    state.coldModules.shipping = payload
  },
  updateComment(state, payload) {
    state.coldModules.comment = payload
  },
  updateCoupon(state, payload) {
    state.coldModules.coupon = payload
  },
  updateOthers(state, payload) {
    state.coldModules.others = payload
  },
  updateSocial(state, payload) {
    state.coldModules.social = payload
  },
  updateStore(state, payload) {
    state.coldModules.store = payload
  },
  updateUser(state, payload) {
    state.coldModules.user = payload
  },
  updatePay(state, payload) {
    state.coldModules.pay = payload
  },
  updateTsp(state, payload) {
    state.coldModules.tsp = payload
  },
  updateCcc(state, payload) {
    state.coldModules.ccc = payload
  },
}
