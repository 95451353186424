export default {
  namespaced: true,
  state: {},
  getters: {
    context(state, getters, rootState) {
      return rootState.newProductDetail.hotModules.ccc?.cccBannerContext || {}
    },
    content(state, getters) {
      return getters.context.content || []
    },
    renderStatus(state, getters, rootState, rootGetters) {
      const fsAbt = rootGetters['newProductDetail/fsAbt']
      const isThirdPartyStore = rootGetters['newProductDetail/common/isThirdPartyStore']
      const { content } = getters
      const { detailbanner = {} } = fsAbt
      const isNotShowByAbt = detailbanner?.p?.detailbanner === 'notshow'
      // 实验优先判断 配置了notshow直接不展示
      if(isNotShowByAbt) return false
      if (!(content && content[0] && content[0].props.items.length)) return false
      const { isThirdPartyMerchantBanner = false } = content[0].props?.metaData || {}
      if (isThirdPartyStore && !isThirdPartyMerchantBanner) return false
      return true
    },
    cateLinks(state, getters) {
      return getters.context.cateLinks
    },
    sceneData(state, getters) {
      const { pageType = '', id = '' } = getters.context || {}
      return {
        sceneName: pageType, // 埋点需要
        pageType: pageType, // 埋点需要
        pageFrom: pageType, // 标识应用在哪个页面，你也可以直接取ccc后台返的pageType
        id,
      }
    },
    cccAppContext(state, getters, rootState) {
      const { commonInfo } = rootState.newProductDetail.coldModules || {}
      const { WEB_CLIENT  } = commonInfo || {}
      return {
        WEB_CLIENT
      }
    },
  }
}
  
