export const detailTPMHandle = ({ detail, mallCode, currency }) => {
  requestIdleCallback(() => {
    window.TPM?.publish?.('viewcontent', {
      id: detail.goods_id,
      sku: detail.goods_sn,
      spu: detail.productRelationID,
      category: detail.cat_id,
      price: detail.mall?.[mallCode]?.salePrice?.amount,
      usd_price: detail.mall?.[mallCode]?.salePrice?.usdAmount,
      discount: detail.mall?.[mallCode]?.unit_discount,
      img: detail.goods_img,
      url_name: detail.goods_url_name,
      currency: currency
    })
  })
}
