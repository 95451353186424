const getters = {
  content (state) {
    return state.context && state.context.content
  },
  topTabList (state) {
    return state.context && state.context.topTabList
  },
  sceneId (state) {
    return state.context && state.context.sceneId
  },
  channelId (state) {
    return state.context && state.context.channelId
  },
  aodAbtData (state) {
    return state.context && state.context.aodAbtData || {}
  },
  tagId (state) {
    return state.context && state.context.tagId
  },
  langPath (state) {
    return state.context && state.context.langPath
  },
  freeShipping (state) {
    return state.freeShppingCon
  },
  topTabAbtData (state) {
    return state.context && state.context.topTabAbtData || {}
  },
  hasPolicyBanner (state) {
    return state.context && state.context.hasPolicyBanner || false
  },
  policyBannerPlaceholder (state) {
    return state.context && state.context.policyBannerPlaceholder || false
  },
  immersiveBannerContent (state) {
    return state.context && state.context.immersiveBannerContent || {}
  }
}
export default getters
