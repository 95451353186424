class Cache {
  constructor() {
    this.capacity = 0           // 存储最大容量数
    this.cacheGoodsId = new Set()  // 存储 Key
  }

  getCacheKey(goodsId, type) {
    return `goods_detail_${type}_cache_${goodsId}`
  }

  valdateType(type) {
    return ['cold', 'hot'].includes(type)
  }

  /**
   * @name set
   * @param {*} id goodsId
   * @param {*} type  cold | hot
   * @param {*} value
   * @returns
   */
  set(id, type = 'cold', value) {
    if (!this.valdateType(type)) {
      return
    }
    const cacheGoodsId = this.cacheGoodsId
    const cacheId = this.getCacheKey(id, type)

    // 更新新鲜度
    this.updateFresh(cacheId)

    // 超出容量，删除最长时间未使用数据
    if (this.capacity > 0 && cacheGoodsId.size > this.capacity) {
      const delKey = cacheGoodsId.keys().next().value
      cacheGoodsId.delete(delKey)
      sessionStorage.removeItem(delKey)
    }

    try {
      sessionStorage.setItem(cacheId, JSON.stringify(value))
    } catch (error) {
      // 当前报错为存入值超过浏览器存储大小限制
      if (error.name === 'QuotaExceededError') {
        // 动态改变最大长度
        this.capacity = cacheGoodsId.size - 1
        // 再次插入
        if (this.capacity > 0) {
          this.set(id, type = 'cold', value)
        }
      }
    }
  }

  /**
   *
   * @param {*} id goodsId
   * @param {*} type cold | hot
   * @returns
   */
  get(id, type = 'cold') {
    if (!this.valdateType(type)) {
      return
    }
    const cacheId = this.getCacheKey(id, type)
    const value = sessionStorage.getItem(cacheId)
    return JSON.parse(value) || null
  }

  /**
   *  更新新鲜度（利用Set特性，将使用的数据放到最后面）
   * @param {*} id goodsId
   */
  updateFresh(cacheId) {
    if (this.cacheGoodsId.has(cacheId)) {
      this.cacheGoodsId.delete(cacheId)
    }
    this.cacheGoodsId.add(cacheId)
  }

  /**
   * 删除所有缓存
   */
  clearAll() {
    this.cacheGoodsId.forEach((key) => {
      sessionStorage.removeItem(key)
    })
    this.cacheGoodsId.clear()
    this.capacity = 0
  }
}

const goodsDetailCache = new Cache()
export default goodsDetailCache
