// tab类型
export const TAB_TYPE = {
  FOLLOWING: '0',
  BOUGHT: '1',
  VISIT: '2',
}

export const TAB_TYPE_TEXT = {
  '0': 'follow',
  '1': 'bought',
  '2': 'visit',
  'recommend': 'recommend'
}

// 多语言key
export const languageKeys = [
  'SHEIN_KEY_PWA_24072',        // Following
  'SHEIN_KEY_PWA_30097',        // Browsed
  'SHEIN_KEY_PWA_30098',        // Purchased
  'SHEIN_KEY_PWA_24068',        // Follow  
  'SHEIN_KEY_PWA_24078',        // View
  'SHEIN_KEY_PWA_24319',
  'SHEIN_KEY_PWA_20758',
  'SHEIN_KEY_PWA_24078',
  'SHEIN_KEY_PWA_24060',
  'SHEIN_KEY_PWA_15332',
  'SHEIN_KEY_PWA_24069',
  'SHEIN_KEY_PWA_30165',
  'SHEIN_KEY_PWA_30164',
  'SHEIN_KEY_PWA_30163',
  'SHEIN_KEY_PWA_24074',
  'SHEIN_KEY_PWA_30585',
  'SHEIN_KEY_PWA_24080',
  'SHEIN_KEY_PWA_24078',
  'SHEIN_KEY_PWA_30129',
  'SHEIN_KEY_PWA_24077',
  'SHEIN_KEY_PWA_24075',
  'SHEIN_KEY_PWA_24076',
  'SHEIN_KEY_PWA_31215',
  'SHEIN_KEY_PWA_24079',
]


