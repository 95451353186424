export default {
  namespaced: true,
  state: {},
  getters: {
    show_local_rating(state, getters, rootState, rootGetters){
      const showLocalReviews = rootGetters['productDetail/Reviews/show_local_reviews']
      const hasLocalComments = getters.has_local_comments
      return showLocalReviews && hasLocalComments
    },
    local_rating(state, getters, rootState, rootGetters) {
      const commentOverViewLocal = rootGetters['productDetail/Reviews/comment_overview_local']
      return commentOverViewLocal && commentOverViewLocal.comment_rank_average
    },
    has_local_comments(state, getters, rootState, rootGetters) {
      const commentOverViewLocal = rootGetters['productDetail/Reviews/comment_overview_local']
      const { comment_num } = commentOverViewLocal || {}
      return !(!comment_num || comment_num == '0')
    }
  }
}
