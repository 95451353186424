
export default {
  namespaced: true,
  state: {
    user_manual_ready: false,
    user_manual_status: false
  },
  mutations: {
    update_user_manual_status(state, payload) {
      state.user_manual_status = payload
    },
    update_user_manual_ready(state, payload) {
      state.user_manual_ready = payload
    }
  },
  actions: {
    open_user_manual_pop({ commit, state }, callback = () => {}) {
      const { user_manual_ready } = state
      if (!user_manual_ready) {
        commit('update_user_manual_ready', true)
      }
      commit('update_user_manual_status', true)

      callback()
    },
    close_user_manual_pop({ commit }) {
      commit('update_user_manual_status', false)
    }
  },
  getters: {
    instruction_url: (state, getters, rootState) => {
      return rootState.productDetail.hotModules.productInfo.instructionUrl
    },
    show_user_manual: (state, getters, rootState, rootGetters) => {
      const { instruction_url } = getters
      const fs_abt = rootGetters['productDetail/common/fs_abt']
      return fs_abt?.usermanual?.param?.usermanual === 'show' && instruction_url
    },
    user_manual_enter_box: (state, getters, rootState, rootGetters) => {
      const language_v2 = rootGetters['productDetail/common/language_v2']
      return {
        title: language_v2.SHEIN_KEY_PWA_31004,
        content: language_v2.SHEIN_KEY_PWA_31005
      }
    },
    user_manual_drawer_box: (state, getters, rootState, rootGetters) => {
      const { instruction_url } = getters
      const language_v2 = rootGetters['productDetail/common/language_v2']
      return {
        title: language_v2.SHEIN_KEY_PWA_31006,
        link: instruction_url,
        shareContent: {
          title: language_v2.SHEIN_KEY_PWA_18311,
          cancelText: language_v2.SHEIN_KEY_PWA_20168,
          successText: language_v2.SHEIN_KEY_PWA_16423,
        }
      }
    }
  },
}
