/**
 * @field 页面加载、执行流程等工具
*/
export default {
  is_ssr() {

  },
  real_time_ready(state, getters, rootState, rootGetters) {
    return rootState?.newProductDetail?.common?.realTimeReady || false
  },
  // GB_cssRight
  gb_css_right() {
    const { GB_cssRight = false } = typeof gbCommonInfo === 'undefined' ? {} : gbCommonInfo
    return GB_cssRight
  },
}
