const getters = {
  isCombinedPackage (state) {
    const packageGoodsList = state.currentPackage || []
    const combinedGoods = packageGoodsList.flat(Infinity).find(item => {
      return item?.cross_order_flag == 1 && [3, 11, 18, 19].indexOf(item?.orderGoodsStauts) != -1
    })
    return combinedGoods
  }
}

export default getters
