import { computed, watch } from 'vue'
import { state } from './state'
import { getItemConfigByType, ItemConfigMap, detailRecommendConfig } from './ItemConfig/index'
import { getAtomFieldsByType } from './AtomFields/index'
import { getMergeConfig, deepDiff } from './utils'
export { EItemConfigType, EAtomFieldsType } from './const'
export { commonConfig } from './ItemConfig/index'


export const load = (options) => {
  if (typeof window === 'undefined') return

  const { isPaidUser, itemConfigAsync, atomFields, commonConfig, commonAtomConfig, isDebug, _productConfigDebugInfo } = options || {}


  state.value.isPaidUser = !!isPaidUser
  state.value.itemConfigAsync = itemConfigAsync || {}
  state.value.atomFields = atomFields || {}

  state.value.commonConfig = {
    ...state.value.commonConfig,
    ...commonConfig,
  }
  state.value.commonAtomConfig = commonAtomConfig


  state.value.loaded = true

  state.value.isDebug = _productConfigDebugInfo && isDebug
  if (state.value.isDebug) {
    window.$ProductConfigManager = window.$ProductConfigManager || {
      itemConfig: {},
      state: state.value,
      _productConfigDebugInfo,
      ItemConfigMap,
      deepDiff,
    }
  }

}

/**
 * 加载成功回调
 */
export const onManagerLoaded = () => {
  return new Promise((resolve) => {
    if (state.value.loaded) return resolve(true)
    watch(() => state.value.loaded, () => {
      resolve(true)
    },
    {
      once: true,
    })
  })
}

export const useItemConfig = ({ name, type, mergeConfig, useNewAbt = true, isRecommend = true } = {}) => {
  const { itemConfig: _itemConfig } = getItemConfigByType({ type, useNewAbt })

  const _mergeConfig = {
    // 商详推荐位专用配置
    ...(isRecommend ? detailRecommendConfig : {}),
    ...(mergeConfig || {}),
  }

  const itemConfig = computed(() => {
    return getMergeConfig(_itemConfig.value, _mergeConfig)
  })

  if (state.value.isDebug) {
    console.log('useItemConfig', name, itemConfig, mergeConfig)
    if (name) {
      window.$ProductConfigManager.itemConfig[name] = {
        itemConfig,
        mergeConfig,
      }
    }
  }

  return {
    itemConfig,
  }
}

/**
 * 获取原子配置（异步）
 */
export const useAtomConfig = async ({ type, useNewAbt = true, mergeConfig, isRecommend = true } = {}) => {

  /**
   * 会有在实时链路未返回调用的风险
   */
  await onManagerLoaded()

  const { fields, trueType, isNew } = getAtomFieldsByType({ type, useNewAbt, isRecommend }) || {}

  const _fields = {
    ...(fields || {}),
    ...(mergeConfig || {}),
  }

  const { abtInfo, ...otherConfig } = state.value.commonAtomConfig || {}

  const atomConfig = {
    ...(otherConfig || {}),
    abtInfo: {
      ...(abtInfo || {}),
      newProductCard: isNew,
    },
    fields: _fields,
    isPaid: state.value.isPaidUser ? 1 : 0,
    newPreHandle: true, // 新旧商卡统一走预处理，兼容de合规价
  }
  const res = {
    atomConfig,
    /**
     * 推荐使用的原子配置
     */
    atomConfigRec: {
      newPreHandle: atomConfig.newPreHandle,
      abtInfoV3: atomConfig.abtInfo,
      atomCccParams: atomConfig.cccParams,
      atomFields: atomConfig.fields,
      isPaid: atomConfig.isPaid,
    },
  }

  if (state.value.isDebug) {
    _fields._test_ = 0
    console.log('useAtomConfig', res, type, trueType)
  }

  return res
}
