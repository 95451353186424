// 用于存储 router.to 的副本, 部分预加载接口依赖location中取参,预加载情况下location还没有发生变化
let routerCopy = {}
  
export const getRouterCopy = () => {
  return routerCopy
}

export const setRouterCopy = (router) => {
  routerCopy = {
    ...router
  }
}
