const initQuickAddOptions = {
  active: false,
  goods_id: null,
  selectedAttrId: null,
  config: {},
  position: 'btt',
  slideClass: '',
  title: '',
  showBack: false,
  drawerChange: null,
  back: null,
  close: null
}
const state = () => ({
  context: {},
  exposureTag: [],
  exposureTime: 0, // 每个组件浏览需要达到的曝光时长
  exposureAllowed: true, // 是否允许曝光，主要针对锚点滚动区域曝光问题
  // quickAdd: { // 直接加车
  //   active: false,
  //   goods_id: null,
  //   id: null,
  //   selectedAttrId: null,
  //   config: {},
  //   index: 0
  // },
  initQuickAddOptions,
  quickAddOptions: initQuickAddOptions,
  saveList: {},   // 收藏状态
  recordSizeObj: {}, // 【专题列表商品尺码状态同步收藏列表】记录之前选中的尺寸，以便收藏时拿到之前选中的尺寸
  goodsDetail: {},
  productDescSku: '',
  commonInfo: { // 所有组件共享信息 在app层处理的 类似 loginStatus cateInfo
    needReInit: 0
  },
  reportCommonDatas: [], // 埋点相关的初始化组件数据
  reportView: [], // 已经曝光的数据
  voteList: [], // 投票 & pk 组件数据
  anchorCb: null, // 锚点跳转后的回调函数
  gotMall: false, // 是否已经获得了mall信息
  actMallList: [], // 保存mall列表信息
})

export default state
