const mutations = {
  setOrderList(state, list = []) {
    state.orderList = list
  },
  setIsShowDrawer(state, isShow){
    if(isShow) {
      window.SaPageInfo = {
        page_id: '6272',
        page_name: 'page_self_find_order',
        page_param: {
          page_from: 'page_order_list',
          is_result: 1
        },
        start_time: parseInt(new Date().getTime() / 1000)
      }
    } else {
      window.SaPageInfo = {
        page_id: '6272',
        page_name: 'page_self_find_order',
        page_param: {
          page_from: 'page_order_list',
          is_result: 0
        },
        start_time: parseInt(new Date().getTime() / 1000)
      }
      state.orderList = []
    }

    sa('set', 'setPageData', window.SaPageInfo)
    state.isShowResultDrawer = isShow
  }
}

export default mutations
