import {
  getEstimatedInfo,
  ESTIMATED_STYLE_TYPES,
  ESTIMATED_DATA_TYPES,
  DISCOUNT_PERCENT_STYLE_MAP
} from 'public/src/pages/product_app/store/modules/product_detail/utils/price.js'
import salePriceWordSize from 'public/src/pages/goods_detail/utils/salePriceWordSize'

export default {
  namespaced: true,
  actions: {
    click_estimated_tag({state, getters, rootState, rootGetters, dispatch, commit }, params) {
      let viewPromotionIsQuickAdd = rootState.newProductDetail.PromotionEnter.viewPromotionIsQuickAdd
      let { estimated_info, price_common_infos } = getters || {}
      let { fsAbt } = price_common_infos || {}
      let clickjumpath = fsAbt?.collestrategy?.p?.clickjumpath || ''
      if (fsAbt.Nestprice?.p?.Nestprice === 'addcartpopup' && estimated_info.dataType === ESTIMATED_DATA_TYPES.N_ESTIMATED) {
        let { updateBuyMultipleQuickAddStateFrom } = params || {}
        return commit('newProductDetail/common/updateBuyMultipleQuickAddState', { show: true, from: updateBuyMultipleQuickAddStateFrom || 1 }, { root: true }) // 打开快速加车加购N件
      }
      if (clickjumpath !== 'gatherpopup' || [1, 3, 4, 5, 6].includes(estimated_info.dataType)) {
        return commit('newProductDetail/PromotionEnter/updateViewPromotionStatus', { status: true }, { root: true })
      }
      if (viewPromotionIsQuickAdd.value) appEventCenter.$emit('quickAddOpenAddOn')
      dispatch('productDetail/common/add_on_item_open', {}, {root: true})
    }
  },
  getters: {
    price_common_infos(state, getters, rootState, rootGetters) { // 价格依赖的全局配置
      let fsAbt = rootGetters['productDetail/common/fs_abt']
      let priceInfo = rootGetters['productDetail/price_info']
      let isPaidUser = rootGetters['productDetail/common/is_paid_user']
      let language = rootGetters['productDetail/common/language_v2']
      let skuInfo = rootGetters['productDetail/common/sku_info']
      let skuList = rootGetters['productDetail/common/sku_list']
      let isSoldOut = rootGetters['productDetail/common/is_sold_out']
      let mallStock = rootGetters['productDetail/common/mall_stock']
      let mallSoldOut = mallStock == 0
      let goodsSn = rootGetters['productDetail/common/goods_sn']
      let goodsId = rootGetters['productDetail/common/goods_id']
      let { GB_cssRight } = rootGetters['productDetail/common/common_page_info'] || {}

      let complianceMode = rootGetters['productDetail/common/compliance_mode']
      let complianceTipsMode = rootGetters['productDetail/common/compliance_tips_mode']
      let complianceModeDe = rootGetters['productDetail/common/compliance_mode_de']
      let isHitComplianceMode = rootGetters['productDetail/common/is_hit_compliance_mode']
      
      let promotionInfo = rootGetters['productDetail/promotion/promotion_info']
      let newFlashPromotion = rootGetters['productDetail/promotion/new_flash_promotion_info']
      let sheinClubPromotionInfo = rootGetters['productDetail/promotion/shein_club_promotion_info']
      let flashType = rootGetters['productDetail/promotion/flash_type']
      const isFlash = rootGetters['productDetail/promotion/is_flash']
      const isNormalFlash = rootGetters['productDetail/promotion/is_normal_flash'] 
      const isBrandFlash = rootGetters['productDetail/promotion/is_brand_flash'] 
      let isSpecialFlash = rootGetters['productDetail/promotion/is_special_flash']

      let price = newFlashPromotion ? priceInfo : (skuInfo?.priceInfo || priceInfo || {})
      let realTimeReady = rootGetters['productDetail/common/real_time_ready']
      return {
        fsAbt,
        priceInfo,
        price,
        isPaidUser,
        promotionInfo,
        language,
        skuInfo,
        skuList,
        newFlashPromotion,
        sheinClubPromotionInfo,
        isFlash,
        flashType,
        isNormalFlash,
        isBrandFlash,
        isSpecialFlash,
        complianceMode,
        complianceTipsMode,
        complianceModeDe,
        isHitComplianceMode,
        goodsSn,
        goodsId,
        isSoldOut,
        mallSoldOut,
        realTimeReady,
        GB_cssRight
      }
    },
    is_show_price(state, getters) {
      let { isPaidUser, sheinClubPromotionInfo, isSpecialFlash, flashType } = getters.price_common_infos
      let { exclusive_info } = getters
      return (isPaidUser && !!sheinClubPromotionInfo) || (isSpecialFlash || flashType == 4) || (!!exclusive_info && exclusive_info.isS3newpriceAbtNew)
    },
    is_inversion(state, getters) {
      let { price } = getters.price_common_infos || {}
      return price.suggestedSalePriceInfo.isInversion
    },
    price_from(state, getters) {
      // 同一个skc下有多个sku价格并且没有选中尺码时展示from
      let { language, skuList, skuInfo } = getters.price_common_infos || {}
      let { appLanguage } = gbCommonInfo || {}

      let selectedSku  = !!skuInfo?.sku_code
      let skuPriceArr = skuList?.map?.(item => item?.priceInfo?.salePrice?.amount) || []
      let inequalitySkuPrice = false
      for(let i = 0; i < skuPriceArr.length; i ++) {
        if (skuPriceArr[0] != skuPriceArr[i]) inequalitySkuPrice = true
      }
      let show = !selectedSku && inequalitySkuPrice

      let isJp = appLanguage == 'ja'

      let result = {
        show,
        isShowMainPriceLeft: show && !isJp,
        isShowMainPriceRight: show && isJp,
        value: language.SHEIN_KEY_PWA_15320,
      }
      return result
    },
    main_price_info(state, getters, rootState, rootGetters) { // 主价格
      let { priceInfo, price, isPaidUser, sheinClubPromotionInfo, isHitComplianceMode, complianceModeDe, isNormalFlash, isBrandFlash, fsAbt } = getters.price_common_infos || {}
      let { estimated_info, is_show_price, suggested_sale_price_info, exclusive_info } = getters
      let lowest_price_tips = rootGetters['productDetail/priceTips/lowest_price_tips']

      const COLOR_MAP = {
        normalBlack: '#222',
        discountOrange: '#fa6338',
        vipBrown: '#873c00', // isPaidUser + priceInfo.showTypeId
        brandFlashWhite: '#FFF',
        s3Brown: '#806208',
      }
      // 大小价

      // showPrice.amountWithSymbol 存在 就展示 showPrice

      let { NEW, NEW_AND_NO_THRESHOLD_FLOOR } = ESTIMATED_STYLE_TYPES
      const getMainPriceValue = () => {
        if (estimated_info?.value && [NEW, NEW_AND_NO_THRESHOLD_FLOOR].includes(estimated_info?.styleType)) return estimated_info.value.amountWithSymbol
        if (is_show_price) {
          return priceInfo?.showPrice?.amountWithSymbol
        }
        return price?.salePrice?.amountWithSymbol
      }
      let value = getMainPriceValue()

      const getMainPriceColor = () => {
        let { normalBlack, discountOrange, vipBrown, brandFlashWhite, s3Brown } = COLOR_MAP

        if (!!exclusive_info && is_show_price) return s3Brown
        if (isPaidUser && !!sheinClubPromotionInfo) return vipBrown
        if (estimated_info && [NEW, NEW_AND_NO_THRESHOLD_FLOOR].includes(estimated_info?.styleType)) return discountOrange
        if (isNormalFlash) return normalBlack
        if (isBrandFlash) return brandFlashWhite

        if (isHitComplianceMode) {
          if (complianceModeDe && suggested_sale_price_info?.de?.show) {
            let { discountPercent, retailPriceDiscountPercent } = suggested_sale_price_info?.de || {}
            if (discountPercent || retailPriceDiscountPercent) return discountOrange
          } else {
            if (suggested_sale_price_info.isInversion) return normalBlack
            if (
              suggested_sale_price_info.show &&
              (!!suggested_sale_price_info?.pl?.show || !!suggested_sale_price_info?.fr?.show)
            ) return discountOrange
          }
          return normalBlack
        }

        if (lowest_price_tips?.mainPriceDiscountStyle) return discountOrange

        if (!price?.retailPrice?.amountWithSymbol) return normalBlack
        if (value !== price?.retailPrice?.amountWithSymbol) return discountOrange
        return normalBlack
      }
      let color = getMainPriceColor()
      let isSheinappwordsizeNew = fsAbt?.sheinappwordsize?.p?.sheinappwordsize === 'newprice'
      let getValueHtml = () => {
        if (estimated_info?.value && estimated_info?.styleType == ESTIMATED_STYLE_TYPES.OLD && fsAbt.middleast?.p?.middleast == 'new' ) {
          return `<span style="font-size: 12px;">${value}</span>`
        }
        if (!isSheinappwordsizeNew && [NEW, NEW_AND_NO_THRESHOLD_FLOOR].includes(estimated_info?.styleType)) {
          return `<span style="font-size: 20px;">${value}</span>`
        }
        if (isSheinappwordsizeNew) {
          return salePriceWordSize({ priceStr: value, gate: true })
        }
        return `<span style="font-size: 18px;">${value}</span>`
      }
      let valueHtml = getValueHtml()

      let result = {
        value,
        valueHtml,
        color,
        isSheinappwordsizeNew
      }

      return result
    },
    price_vat_info(state, getters, rootState) { // 税小气泡
      let { fsAbt, isBrandFlash, realTimeReady } = getters.price_common_infos || {}
      if (!realTimeReady) return null
      let { suggested_sale_price_info } = getters || {}
      let { Taxshowtype } = fsAbt
      let { vatInclusive, vatLowestPriceTips } = rootState.productDetail?.coldModules?.priceInfo?.vatInfo || {}
      let iconColor = '#959595'
      if (isBrandFlash) iconColor = '#FFF'
      if (suggested_sale_price_info.show && suggested_sale_price_info?.de?.show) {
        return {
          vatInclusive: Taxshowtype?.p?.Taxshowtype == 'A' ? vatInclusive : '',
          vatLowestPriceTips,
          iconColor,
          placemen: 'bottom-start'
        }
      }
      if (Taxshowtype?.p?.Taxshowtype !== 'A') return null
      if (!vatInclusive) return null
      return {
        vatInclusive,
        iconColor,
        placemen: 'top'
      }
    },
    discount_info(state, getters, rootState) {
      let { price, isFlash, isBrandFlash, isSpecialFlash, isHitComplianceMode } = getters.price_common_infos || {}
      let { estimated_info, is_show_price } = getters
      let { retailPrice, salePrice, showPrice, unitDiscount, discountValue } = price || {}
      let mainPrice = is_show_price ? showPrice : salePrice

      let show = (() => {
        if (isHitComplianceMode) return false
        if (!retailPrice || retailPrice?.amount == mainPrice?.amount) return false
        if (estimated_info) {
          if (estimated_info.styleType === ESTIMATED_STYLE_TYPES.NO_THRESHOLD_FLOOR) return true
          if (estimated_info.styleType === ESTIMATED_STYLE_TYPES.OLD) return true
          return false
        }
        return true
      })()

      let discountPercent = (() => {
        if (estimated_info && estimated_info.styleType === ESTIMATED_STYLE_TYPES.OLD) return ''
        let result = unitDiscount > 0 ? unitDiscount : ''
        if (is_show_price) result = discountValue
        if (result) result = result + '%'
        if (result && rootState.newProductDetail.coldModules.commonInfo.lang_rp != 'il') result = '-' + result
        return result
      })()

      let { normal, normalFlash, specialFlash, brandFlash } = DISCOUNT_PERCENT_STYLE_MAP
      let discountPercentStyle = normal
      let retailPriceStyle = {}
      if (isFlash) {
        if (isSpecialFlash) {
          discountPercentStyle = specialFlash
        } else if (isBrandFlash) {
          discountPercentStyle = brandFlash
          retailPriceStyle = {
            color: 'rgba(255, 255, 255, 0.8)'
          }
        } else {
          discountPercentStyle = normalFlash
        }
      }

      let result = {
        show,
        retailPrice: retailPrice?.amountWithSymbol,
        retailPriceStyle,
        discountPercent,
        discountPercentStyle,
      }
      return result
    },
    suggested_sale_price_info(state, getters, rootState) { // 合规划线价
      let { price, language, complianceMode, complianceTipsMode, complianceModeDe, isHitComplianceMode } = getters.price_common_infos || {}
      let { estimated_info, is_show_price } = getters
      let { suggestedSalePriceInfo, unitDiscount, discountValue } = price || {}
      let { suggestedUnitDiscount, suggestedVipUnitDiscount, isInversion } = suggestedSalePriceInfo || {}
      let value = suggestedSalePriceInfo?.suggestedSalePrice?.amountWithSymbol

      let discount = unitDiscount
      let showPriceDiscount = discountValue
      if (complianceModeDe) {
        discount = suggestedUnitDiscount
        showPriceDiscount = suggestedVipUnitDiscount
      }
      let finalDiscount = is_show_price ? showPriceDiscount : discount
      let discountPercent = finalDiscount > 0 ? finalDiscount : ''

      if (discountPercent) {
        discountPercent = discountPercent + '%'
        if (rootState.newProductDetail.coldModules.commonInfo.lang_rp != 'il') discountPercent = '-' + discountPercent 
      }

      if (estimated_info && (complianceMode || complianceTipsMode)) discountPercent = '' // fr pl 有到手价不展示折扣百分比

      let retailPrice = ''
      let retailPriceDiscountPercent = ''
      if (complianceModeDe) {
        retailPrice = price?.retailPrice?.amountWithSymbol,
        retailPriceDiscountPercent = is_show_price ? discountValue : unitDiscount
        if (Number(retailPriceDiscountPercent) == 0 || !retailPriceDiscountPercent) retailPriceDiscountPercent = ''
      }
      if (retailPriceDiscountPercent) {
        retailPriceDiscountPercent = retailPriceDiscountPercent + '%'
        if (rootState.newProductDetail.coldModules.commonInfo.lang_rp != 'il') retailPriceDiscountPercent = '-' + retailPriceDiscountPercent 
      }

      return {
        show: isHitComplianceMode && value,
        isInversion: isInversion == 1,
        fr: {
          show: complianceMode && !complianceTipsMode,
          value,
          discountPercent,
        },
        pl: {
          show: complianceTipsMode,
          value,
          discountPercent,
          tip: language.SHEIN_KEY_PWA_30762,
        },
        de: {
          show: complianceModeDe,
          value,
          valueTip: language.SHEIN_KEY_PWA_31307,
          discountPercent,

          retailPriceShow: !!retailPrice,
          retailPrice,
          retailTip: language.SHEIN_KEY_PWA_31306,
          retailPriceDiscountPercent,
        },
      }
    },
    skc_estimated_info(state, getters) {
      let { exclusive_info: exclusiveInfo } = getters
      let { isSoldOut, mallSoldOut, goodsSn, fsAbt, sheinClubPromotionInfo, isPaidUser, language, complianceMode, complianceModeDe, isFlash, isNormalFlash, isSpecialFlash, isBrandFlash, priceInfo, realTimeReady, GB_cssRight } = getters.price_common_infos || {}
      let { EstimatedPrice, EstimatedShowType, EstimatedNothreShowType, collestrategy, middleast } = fsAbt
      let showNoSatisfied = true
      if (collestrategy?.p?.colldisplayrest === 'popup') showNoSatisfied = false
      let result = getEstimatedInfo({
        skcPriceInfo: priceInfo,
        skuPriceInfo: null,
        isSoldOut: isSoldOut || mallSoldOut,
        abts: {
          EstimatedPrice,
          EstimatedShowType,
          EstimatedNothreShowType,
          middleast
        },
        showNoSatisfied, // 快速加车用只有主商品 true，商详看 abt colldisplayrest
        otherInfo: {
          exclusiveInfo,
          sheinClubPromotionInfo,
          isPaidUser,
          complianceMode,
          complianceModeDe,

          isFlash,
          isNormalFlash, // isNormalFlash isSpecialFlash isBrandFlash 三个同时只会生效一个
          isSpecialFlash,
          isBrandFlash,

          language,
          goodsSn,
          isQuickAdd: false,
          hasLink: true, // 快速加车用回调，商详固定true
          realTimeReady,
          GB_cssRight

        }
      })
      return result
    },
    estimated_info(state, getters) { // 到手价
      let { exclusive_info: exclusiveInfo, skc_estimated_info } = getters
      let { isSoldOut, mallSoldOut, price, priceInfo, goodsSn, fsAbt, sheinClubPromotionInfo, isPaidUser, language, complianceMode, complianceModeDe, isFlash, isNormalFlash, isSpecialFlash, isBrandFlash, skuInfo, realTimeReady, GB_cssRight } = getters.price_common_infos
      let { EstimatedPrice, EstimatedShowType, EstimatedNothreShowType, collestrategy, middleast } = fsAbt
      if (state.skc_estimated_info && !skuInfo) return skc_estimated_info
      let showNoSatisfied = true
      if (collestrategy?.p?.colldisplayrest === 'popup') showNoSatisfied = false
      let result = getEstimatedInfo({
        skcPriceInfo: priceInfo,
        skuPriceInfo: price,
        isSoldOut: isSoldOut || mallSoldOut,
        abts: {
          EstimatedPrice,
          EstimatedShowType,
          EstimatedNothreShowType,
          middleast,
        },
        otherInfo: {
          exclusiveInfo,
          showNoSatisfied,
          sheinClubPromotionInfo,
          isPaidUser,
          complianceMode,
          complianceModeDe,

          isFlash,
          isNormalFlash,
          isSpecialFlash,
          isBrandFlash,

          language,
          goodsSn,
          isQuickAdd: false,
          hasLink: true,
          realTimeReady,
          GB_cssRight
        }
      })
      return result
    },
    estimated_new_end_time(state, getters) {
      let { estimated_info } = getters || {}
      let { NEW, NEW_AND_NO_THRESHOLD_FLOOR } = ESTIMATED_STYLE_TYPES || {}
      if (![NEW, NEW_AND_NO_THRESHOLD_FLOOR].includes(estimated_info?.styleType)) return null
      return estimated_info?.endTime
    },
    exclusive_info(state, getters) {
      let { language, priceInfo, fsAbt } = getters.price_common_infos || {}
      if (priceInfo?.showTypeId == 12 && priceInfo?.showPrice) {
        return {
          isS3newpriceAbtNew: fsAbt?.EstimatedPrice?.p?.S3newprice == 'new',
          imgLink: 'https://img.ltwebstatic.com/images3_ccc/2024/07/23/00/1721706854eda1168bf47805172a56be15e10d076c.png',
          amountWithSymbol: priceInfo.showPrice.amountWithSymbol,
          text: language?.SHEIN_KEY_PWA_24353,
          introText: language?.SHEIN_KEY_PWA_17305,
          btnText: language?.SHEIN_KEY_PWA_15146,
        }
      }
      return null
    },
    flash_belt_wraper_info(state, getters, rootState, rootGetters) {
      let { isFlash, isNormalFlash, isSpecialFlash, isBrandFlash, fsAbt, promotionInfo, flashType, language, goodsId } = getters.price_common_infos || {}
      let flashProm = promotionInfo?.find?.(item => item.typeId == 10) || {}
      let endTime = null
      let isFlashSaleCountDownAbtHide = fsAbt?.FlashSaleCountDown?.p?.FlashSaleCountDown === 'Hide'
      if (isNormalFlash || isBrandFlash) {
        if (!isFlashSaleCountDownAbtHide) endTime = flashProm?.endTimestamp
      }
      let titleFlashTypeMap = {
        '1': language.SHEIN_KEY_PWA_14949,
        '2': language.SHEIN_KEY_PWA_21931,
        '3': language.SHEIN_KEY_PWA_21932,
        '6': language.SHEIN_KEY_PWA_29102,
      }
      let title = titleFlashTypeMap[flashType]
      if (!title) title = flashProm?.flashSaleTip || language.SHEIN_KEY_PWA_26575 || language?.SHEIN_KEY_PWA_14949

      const beltLoaded = rootGetters['newProductDetail/PriceBelt/beltLoaded'] // @@seb 旧数据流
      const skcPreposeVal = rootGetters['newProductDetail/ColorBox/skcPreposeVal'] // @@seb 旧数据流
      const freeShippingInfo = rootGetters['newProductDetail/ShippingEnter/freeShippingInfo'] // @@seb 旧数据流
      const langPath_rp = rootState.newProductDetail.coldModules?.commonInfo?.langPath_rp // @@seb 旧数据流
      const isShowBelt = beltLoaded && skcPreposeVal !== 'A'
      const skcPreposeStyle = skcPreposeVal !== 'A' && !freeShippingInfo?.freeShippingStyle?.isStrip

      let brandFlashLinkClickAble = (fsAbt?.brandskip?.p?.brandskip || 'Cannotjump') === 'Jumpable'
      
      return {
        flashType,
        isFlash,
        isNormalFlash,
        isSpecialFlash,
        isBrandFlash,

        title,
        endTime,
        language,
        
        brandFlashLinkClickAble,
        isFlashSaleCountDownAbtHide,

        beltLoaded,
        isShowBelt,
        skcPreposeStyle,
        langPath_rp,
        goodsId,
        flashProm

      }
    }
  }
}
