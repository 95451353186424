import { template } from '@shein/common-function'

export default {
  namespaced: true,
  getters: {
    sizeConfig(state, getters, rootState, rootGetters) {
      return rootGetters['newProductDetail/SizeBox/sizeConfig']
    },
    sizeConstant(state, getters, rootState, rootGetters) {
      return rootGetters['newProductDetail/SizeBox/sizeConstant']
    },
    currentUnit(state, getters, rootState, rootGetters) {
      return rootGetters['newProductDetail/SizeBox/currentUnit']
    },
    goodsMainAttPicInfo(state, getters, rootState, rootGetters) {
      return rootGetters['newProductDetail/ColorBox/colorBoxInfo'].goodsMainAttPicInfo
    },
    goodsDesc(state, getters, rootState, rootGetters) {
      return rootGetters['newProductDetail/ColorBox/colorBoxInfo'].goodsDesc
    },
    tipsCombo(state, getters, rootState, rootGetters) {
      return rootGetters['newProductDetail/SizeBox/tipsCombo']
    },
    language(state, getters, rootState, rootGetters) {
      return rootGetters['newProductDetail/common/language']
    },
    isShowColors(state, getters, rootState, rootGetters) {
      return rootGetters['newProductDetail/ColorBox/isShowColors']
    },
    hideOneSizeModule(state, getters, rootState, rootGetters) {
      // 该品类one-size直接隐藏
      const hideOneSize = rootGetters['newProductDetail/SizeBox/hideOneSize']
      const { productInfo } = rootState.newProductDetail.coldModules || {}
      const { sizeFormatData = {} } = productInfo || {}
      const { saleAttrList } = sizeFormatData || {}
      const { polyAttrSize, skcSaleAttr } = saleAttrList || {}
      return hideOneSize && skcSaleAttr?.length === 1 && polyAttrSize?.length == 1 && polyAttrSize[0]['attr_value_name_en'] == 'one-size'
    },
    saleAttrsArr(state, getters, rootState) {
      // 销售属性集合
      const externalSizeInfoIndex = rootState.newProductDetail.common.externalSizeInfoIndex || {}
      const { productInfo } = rootState.newProductDetail.coldModules || {}
      const { detail = {}, sizeFormatData = {} } = productInfo || {}
      const mainSaleAttr = detail.mainSaleAttribute?.[0] || {}
      const { saleAttrList } = sizeFormatData || {}
      const { skcSaleAttr } = saleAttrList || {}
      let result = []
      if (getters.isShowColors) result.push({ ...mainSaleAttr, isMainAttr: true, sortValue: 1 })
      if (skcSaleAttr?.length && !getters.hideOneSizeModule) result.push(...skcSaleAttr)
      result.sort((a, b) => {
        const sortA = (externalSizeInfoIndex[a.attr_id]?.attr_value_id || a.isMainAttr) ? 1 : 0
        const sortB = (externalSizeInfoIndex[b.attr_id]?.attr_value_id || b.isMainAttr) ? 1 : 0
        return sortB - sortA
      })
      return result.filter(item => !!item?.attr_name)
    },
    mallInfo(state, getters, rootState, rootGetters) {
      return rootGetters['newProductDetail/common/detail']?.mall_info || []
    },
    colorsFoldInfo(state, getters, rootState, rootGetters) {
      if (getters.isShowColors) {
        const colorBoxInfo = rootGetters['newProductDetail/ColorBox/colorBoxInfo'] || {}
        const { colorsInfo } = colorBoxInfo
        const colorList = []
        colorsInfo?.some?.(item => {
          if (item.goods_thumb) {
            colorList.push(item)
          }
          return colorList.length >= 3
        })
        const colorMoreNum = colorsInfo?.length > 3 ? colorsInfo?.length - colorList.length : 0
        const showColorList = colorList?.length > 1
        return {
          showColorList,
          colorList,
          colorMoreNum,
        }
      }
      return {}
    },
    onlyOneSize(state, getters, rootState) {
      // 单sku的时候需要默认选中
      const { productInfo } = rootState.newProductDetail.coldModules || {}
      const { sizeFormatData = {} } = productInfo || {}
      const { saleAttrList } = sizeFormatData || {}
      const { polyAttrSize, skcSaleAttr, skuList } = saleAttrList || {}
      return (skcSaleAttr?.length == 1 && polyAttrSize?.length <= 1) || skuList?.length == 1
    },
    sizeComposeInfo(state, getters, rootState, rootGetters) {
      const dataMapCompose = rootGetters['newProductDetail/SizeBox/dataMapCompose']
      const skuMapCompose = rootGetters['newProductDetail/SizeBox/skuMapCompose']
      const { productInfo } = rootState.newProductDetail.coldModules || {}
      const { sizeFormatData = {}, sizeInfoDes, localsize } = productInfo || {}
      const { saleAttrList, tackInfoDes, heightSizeMap } = sizeFormatData || {}
      const { skcSaleAttr, skuList, polyAttrSize, skcName } = saleAttrList || {}
      const currentCountry = rootGetters['newProductDetail/common/currentCountry']
      // 本地化尺码
      const localSizeRules = localsize?.size_rule_list?.[currentCountry] || []
      const localSizeMap = () => {
        const result = {}
        localSizeRules?.forEach?.(item => {
          const { correspond, name } = item || {}
          result[name] = correspond
        })
        return result
      }
      // 折叠入口不展示Click to buy
      const showMultiLayer = true
      return {
        skcSaleAttr, 
        localSizeRules,
        tackInfoDes,
        sizeInfoDes,
        skuList,
        showMultiLayer,
        polyAttrSize,
        dataMapCompose,
        skuMapCompose,
        skcName,
        localSizeMap: localSizeMap(),
        heightSizeMap,
      }
    },
    currentSkuSoldOut(state, getters, rootState) {
      const skuInfo = rootState.newProductDetail.common.skuInfo || {}
      if (!skuInfo.sku_code) return false
      const { mall_stock = [] } = skuInfo
      return mall_stock?.every(item => !item.stock)
    },
    soldoutTipsLimitNum(state, getters, rootState, rootGetters) {
      return rootGetters['newProductDetail/SizeBox/tipsCombo'].soldoutTipsLimitNum || 10
    },
    almostSoldOut(state, getters, rootState, rootGetters) {
      const { soldoutTipsLimitNum } = getters || {}
      const { productInfo } = rootState.newProductDetail.coldModules || {}
      const { sizeFormatData } = productInfo || {}
      const { saleAttrList } = sizeFormatData || {}
      const { skuList } = saleAttrList || {}
      const mallCode = rootGetters['newProductDetail/common/mallCode']
      let allSkuStock = 0
      for (let i = 0; i < skuList?.length || 0; i++) {
        allSkuStock += +skuList[i]?.mall?.[mallCode]?.stock || 0
        if (allSkuStock > soldoutTipsLimitNum) {
          return false
        }
      }
      if (allSkuStock == 0) {
        return false
      }
      return true
    },
    sizeStockTips(state, getters, rootState, rootGetters) {
      const { attrLowStockTipsPlan } = rootGetters['newProductDetail/SizeBox/sizeConfig'] || {}
      if(attrLowStockTipsPlan === 'B') {
        return false
      }
      const currentMall = rootGetters['newProductDetail/common/currentMall']
      const { stock, is_absolute_low_inventory, is_relatively_low_inventory } = currentMall || {}
      let judge
      if (stock <= 0) {
        judge = false
      } else if (is_absolute_low_inventory || is_relatively_low_inventory) {
        judge = true
      }
      return !!judge
    },
    soldOutTips(state, getters, rootState, rootGetters) {
      const { language } = getters
      const currentMall = rootGetters['newProductDetail/common/currentMall']
      const { stock, is_absolute_low_inventory, is_relatively_low_inventory } = currentMall || {}

      if(stock <= 0) {
        return ''
      }else if(is_absolute_low_inventory === true) {
        return template(stock, language.SHEIN_KEY_PWA_16814)
      }else if(is_relatively_low_inventory === true) {
        return language?.SHEIN_KEY_PWA_16827
      }
      return ''
    },
    sizeFeedBackBind(state, getters, rootState, rootGetters) {
      const language = rootGetters['newProductDetail/common/language']
      const constant = rootGetters['newProductDetail/SizeBox/sizeConstant']
      const baseInfo = rootGetters['newProductDetail/common/detail']
      const { localSize, sizeInfoDes, saleAttrList, country } = rootGetters['newProductDetail/SizeBox/sizeComposeData']
      const { polyAttrSize, skcSaleAttr } = saleAttrList || {}
      // feed-back-basic-sizes => 组件内部计算
      const { isNewSizeLocal } = rootGetters['newProductDetail/SizeBox/sizeConfig']
      return {
        language,
        constant,
        baseInfo,
        localSize,
        sizeInfoDes,
        currentSizeList: polyAttrSize,
        skcSaleAttr,
        isNewSizeLocal,
        country,
        asyncContentReady: false,
      }
    },
    localSelectComb(state, getters, rootState, rootGetters) {
      const goodsId = rootGetters['newProductDetail/common/goodsId']
      const { localSize, country } = rootGetters['newProductDetail/SizeBox/sizeComposeData']
      const { isNewSizeLocal } = rootGetters['newProductDetail/SizeBox/sizeConfig']
      return {
        id: goodsId,
        value: country,
        localSize,
        isNewSizeLocal,
      }
    },
    quickShipBind(state, getters, rootState, rootGetters) {
      const language = rootGetters['newProductDetail/common/language']
      const mallCode = rootGetters['newProductDetail/common/mallCode']
      const skuInfo = rootState.newProductDetail.common.skuInfo || {}
      const { saleAttrList } = rootGetters['newProductDetail/SizeBox/sizeComposeData']
      const { skuList, skcSaleAttr } = saleAttrList || {}
      const { localSizeMap } = getters.sizeComposeInfo
      const constant = rootGetters['newProductDetail/SizeBox/sizeConstant']
      const { isNewSizeLocal, from } = rootGetters['newProductDetail/SizeBox/sizeConfig']
      const { deliveryFloorQuickshipTitle } = rootGetters['newProductDetail/SizeBox/tipsCombo']
      return {
        from,
        mallCode,
        skuInfo,
        skuList,
        skcSaleAttr,
        localSizeMap,
        cssRight: constant?.cssRight,
        language,
        isNewSizeLocal,
        deliveryFloorQuickshipTitle,
      }
    },
    mallBind(state, getters, rootState, rootGetters) {
      const { mallInfo, currentSkuSoldOut } = getters
      const language = rootGetters['newProductDetail/common/language']
      const mallCode = rootGetters['newProductDetail/common/mallCode']
      const isOnSale = rootGetters['newProductDetail/common/isOnSale']
      const config = rootGetters['newProductDetail/SizeBox/sizeConfig']
      const constant = rootGetters['newProductDetail/SizeBox/sizeConstant']
      // chooseCheck => 组件内部赋值
      // mallClick => 组件内部赋值
      return {
        mallList: mallInfo,
        language,
        mallCode,
        titleBold: false,
        isOnSale,
        from: config?.from,
        config,
        constant,
        currentDisable: currentSkuSoldOut,
      }
    },
    compShowConfig(state, getters, rootState, rootGetters) {
      const { saleAttrsArr, onlyOneSize, sizeConfig, mallInfo } = getters || {}
      const { showMallSite, qty, showQuickShip } = sizeConfig || {} 
      const saleAttrsFoldAb = rootGetters['newProductDetail/common/saleAttrsFoldAb']
      // 模块是否展示配置
      // 折叠入口
      const showFoldEntry = !!saleAttrsArr?.length
      // Mall + Qty => 折叠入口隐藏 || 命中A折叠方案
      const showMallAndQty = !showFoldEntry || saleAttrsFoldAb === 'fold_A'
      // 色块描述 + 销售属性图片 + 销售属性气泡 => 展示折叠入口 + 命中A折叠方案 + 默认选中sku的场景需要展示
      const showAttrInfoComp = showFoldEntry && saleAttrsFoldAb === 'fold_A'
      // 库存tip提示位置 => 折叠入口 > Qty > 独立一行
      const stockTipLocation = showFoldEntry ? 'fold' : (qty?.isShow ? 'qty' : 'line')
      // not your size 入口展示
      const showFeedBackComp = !showFoldEntry || (showFoldEntry && saleAttrsFoldAb === 'fold_A' && onlyOneSize)
      // tips 功能
      const showTipsComp = !showFoldEntry
      // quickShip 功能
      const showQuickShipComp = !showFoldEntry

      // ----------------- 以下原有模块展示条件 -----------------
      const showMallEntry = showMallAndQty && showMallSite && mallInfo?.length > 0
      const showQuickShipEntry = showQuickShipComp && showQuickShip

      return {
        showFoldEntry,
        showMallAndQty,
        stockTipLocation,
        showAttrInfoComp,
        showFeedBackComp,
        showTipsComp,
        showMallEntry,
        showQuickShipEntry,
      }
    },
  },
  actions: {
    handleCompose({ commit, rootState }, payload) {
      // curSelectAttr(应该没用了，用作点击尺码埋点上报，折叠不就了不需要了)
      const { attrs, skuInfo, externalSizeInfoIndex, mallStock } = payload || {}
      const addToBagForm = rootState.newProductDetail.common.addToBagForm || {}
      addToBagForm.attrs = attrs
      commit('newProductDetail/SizeBox/updateRealMallStock', mallStock, { root: true })
      commit('newProductDetail/common/updateSkuInfo', skuInfo, { root: true })
      commit('newProductDetail/common/updateAddToBagForm', addToBagForm, { root: true })
      commit('newProductDetail/common/updateExternalSizeInfoIndex', externalSizeInfoIndex, { root: true })

      if (typeof window !== 'undefined') {
        window.vBus &&
          window.vBus.$emit('changeWakeupData', {
            sku_code: skuInfo?.sku_code || '',
            selected_attr_value_list: attrs.map((_) => String(_.attr_value_id)) || [],
          })
      }
    },
    mallClick({ commit }, payload) {
      const { mallCode } = payload || {}
      commit('newProductDetail/SizeBox/updateSwitchMallCode', mallCode, { root: true })
      commit('newProductDetail/SizeBox/updateRealMallStock', null, { root: true })
    },
  },
}
