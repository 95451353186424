export default {
  namespaced: true,
  state: {
    serialProducts: null, // 只有在当前组件里面用到的数据收敛到组件里面来
  },
  mutations: {
    updateSerialProducts(state, payload) {
      state.serialProducts = payload
    },
  },
  actions: {
    // 套装详情
    getSerialProducts({ commit, rootState, rootGetters }) {
      const serial_no = sessionStorage.getItem('serial_no')
      if (!serial_no) {
        commit('updateSerialProducts', null)
        return
      }
      const fromSwitchColor = rootState.newProductDetail.common.fromSwitchColor
      const getTheLookInfo = rootGetters['newProductDetail/getTheLook']
      const detail = rootState.newProductDetail.coldModules.productInfo.detail || {}

      let item = {}
      const serialProductsCache = JSON.parse(sessionStorage.getItem('serialProductsCache') || '{}')

      if (fromSwitchColor) {
        // 切换颜色不刷新
        item = serialProductsCache
      } else {
        item = getTheLookInfo?.find((item) => item.series_no === serial_no)
        sessionStorage.removeItem('cacheSerialGoodsId')
      }

      if (item) {
        if (item.series_no != serialProductsCache?.series_no) {
          const data = []
          const target = []
          item?.related_goods?.forEach((i) => {
            if (i.goods_id != detail.goods_id) {
              data.push(i)
            } else {
              target.push(i)
            }
          })
          item.related_goods = target.concat(data)
          commit('updateSerialProducts', item)
          sessionStorage.setItem('serialProductsCache', JSON.stringify(item))
        } else {
          commit('updateSerialProducts', serialProductsCache)
        }
      } else {
        commit('updateSerialProducts', null)
        sessionStorage.removeItem('serialProductsCache')
      }
      sessionStorage.removeItem('serial_no')
    },
  },
  getters: {
    
    serial_products_base(state, getters, rootState, rootGetters) {
      const language = rootGetters['productDetail/common/language_v2']
      const { langPath, GB_cssRight } = gbCommonInfo
      const mall_code = rootGetters['productDetail/common/mall_code']
      const goods_id = rootGetters['productDetail/common/goods_id']
      return {
        goods_id,
        mall_code,
        langPath,
        GB_cssRight,
        language,
      }
    },
    // 主题629的数据展示入口提前到LOOKBOOK详情页
    get_matching_styles_info(state, getters, rootState) {
      return rootState.productDetail.asyncModules.getTheLookRelatedIdSeries || []
      // const { IS_RW } = rootState.newProductDetail.coldModules.commonInfo
      // if (IS_RW) return []
      // const fsAbt = rootGetters['productDetail/fs_abt']
      // if (!fsAbt?.DetailFamilyOutfits?.param?.DetailFamilyOutfits) return []
      // const { getTheLook = [] } = rootState.newProductDetail.hotModules.getTheLook
      // if (!getTheLook.length) return []
      // let result = []
      // getTheLook.forEach((series) => {
      //   const isNotAllSoldOut = series.related_goods.some((goodItem) => {
      //     return goodItem?.is_on_sale || goodItem?.stock
      //   })
      //   if (series.theme_id === 629 && isNotAllSoldOut && result.length <= 3) {
      //     result.push(series)
      //   }
      // })
      // return result
    },
    show_serial_products(state, getters) {
      const matchingStylesInfo = getters.get_matching_styles_info
      const matchingStylesRelatedGoods = matchingStylesInfo[0]?.related_goods || []
      return Boolean(
        state.serialProducts?.related_goods?.length || matchingStylesRelatedGoods.length
      )
    },
  },
}
