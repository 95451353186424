import Vuex from 'vuex'
import Vue from 'vue'
import getRoot from './modules/root'

Vue.use(Vuex)

export function createStore (context) {
  return new Vuex.Store({
    ...getRoot(context)
  })
}
