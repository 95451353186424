import itemSer from 'public/src/services/goodsItemInfo/interfaceSer'
import { getSiteMallInfo } from 'public/src/pages/common/getSiteMallInfo'
import { getDetailRecPreTreat } from 'public/src/pages/goods_detail/utils/productDetail.js'
import { getAtomicRequestFileds } from 'public/src/services/goodsItemInfo/utils.js'

export function getStepChildData (data, step = 1) {
  let childIndex = 1
  if (step === childIndex) {
    childIndex = 1
    return data
  }
  if (data.children && data.children.length) {
    childIndex += 1
    return getStepChildData(data.children[0], step)
  }

  childIndex = 1
  return null
}


// 获取商品项节点的埋点dataset
export function getProductItemDataset (productDetail) {
  if (!productDetail) return {}

  const pretreatInfo = productDetail?.pretreatInfo || {}

  const mixPromotionInfo = pretreatInfo.mixPromotionInfo || []
  const isSoldOut =
    productDetail.is_on_sale == 0
    || productDetail.stock == 0
    || productDetail.sale_percent == 1

  return {
    'data-sku': productDetail.goods_sn || '',
    'data-spu': productDetail.productRelationID || '',
    'data-id': productDetail.goods_id || '',
    'data-name': productDetail.goods_name || '',
    'data-rec_mark': productDetail.ext?.rec_mark || '',
    'data-extra_mark': productDetail.ext?.extra_mark || '',
    'data-other_ext_mark': productDetail.ext?.other_ext_mark || '',
    'data-other_d_ext_mark': productDetail.dynamic_ext?.other_d_ext_mark || '',
    'data-cat_id': productDetail.cat_id || '',
    'data-price': productDetail.salePrice?.amount || '',
    'data-us-price': productDetail.salePrice?.usdAmount || '',
    'data-us-origin-price': productDetail.retailPrice?.usdAmount || '',
    // 'data-index':this.index)
    'data-type': productDetail.dataSource || '',
    // 'data-similar':isSoldOut && this.config.showSimilarBtn || '',
    'data-reduce-price': !isSoldOut && pretreatInfo.reducePrice?.usdAmount || '',
    'data-soldout': Number(isSoldOut),
    'data-sold-out': Number(isSoldOut),
    // 'data-show-exclusive-price':productDetail.exclusivePromotionPrice || '',
    'data-lable': pretreatInfo.label || '',
    // 'data-series-brand':!promoLabelSrc && pretreatInfo.seriesOrBrandAnalysis || '',
    'data-brand': productDetail.brand?.name || '',
    'data-brand-code': productDetail.brand?.brand_code || '',
    'data-video': productDetail.video_url ? 'video_icon' : '',
    // 'data-spu-img':this.spuImg ? (this.needShowSpuImg ? 'spupic_1' : 'spupic_0') : '':// 有spu图且展示则上报spupic_1，有spu图但不展示则上报spupic_0，无spu图不上报
    'data-mall_tag_code': `${pretreatInfo.mallTagsInfo?.mall_tags || ''}_${ productDetail.mall_code || '-'}`,
    'data-store_code': productDetail.store_code ?? '',
    'data-sale-attr': pretreatInfo.saleAttr?.analysis || '',
    // 'data-show-promot-info':this.labelsShow.promotionLabel || '',
    'data-price-cut': pretreatInfo.priceCut || '',
    'data-promotion-id': mixPromotionInfo.map(item => item?.id).join('`') || '',
    'data-type-id': mixPromotionInfo.map(item => item?.typeId).join('`') || '',
    'data-best-deal': pretreatInfo.showBestDeal ? 1 : '',
    // 'data-promo-label':promoLabelSrc ? 1 : '',
    'data-user-behavior-label': pretreatInfo.mobileVerticalView?.userBehaviorLabel?.ana || '',
    // 'data-ranking-list':this.rankingList?.ana || '',
    'data-belt-label': pretreatInfo.mobileVerticalView?.beltLabel?.ana || '',
  }
}

/**
 * 通过商品数据在客户端获取新商卡原子数据
 */
export const getNewCardAtomGoodsByGoods = async (options = {}) => {
  const {
    goods = [],
    fields,
    requestMenuList = [],
    cccParams = {},
    abtInfo = {},
    itemConfig = {},
    subPageKey = 'other_recommend_1pic',
    sceneKey = 'ONE_IN_A_ROW',
    isRecommend,
    isPaid,
  } = options

  let _hasSkuCode = false
  // 清理数据
  const goodsReq = goods.map(d => {

    // 组合购/新搭配选中sku场景需要查询sku纬度价格
    const sku_code = d?._cartInfo?.skuCode || undefined

    if (!_hasSkuCode && sku_code) _hasSkuCode = true

    return {
      goods_id: d.goods_id,
      cat_id: d.cat_id,
      mall_code: d.mall_code,
      goods_sn: d.goods_sn,
      sku_code
    }
  })

  const { mallInfoForSite = [] } = await getSiteMallInfo()

  const _fields = fields ? fields : getAtomicRequestFileds(itemConfig, requestMenuList, isRecommend)

  // sku价格原子
  if (_hasSkuCode && !_fields?.skuPrices) {
    _fields.skuPrices = true
  }

  const opt = {
    fields: _fields,
    cccParams,
    abtInfo,
    pageKey: 'page_goods_detail_rec',
    subPageKey,
    sceneKey,
    goods: goodsReq,
    mallInfo: mallInfoForSite,
    isPaid,
  }

  await itemSer.getAtomicInfoNew(opt)

  // 新商品卡片的场景
  return await getDetailRecPreTreat({
    products: goodsReq,
    itemConfig
  })
}
