class Cache {
  constructor() {
    this.state = {
      closeBff: false,
    }
  }

  get(key = '') {
    if (!key) return ''
    return this.state[key]
  }

  set(key = '', value = '') {
    this.state[key] = value
  }
}

export default new Cache()
