// TODO MOCK

import commonTools from 'public/src/pages/product_app/store/modules/product_detail/common/modules/tools.js'
import commonStock from 'public/src/pages/product_app/store/modules/product_detail/common/modules/stock.js'
import commonSaleAttr from 'public/src/pages/product_app/store/modules/product_detail/common/modules/saleAttr.js'
import commonLabel from 'public/src/pages/product_app/store/modules/product_detail/common/modules/label.js'
import { reg } from 'public/src/pre_requests/modules/goods_detail.js'
import { closestRatio } from 'public/src/pages/goods_detail/utils/common.js'

export default {
  ...commonTools,
  ...commonStock,
  ...commonSaleAttr,
  ...commonLabel,
  is_ssr(state, getters, rootState, rootGetters) {
    return rootGetters['newProductDetail/isSsr']
  },
  common_page_info(state, getters, rootState) {
    return rootState.productDetail.coldModules.commonPageInfo || {}
  },
  language_v2(state, getters, rootState, rootGetters) {
    return rootGetters['newProductDetail/common/language']
  },
  product_items_language(state, getters, rootState, rootGetters) {
    return rootGetters['newProductDetail/common/productItemsLanguage'] // todo_del
  },
  cache_screen_data(state, getters, rootState, rootGetters) {
    // TODO: 旧数据流，预取数据
    const cacheScreenData = rootGetters['newProductDetail/cacheScreenData']
    return cacheScreenData
  },
  goods_id(state, getters, rootState) {
    return rootState.productDetail.coldModules.productInfo.goods_id || ''
  },
  goods_thumb(state, getters, rootState) {
    return rootState.productDetail.coldModules.productInfo.goods_thumb || ''
  },
  goods_img(state, getters, rootState) {
    return rootState.productDetail.coldModules.productInfo.goods_img || ''
  },
  mall_code(state, getters, rootState) {
    // 旧数据流，等依赖相关方全部迁移完毕后删除
    if (getters.cache_screen_data) {
      return getters.cache_screen_data.mallCode || ''
    }
    return rootState.newProductDetail?.SizeBox?.switchMallCode || rootState.productDetail.coldModules?.productInfo?.selectedMallCode || '' 
  },
  // 当前mall
  current_mall(state, getters, rootState, rootGetters) {
    // 旧数据流，等依赖相关方全部迁移完毕后删除
    return rootGetters['newProductDetail/common/currentMall']
  },
  fs_abt(state, getters, rootState, rootGetters) {
    // TODO: 旧数据流，app接口切换mall的时候需要按照切换skc的方式处理，后续迁移
    return rootGetters['newProductDetail/fsAbt']
  },
  is_paid_user(state, getters, rootState, rootGetters) {
    // TODO: 旧数据流，app接口切换mall的时候需要按照切换skc的方式处理，后续迁移
    // return rootGetters['newProductDetail/isPaidUser']
    return rootGetters['productDetail/user_info']?.isPaidMember === '1'
  },
  is_can_trial(state, getters, rootState, rootGetters) {
    return rootGetters['productDetail/user_info']?.isCanTrial == '1'
  },
  goods_sn(state, getters, rootState, rootGetters) {
    const goodsSn = rootGetters['newProductDetail/common/goodsSn']
    return goodsSn
  },
  /**
   * 合规价相关 todo
   */
  compliance_mode(state, getters, rootState) { // 是否开启价格合规 / 后续新增合规规则 complianceModeV1 / complianceModeV2
    return rootState.newProductDetail.coldModules.pageInfo.complianceMode
  },
  compliance_tips_mode(state, getters, rootState)  { // 合规文案模式
    return rootState.newProductDetail.coldModules.pageInfo.complianceTipsMode
  },
  compliance_mode_de(state, getters, rootState)  { // 德国合规
    return rootState.newProductDetail.coldModules.pageInfo.complianceModeDe
  },
  is_hit_compliance_mode(state, getters, rootState) { // 是否命中合规模式，包含所有
    return rootState.newProductDetail.coldModules.pageInfo.isHitComplianceMode
  },
  // 保留商品配置信息
  retention_position_config(state, getters, rootState) {
    const { is_sold_out, fs_abt } = getters
    const { SameLabel } = fs_abt
    const { sameLabelBelt } = rootState.productDetail.hotModules.beltInfo

    const res = {
      show: false,
      position: '',
      // rightText: '',
      contentText: '',
    }

    const position = SameLabel?.param?.SameLabel == 'NO' ? '' : SameLabel?.param?.SameLabel

    if (is_sold_out || !sameLabelBelt || !position) return res

    return {
      ...res,
      position,
      contentText: sameLabelBelt?.lowestPriceTips,
      show: true,
    }
  },
  parent_cats(state, getters, rootState, rootGetters) {
    const parentCats = rootGetters['newProductDetail/common/parentCats']
    return parentCats
  },
  sku_code(state, getters, rootState, rootGetters) { // TODO: 旧数据流，预取数据
    return rootGetters['newProductDetail/common/skuCode']
  },
  cache_sku_list(state, getters, rootState) {
    return rootState.productDetail.coldModules?.saleAttr?.multiLevelSaleAttribute?.sku_list
  },
  real_time_sku_list(state, getters, rootState) {
    return rootState.productDetail.hotModules?.saleAttr?.multiLevelSaleAttribute?.sku_list
  },
  sku_info(state, getters) {
    const { sku_code, cache_sku_list, real_time_sku_list } = getters || {}
    const cacheSkuInfo = cache_sku_list?.find?.(item => item.sku_code === sku_code) || {}
    const realTimeSkuInfo = real_time_sku_list?.find?.(item => item.sku_code === sku_code) || {}
    const skuInfo = {
      ...cacheSkuInfo,
      ...realTimeSkuInfo,
    }
    if (skuInfo?.sku_code) {
      return skuInfo
    }
    return null
  },
  sku_list(state, getters, rootState, rootGetters) {
    // 尽量别用了，有缓存的
    return rootGetters['productDetail/sku_list'] || []
  },
  current_local_country(state, getters, rootState) {
    // todo: 旧数据流
    const { currentLocalCountry } = rootState['newProductDetail'].common || {}
    return currentLocalCountry
  },
  show_evolu_shein(state, getters) {
    const { fs_abt } = getters
    const showEvoluShein = fs_abt?.evolushein?.p?.evolushein === 'show'
    return showEvoluShein
  },
  quick_ship(state, getters, rootState) {
    return rootState.newProductDetail.common.quickShip// TODO：旧
  },
  product_relation_id(state, getters, rootState) {
    return rootState.productDetail.coldModules.productInfo?.productRelationID || ''
    // return rootGetters['newProductDetail/common/productRelationID'] // TODO：就
  },
  cat_id(state, getters, rootState) {
    return rootState.productDetail.coldModules.productInfo.cat_id
  },
  // 店铺人气氛围列表
  store_atmosphere_info(state, getters, rootState) {
    // 降级措施: bff的get_goods_detail_realtime_data接口请求取不到数据时，或降级未调用bff接口时。
    // 则数据默认为空数组。店铺人气氛围功能就不展示了。
    const { productLabel } = rootState.productDetail.hotModules
    return productLabel?.storeTipList || []
  },
  has_fit(state, getters, rootState) {
    const { comments_overview } = rootState.productDetail.hotModules.comment || {}
    return +comments_overview?.hasFit
  },
  // 是否是定制商品 todo del
  is_customization(state, getters, rootState, rootGetters) {
    return rootGetters['newProductDetail/common/isCustomization']
  },
  store_code(state, getters, rootState) {
    return rootState.productDetail.coldModules.storeInfo?.store_code
  },
  show_from(state, getters, rootState, rootGetters) {
    return rootGetters['newProductDetail/common/showFrom']
  },
  add_to_bag_form(state, getters, rootState, rootGetters) {
    return rootGetters['newProductDetail/common/addToBagForm']
  },
  external_size_info_index(state, getters, rootState, rootGetters) {
    return rootGetters['newProductDetail/common/externalSizeInfoIndex']
  },
  // 人气氛围配置(主图 ｜ 大图 ｜ 加车按钮)
  atmosphere_config(state, getters) {
    const { fs_abt } = getters
    const { hotnews: hotnewsAbt } = fs_abt
    // 根据abt配置是否展示人气氛围(wiki/pageId=1148323611)
    const { whereshow, toastcolor, hotnews } = hotnewsAbt?.param || {}
    // 根据访问次数判断是否展示人气氛围(wiki/pageId=1321240725) --默认不限制
    let forbidShow = getters.forbid_atmosphere_flow
    // 全部禁止展示
    if (forbidShow || !hotnews) return {}

    return {
      mainPicShow: whereshow !== 'onlyaddtobag',                      
      bigPicShow: ['bigpicture', 'andaddtobag'].includes(whereshow), 
      addBagShow: ['onlyaddtobag', 'andaddtobag'].includes(whereshow),
      config: {
        color: toastcolor || ''
      }
    }
  },
  // 人气氛围数据(主图 ｜ 大图 ｜ 加车按钮)
  atmosphere_data(state, getters, rootState) {
    let { hotNews, carHotNews } = rootState.productDetail.hotModules.productLabel || {}
    const { current_mall } = getters
    const { is_absolute_low_inventory, is_relatively_low_inventory } = current_mall || {}

    let labelType = ''
    if (is_absolute_low_inventory === true) {
      labelType = 1
    }
    if (is_relatively_low_inventory === true) {
      labelType = 2
    }

    return {
      hotNews,
      carHotNews,
      labelType
    }
  },
  // 榜单相关
  ranking_list_info(state, getters, rootState) {
    const cache_screen_data = getters.cache_screen_data
    if (cache_screen_data) {
      const { rankingList } = cache_screen_data
      return {
        ...rankingList,
        // 兼容列表字段
        rankingTypeText: rankingList?.rankingTypeText || rankingList?.rankTypeText,
      }
    }
    return rootState.productDetail.asyncModules.topRanking ?? null
  },
  // 免费退货 TODO
  free_return_policy(state, getters, rootState) {
    return !!(rootState.newProductDetail.hotModules?.shipping?.freeReturnPolicy * 1)
  },
  // 商品主图
  product_imgs(state, getters, rootState, rootGetters) {
    const { second_info_ready } = state
    // 商品 skc ｜ sku ｜ spu 图片 
    const { skcImages = [], skuImages = {}, spuImages = [], maskLayer = {} } = rootState.productDetail.coldModules.productInfo.currentSkcImgInfo || {}
    const sku_list = rootState.productDetail.coldModules?.saleAttr?.multiLevelSaleAttribute?.sku_list || []
    const allColorDetailImages = rootState.productDetail.coldModules.productInfo.allColorDetailImages || {}
    // 视频的位置
    const videoSort = rootState.productDetail.coldModules.productInfo.videoSort || 1
    const has_vimeo_url = rootGetters['productDetail/Main/has_vimeo_url']
    const has_video_url = rootGetters['productDetail/Main/has_video_url']
    // 商品 ID
    const goods_id = getters.goods_id

    // 商品图片排序 spu图-skc图-sku图-细节图
    const imageArr = []
    // 正则表达式
    const regex = /\/([^\/]*)_thumbnail/
    const fsAbt = getters.fs_abt
    const showSpuPic = fsAbt?.detailSpuPic?.param?.spuPic === 'show'

    // 为每个对象生成唯一的 ID
    const getImgID = (image) => {
      // 兼容图片后缀没有thumbnail导致的key获取失败
      if (image?.indexOf('_thumbnail') === -1) {
        image = image?.replace('.webp', '_thumbnail_720x.webp')
      }
      // 使用正则表达式执行匹配
      const match = regex.exec(image)
      return match ? match[1] : Math.random().toString(36).substr(2, 9)
    }

    // spu 图片处理
    if (showSpuPic && spuImages.length) {
      spuImages?.forEach(spu => {
        spu && imageArr.push({
          origin_image: spu,
          img_id: getImgID(spu),
          is_spu_image: true,
        })
      })
    }

    // skc 图片处理
    if (skcImages?.length) {
      skcImages?.forEach(skc => {
        skc && imageArr.push({
          origin_image: skc,
          img_id: getImgID(skc),
          is_skc_image: true,
        })
      })
    }

    // spu 图片数量 > 1时候 取spu 下面所有skc
    if (showSpuPic && spuImages?.length > 1) {
      Object.keys(allColorDetailImages).forEach(skcKey => {
        const skcImageArr = allColorDetailImages?.[skcKey] || null
        const skcImage = skcImageArr?.[0]?.origin_image || null
        if (skcImage && skcKey !== goods_id ) {
          imageArr.push({
            origin_image: skcImage,
            img_id: getImgID(skcImage),
            is_relate_skc_image: true,
            is_skc_image: true,
          })
        }
      })
    }

    // sku 图片处理 以 sku_list 顺序为准排序
    if (skuImages && !!sku_list?.length) {
      sku_list?.forEach(item => {
        const skuImage = skuImages[item.sku_code] || null
        skuImage && imageArr.push({
          origin_image: skuImage,
          img_id: getImgID(skuImage),
          sku_code: item.sku_code,
          is_sku_image: true,
        })
      })
    }

    // 是否展示细节图配置
    if (second_info_ready) {
      const { content = [] } = rootState.productDetail.hotModules.productInfo.cccDetailsTemplate || {}
      const isShowDetail = content?.[0]?.detailImg && content?.[0]?.detailImg === '1'
      // 细节图
      const detailImages = rootState.productDetail.asyncModules.currentSkcImgInfo.detailImage || []
      if (isShowDetail && detailImages?.length) {
        detailImages?.forEach(detail => {
          const image_url = detail?.image_url || null
          image_url && imageArr.push({
            origin_image: image_url,
            img_id: getImgID(image_url),
          })
        })
      }
    }

    // 取缓存图片
    const cache_goods_imgs = rootState.productDetail.common.cache_goods_imgs

    if (cache_goods_imgs && cache_goods_imgs?.bf_first_image) {
      const paths = location.pathname.match(reg)
      const urlGoodsId = paths instanceof Array && paths[1] ? paths[1] : null
      // 是否同一个商品
      const isCurGoods = goods_id === urlGoodsId
      
      const bf_first_image = cache_goods_imgs?.bf_first_image
      // 替换图片
      const origin_image = isCurGoods && imageArr[0]?.origin_image
        ? imageArr[0]?.origin_image
        : bf_first_image?.bfCache

      imageArr[0] = {
        is_skc_image: imageArr[0]?.is_skc_image,
        is_spu_image: imageArr[0]?.is_spu_image,
        bfCache: bf_first_image?.bfCache,
        origin_image,
        img_id: getImgID(origin_image),
      }
    }

    // 添加主图Beauty品类蒙版 （SPU图/SKC主图）
    if (!!maskLayer?.src && imageArr?.length > 0) {
      let imgRatio = ''
      try {
        const { width, height } = maskLayer || {}
        imgRatio = (width && height) ? closestRatio(+width / +height) : null
      } finally {
        let maskIndex = 0
        // 首图有视频按钮是蒙层放在第二个
        if (has_vimeo_url || has_video_url) {
          maskIndex = +videoSort === 1 ? 1 : 0
        }
        if ((imageArr[maskIndex]?.is_skc_image || imageArr[maskIndex]?.is_spu_image)) {
          imageArr[maskIndex].maskLayer = {
            src: maskLayer.src,
            imgRatio,
          }
        }
      }
    }
    
    return imageArr
  },
  // 新搭配数据
  new_companion_info(state, getters, rootState) {
    const product_imgs = getters.product_imgs
    const newCompanionInfo = rootState.productDetail.asyncModules.newCompanionInfo || {}
    const { productRecommendDetailLabel } = newCompanionInfo

    const { fs_abt } = getters
    const { newoutfit } = fs_abt?.newoutfit?.param || 0
    if (newoutfit !== 'new') return {}

    // 过滤主图没有
    if (productRecommendDetailLabel?.url) {
      const url = productRecommendDetailLabel?.url
      const beginIdx = url.lastIndexOf('/')
      const endIdx = url.lastIndexOf('.')
      const point = url.substring(beginIdx, endIdx)
      const condition = product_imgs.some(item=>item?.origin_image?.includes(point))
      if (!condition) {
        return {}
      }
    } 

    return newCompanionInfo
  },
  close_estimated_and_ab_price(state, getters, rootState, rootGetters) {
    // todo: 旧数据流
    return rootGetters['newProductDetail/closeEstimatedAndAbPrice']
  },
  forbid_atmosphere_flow(state, getters, rootState, rootGetters){
    if(typeof window == 'undefined') return
    const { fs_abt } = getters
    const Displaytiming = fs_abt?.Displaytiming?.param?.Displaytiming || 0
    if(Displaytiming){
      let pageView = JSON.parse(localStorage?.getItem('goods_detail_page_view'))?.value || 0
      // 用户24小时内访问商详次数大于等于X次，返回false，展示人气氛围
      return Displaytiming > pageView
    }
    return false
  },
  is_store_business_brand(state, getters, rootState) {
    return rootState.productDetail.coldModules.storeInfo?.storeBusinessType == 2
  },
  store_brand_series_recommend_group(state, getters, rootState) { // TODO 旧数据流 后面需要替换
    return rootState.newProductDetail.storeBrandSeriesRecommendGroup
  },
}
