import { SIMetric } from 'public/src/pages/common/monitor/index.js'
import { daEventCenter } from 'public/src/services/eventCenter/index'
import DETAIL_API_LOCATION from '../api'
import schttp from 'public/src/services/schttp'
import qs from 'qs'
/**
 * 首屏相关无法区分模型的 actions
 */
export default {
  get_product_second_info: async ({
    commit,
    rootGetters,
    rootState,
    dispatch
  }) => {
    // 第二部分数据
    const goods_id = rootGetters['productDetail/common/goods_id'] || ''

    const [
      detailImage = [],
      topRanking = {},
      newCompanionInfo = {},
    ] = await Promise.all([
      dispatch('get_product_image', { goods_id }),
      dispatch('get_top_ranking_info'),
      dispatch('get_new_companion_module'),
    ])

    commit('productDetail/update_async', {
      ...rootState.productDetail.asyncModules,
      currentSkcImgInfo: { detailImage },
      topRanking,
      newCompanionInfo,
    }, { root: true })

    commit('common/update_second_info_ready', true)
  },
  get_product_third_info: async ({
    commit,
    rootState,
    rootGetters,
    dispatch
  }) => {
    // 第三部分数据
    const goodsId = rootGetters['productDetail/common/goods_id'] || ''
    const cateId = rootGetters['productDetail/common/cat_id'] || ''
    const goodsSn = rootGetters['productDetail/common/goods_sn'] || ''

    const [
      getTheLookRelatedIdSeries = [],
    ] = await Promise.all([
      dispatch('get_recommend_info', { goodsId, cateId, goodsSn }),
    ])

    commit('productDetail/update_async', {
      ...rootState.productDetail.asyncModules,
      getTheLookRelatedIdSeries
    }, { root: true })
  },
  get_product_image: ({}, { goods_id }) => {
    // 获取细节图
    return new Promise(resolve => {
      schttp({
        url: DETAIL_API_LOCATION.PRODUCT_DETAIL_DETAIL_IMAGE,
        params: { goods_id },
        useBffApi: true
      }).then(({ info = {} }) => {
        const { goods_images = [] } = info
        return resolve(goods_images)
      })
    })
  },
  get_top_ranking_info: ({ state, rootGetters }) => {
    // 获取榜单信息
    return new Promise(resolve => {
      const MAIN_BFF_APOLLO = state.MAIN_BFF_APOLLO || {}
      if (!MAIN_BFF_APOLLO.v4) return resolve({})

      const goods_id = rootGetters['productDetail/common/goods_id'] || ''
      const fs_abt = rootGetters['productDetail/common/fs_abt'] || ''
      const cat_id = rootGetters['productDetail/common/cat_id'] || ''
      const goods_sn = rootGetters['productDetail/common/goods_sn'] || ''

      const ruleId = fs_abt?.ProductDetailBelowPolicyFloor?.p ||  ''
      if (!ruleId) return resolve({})

      const params = {
        abt_branch: '',
        belowModelFloor: '',
        goods_ids: goods_id,
        cate_ids: cat_id,
        goods_sns: goods_sn,
        belowPolicyFloor: ruleId
      }

      schttp({
        url: DETAIL_API_LOCATION.PRODUCT_RANKING_INFO,
        method: 'POST',
        headers: { 
          'Content-Type': 'application/x-www-form-urlencoded'
        },
        data: qs.stringify(params),
        useBffApi: true
      }).then(({ info = {} }) => {
        const { content = [] } = info
        return resolve(content?.[0]?.content?.props?.items?.[0]?.rank_of_goods?.list?.[0] || {})
      })
    })
  },
  get_recommend_info: ({}, { cateId, goodsId, goodsSn }) => {
    // 获取榜单信息
    return new Promise(resolve => {

      const params = {
        goodsId,
        cateId,
        goodsSn,
      }

      schttp({
        url: DETAIL_API_LOCATION.PRODUCT_DETAIL_RECOMMEND_INFO,
        method: 'POST',
        data: params,
        useBffApi: true
      }).then(res => {
        const getTheLookRelatedIdSeries = res?.info?.getTheLookRelatedIdSeries || []
        resolve(getTheLookRelatedIdSeries)
      })
    })
  },
  get_new_companion_module: ({ state, rootGetters }, args) => {
    // 获取新搭配数据
    return new Promise(resolve => {
      const MAIN_BFF_APOLLO = state.MAIN_BFF_APOLLO || {}
      if (!MAIN_BFF_APOLLO.v4) return resolve({})

      const goods_id = rootGetters['productDetail/common/goods_id'] || ''
      const cat_id = rootGetters['productDetail/common/cat_id'] || ''
      const goods_sn = rootGetters['productDetail/common/goods_sn'] || ''
      const is_paid_user = rootGetters['productDetail/common/is_paid_user'] || ''
      const mall_code = rootGetters['productDetail/common/mall_code'] || ''
      const is_sold_out = rootGetters['productDetail/common/is_sold_out']

      const { 
        attribute = '', 
        isAddCart = '0',
        isEnterDetailCall = '1',
        isSelectCollocationGood,
        relationCatId,
        relationGoodsId,
        selectedGoodsIdSkus,
        updateGoodsId
      } = args || {}

      const params = {
        goodsId: goods_id,
        catId: cat_id,
        goodsSn: goods_sn,
        isPaidMember: !!is_paid_user ? '1' : '0',
        isMainGoodsSoldOut: !!is_sold_out ? '1' : '0',
        mallCode: mall_code,
        attribute,
        isAddCart,
        isEnterDetailCall,
        isSelectCollocationGood,
        relationCatId,
        relationGoodsId,
        selectedGoodsIdSkus,
        updateGoodsId,
      }

      schttp({
        url: DETAIL_API_LOCATION.PRODUCT_GET_NEW_COMPANION,
        params: params,
        useBffApi: true
      }).then(({ info = {}, code }) => {

        // 接口异常上报排查
        if(code !== null && code !== '0' && code !== 836100) {
          const { SiteUID } = typeof gbCommonInfo !== 'undefined' ? gbCommonInfo : {}
          const apiUrl = DETAIL_API_LOCATION.PRODUCT_GET_NEW_COMPANION
          SIMetric.metricCount({
            metric_name: 'bff_api_res_error',
            tags: {
              siteuid: SiteUID,
              errorCode: code || '',
              apiUrl,
              hostname: location?.hostname || ''
            },
            message: JSON.stringify(info || {}) || `${apiUrl}请求非0错误`
          }, {
            immediate: true
          })
        }
        // 每次请求需要上报是否有outfits数据
        if (!!info?.productNewCompanionList?.length) {
          daEventCenter.triggerNotice({
            daId: '1-6-1-237',
          })
        }

        return resolve(info)
      })
    })
  },
}
