import PROMOTION_CONFIG from 'public/src/pages/common/promotion/config.js'
import { souceFormat } from 'public/src/pages/components/coupon/mall/utils'
import { cloneDeep, template } from '@shein/common-function'

const { CLOSE_BRAND_FLASH_PROMOTION_FLOOR } = gbCommonInfo || {}

const discountFilter = (item, isSpecialFlash, zoneFlash) => {
  const isNotDealerDown = (item = {}) => item.typeId == 31 && item.promotionLogoType != 3 // 用于过滤同一活动Id--31但非商家直降活动的情况
  return (
    PROMOTION_CONFIG.allPromotionTypeIds.includes(+item.typeId) &&
    !(
      [8, 12, 29, 11].includes(+item.typeId) ||
      (item.typeId == 24 &&
        item.ruleType === 'no_discount' &&
        Number(item.singleNum) &&
        !Number(item.orderNum)) ||
      (item.typeId == 10 && [2, 3].includes(item.flashType) && !isSpecialFlash) ||
      (item.typeId == 10 && [4].includes(item.flashType) && !zoneFlash) ||
      isNotDealerDown(item)
    )
  )
}

export default {
  /** 促销信息 */
  promotionInfo(state, getters, rootState, rootGetters) {
    let needShieldTypeIdsOnInversion = [3, 24, 30, 31]
    if (rootGetters['newProductDetail/Price/isInversion']) return getters?.currentMallDetail?.promotionInfo?.filter(item => !needShieldTypeIdsOnInversion.includes(Number(item.typeId))) || []
    if (rootGetters['newProductDetail/common/complianceModeDe'] && rootGetters['newProductDetail/common/price']?.suggestedSalePrice?.amount == 0) {
      return getters?.currentMallDetail?.promotionInfo?.filter(item => !needShieldTypeIdsOnInversion.includes(Number(item.typeId))) || []
    }
    return getters.skuPromotionInfo || getters?.currentMallDetail?.promotionInfo || []
  },
  skuPromotionInfo(state, getters, rootState) {
    return rootState.newProductDetail?.common?.skuInfo?.mall?.[getters.mallCode]?.promotionInfo
  },
  /** 闪购商品 */
  flashGoods(state, getters, rootState, rootGetters) {
    if (rootGetters['newProductDetail/Price/isInversion']) return null
    if (rootGetters['newProductDetail/common/complianceModeDe'] && rootGetters['newProductDetail/common/price']?.suggestedSalePrice?.amount == 0) {
      return null
    }
    return getters?.promotionInfo?.find?.(item => item.typeId == 10)
  },
  /** 闪购类型 */
  flashType(state, getters) {
    return getters.flashGoods?.flashType
  },
  /** 是否特殊闪购 */
  isSpecialFlash(state, getters) {
    const { flashType } = getters
    const hasNewFlashPromotion = getters.newFlashPromotion?.price?.amountWithSymbol
    const isSpecialFlash = hasNewFlashPromotion && [2, 3].includes(Number(flashType))
    return isSpecialFlash
  },
  /** 是否是闪购 */
  isFlash(state, getters, rootState) {
    const { productInfo } = rootState.newProductDetail.coldModules
    const { detail } = productInfo || {}
    const { hasNewFlashPromotion, flashType, flashGoods } = getters
    // 普通闪购样式
    const flashSaleDiff = [1, 5].includes(Number(flashType)) || ([4].includes(Number(flashType)) && hasNewFlashPromotion)
    // 显示闪购信息
    const showFlashSale = !!(flashGoods || detail?.unit_discount)
    const isFlash = flashSaleDiff && showFlashSale
    return isFlash
  },
  /** */
  hasNewFlashPromotion(state, getters) {
    return getters.newFlashPromotion?.price?.amountWithSymbol
  },
  newFlashPromotion(state, getters, rootState, rootGetters) {
    let result = cloneDeep(rootGetters['newProductDetail/skcNewFlashPromotion'])
    if (!result?.price?.amountWithSymbol) return null
    let skuInfoMallInfo = rootState.newProductDetail?.common?.skuInfo?.mall?.[getters.mallCode]
    if (skuInfoMallInfo?.showTypeId == 10) {
      result.price = skuInfoMallInfo.showPrice
      result.suggested_sale_price =  skuInfoMallInfo.suggestedSalePrice
    }
    let discountPercent = rootGetters['newProductDetail/skcNewFlashPromotion']?.discount_percent || result?.discountValue
    let discountValue = rootGetters['newProductDetail/skcNewFlashPromotion']?.discount_value || (result?.discountValue ? ((1 - result?.discountValue) * 100).toFixed(0) : '')
    return {
      ...result,
      discountPercent,
      discountValue
    }
  },
  /** 命中到手价abt */
  hitEstimatedAbt(state, getters, rootState, rootGetters) {
    const fsAbt = rootGetters['newProductDetail/fsAbt']
    const abtValue = fsAbt?.EstimatedPrice?.p?.E_Price_Cal
    let includesAbts = ['Have_Threshold', 'Non_Threshold']
    const hitEstimatedAbt = includesAbts.includes(abtValue)
    return hitEstimatedAbt
  },
  storeCouponListFormated(state, getters, rootState, rootGetters) {
    let storeCouponList = rootGetters['newProductDetail/storeCouponList'] || []
    let formated = storeCouponList.map(item => {
      return souceFormat(item)
    })
    return formated
  },
  promotionInfoDesc(state, getters, rootState, rootGetters) { // 不到 2ms 可以放客户端
    const flashType = getters.flashType
    const promotionInfoWithCenter = rootState.newProductDetail.promotionInfoWithCenter
    const newFlashPromotion = rootGetters['newProductDetail/common/newFlashPromotion']
    const getEstimatedInfo = getters.getEstimatedInfo
    let promotionInfoDesc = []
    //需要过滤：未命中展示新人/特殊闪购的分支，promotion弹窗和入口都不应该展示新人/特殊闪购
    const isSpecialFlash = [2, 3].includes(flashType) && newFlashPromotion?.price?.amountWithSymbol
    const zoneFlash = [4].includes(flashType) && newFlashPromotion?.price?.amountWithSymbol
    let temp = promotionInfoWithCenter
      .filter(item => discountFilter(item, isSpecialFlash, zoneFlash))

    temp = temp.filter(item => getters.promotionInfo.find(prom => prom.id == item.id))
    if(rootGetters['newProductDetail/Price/isInversion']) temp = temp.filter(item => item.typeId != 10)
    // 倒挂要拿闪购PFES闪购的字段，不能屏蔽，但是展示上屏蔽
    promotionInfoDesc = temp.filter(item => getters.promotionInfo.find(prom => prom.id == item.id))
    
    // 命中到手价实验时，最优活动要排到第一位
    if (getters.hitEstimatedAbt || getEstimatedInfo?.isAb) {
      const bestId = getEstimatedInfo?.promotionInfo?.id
      promotionInfoDesc.forEach((item, index) => {
        if (item.id === bestId) {
          promotionInfoDesc.unshift(promotionInfoDesc.splice(index, 1)[0])
        }
      })
      // 活动中心没返还活动信息
      promotionInfoDesc.forEach((item, index) => {
        if (item.typeId === 32) {
          promotionInfoDesc[index].id = getEstimatedInfo?.promotionInfo?.id
        }
      })
    }
    if (!getEstimatedInfo?.value && promotionInfoDesc.find(item => item.typeId == 32)) {
      promotionInfoDesc = promotionInfoDesc.filter(item => item.typeId != 32)
    }
    let isBrandFlash = flashType == 6
    let closeBrandFlashPromotionFloor = CLOSE_BRAND_FLASH_PROMOTION_FLOOR
    if (closeBrandFlashPromotionFloor && isBrandFlash) {
      return []
    }
    return promotionInfoDesc
  },
  cccAddCartPrompt(state, getters, rootState,) {
    const { ccc } = rootState.newProductDetail.hotModules || {}
    const { cccAddCartPrompt } = ccc || {}
    const items = cccAddCartPrompt?.content?.items ?? []
    return items
  },
  extraVoucherPromotionInfo(state, getters, rootState, rootGetters) {

    const fsAbt = rootGetters['newProductDetail/fsAbt']
    let detialcatevorch = fsAbt?.detialcatevorch?.p?.detialcatevorch
    let extraVoucherPromotion = getters.promotionInfo?.find(item => item.typeId == 33)
    let discountAmountWithSymbol = extraVoucherPromotion?.voucherAmount?.amountWithSymbol
    let catNameMulti = extraVoucherPromotion?.categoryInfo?.[0]?.catNameMulti
    const { language } = rootState.newProductDetail.coldModules?.pageInfo

    const {
      SHEIN_KEY_PWA_31290,
      SHEIN_KEY_PWA_31291,
      SHEIN_KEY_PWA_31315,
      SHEIN_KEY_PWA_31292,
      SHEIN_KEY_PWA_31293,
      SHEIN_KEY_PWA_31294,
      SHEIN_KEY_PWA_31313,
      SHEIN_KEY_PWA_31314,
      SHEIN_KEY_PWA_31296,
      SHEIN_KEY_PWA_26949
    } = language

    if (!extraVoucherPromotion || !discountAmountWithSymbol || !catNameMulti) return null
    if (!['show1', 'show2'].includes(detialcatevorch)) return null
    let isExtraVoucherFloorType = detialcatevorch === 'show1'
    let isExtraVoucherTextType = detialcatevorch === 'show2'
    let extraVoucherFloorTypeTexts = {
      extraVoucher: SHEIN_KEY_PWA_31293 || 'Extra voucher',
      forNewUeserInCatNameMulti: template(catNameMulti, SHEIN_KEY_PWA_31315 || 'for new ueser in {0}'),
    }
    let extraVoucherTextTypeTexts = {
      extraVoucher: SHEIN_KEY_PWA_31290 || 'Extra voucher {0}',
    }

    let extraVoucherPromotionTexts = {
      title: SHEIN_KEY_PWA_31296 || 'Voucher',
      contentTitle: template(catNameMulti, SHEIN_KEY_PWA_31291 || 'For new user in {0}'),
      contentItem1: SHEIN_KEY_PWA_31292,
      contentItem2EndTimeLanguage: SHEIN_KEY_PWA_26949 || 'Expires in {0}',
      contentItem2EndTimestamp: extraVoucherPromotion?.endTimestamp,
      // contentItem2EndTimestamp: 1715961599,
      floorTip: SHEIN_KEY_PWA_31293 || 'Extra Voucher',
      tipTitle: SHEIN_KEY_PWA_31294 || 'How to get Vouchers?',
      tipContent1: SHEIN_KEY_PWA_31313 || 'The Category Voucher for New (the "Voucher(s)") will be issued to a new category user. The Voucher value vary among users; A new category user refers to a SHEIN user who has never placed an order or purchased any product from a certain category.',
      tipContent2: SHEIN_KEY_PWA_31314 || `Obtaining Voucher(s) is subject to compliance with SHEIN's terms and conditions. Users who have been restricted due to violations of SHEIN's policies or whose accounts exhibit unusual activities will be ineligible to obtain the Voucher(s). SHEIN reserves the right to withhold Voucher(s) from users who, in SHEIN's reasonable judgment, have engaged in improper or illicit activities to collect Voucher(s). SHEIN's determination of such activities will be final and binding.`,
    }

    return {
      isExtraVoucherFloorType,
      isExtraVoucherTextType,
      extraVoucherFloorTypeTexts,
      extraVoucherTextTypeTexts,
      extraVoucherPromotionTexts,
      discountAmountWithSymbol
    }

  }
}
