import abtInfo from './abtInfo'
export default {
  namespaced: true,
  state: {
    combobuy_data: [], // test
  },
  getters: {
    ...abtInfo,
    /**
   * 店铺推荐商品id集合, 用,分隔
   * todo_del
   */
    shop_rec_product_ids(state, getters, rootState) {
      return rootState.newProductDetail.common.storeRecommendInfo || ''
    },
    /**
     * 商卡客户端预处理bff依赖数据
     */
    product_bff_depend_data(state, getters, rootState, rootGetters) {
      const product_items_language = rootGetters['productDetail/common/product_items_language']
      const isPaid = !!rootGetters['productDetail/common/is_paid_user']
      const compliance_mode = rootGetters['productDetail/common/compliance_mode']
      const compliance_tips_mode = rootGetters['productDetail/common/compliance_tips_mode']
      const compliance_mode_de = rootGetters['productDetail/common/compliance_mode_de']

      // 当前站点合规配置 'normal', 'special','special_de'
      const suggestedSaleType = (() => {
        if (compliance_mode_de) return 'special_de'
        if (compliance_tips_mode) return 'special'
        if (compliance_mode) return 'normal'
      })()

      const lang = (typeof gbCommonInfo !== 'undefined' && gbCommonInfo.lang) || 'us'

      return {
        lang,
        language: product_items_language,
        isPaid,
        suggestedSaleType,
      }
    }
  },
  actions: {},
  mutations: {
  },
}
